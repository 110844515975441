import React from 'react';
import {FAN, ARTIST} from "../../redux/actions/LoginActions";

import {
    Route,
    Redirect,
    withRouter,
} from 'react-router-dom'

const PrivateRoute = ({currentUser, component, ...rest}) => (

    <Route  {...rest}
            render={
                (props) => ((currentUser === FAN || currentUser === ARTIST) ?
                    (React.createElement(component, props)) : (<Redirect to={{pathname: '/login',}}/>)
            )}/>
);

export default withRouter(PrivateRoute);