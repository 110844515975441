import {MediaActions} from "../api/media/MediaApi";
import {PlaylistActions} from "../api/playlist/PlaylistApi";
import {AlbumActions} from "../api/album/AlbumApi";
import {ArtistActions} from "../api/artist/ArtistApi";

// CONST

export const Library = {
    LIBRARY_REQUEST: "LIBRARY_REQUEST",
    LIBRARY_SUCCESS: "LIBRARY_SUCCESS",
    LIBRAYR_ERROR: "LIBRARY_ERROR",
}

// ACTIONS

export const LibraryActions = {
    SongLibrarySwitch,
    PlaylistLibrarySwitch,
    AlbumLibrarySwitch,
    ArtistLibrarySwitch,
    AddMediaToPlaylist,
}

function SongLibrarySwitch(song) {
    return dispatch => {
        dispatch(request(song))

        if (song.inLibrary)
            MediaActions.removeFromLibrary(song.id, dispatch)
        else
            MediaActions.addToLibrary(song.id, dispatch)

        song.inLibrary = !song.inLibrary

        dispatch(success(song))
    }

    function request(song) {return {type: Library.LIBRARY_REQUEST, song: song}}
    function success(song) {return {type: Library.LIBRARY_SUCCESS, song: song}}
}

function PlaylistLibrarySwitch(library) {
    return dispatch => {
        dispatch(request(library))

        if (library.inLibrary)
            PlaylistActions.removeFromLibrary(library.id, dispatch);
        else
            PlaylistActions.addToLibrary(library, dispatch);

        library.inLibrary = !library.inLibrary;

        dispatch(success(library));
    };
    
    function request(library) {return {type: Library.LIBRARY_REQUEST, song: library}}
    function success(library) {return {type: Library.LIBRARY_SUCCESS, song: library}}
}

function AlbumLibrarySwitch(album) {
    return dispatch => {
        dispatch(request(album))

        if (album.inLibrary)
            AlbumActions.removeFromLibrary(album.id, dispatch);
        else
            AlbumActions.addToLibrary(album.id, dispatch);

        album.inLibrary = !album.inLibrary;

        dispatch(success(album))
    };

    function request(album) {return {type: Library.LIBRARY_REQUEST, song: album}}
    function success(album) {return {type: Library.LIBRARY_SUCCESS, song: album}}
}

function ArtistLibrarySwitch(artist) {
    return dispatch => {
        dispatch(request(artist))

        if (artist.inLibrary)
            ArtistActions.removeFromLibrary(artist.id, dispatch);
        else
            ArtistActions.addToLibrary(artist.id, dispatch);

        artist.inLibrary = !artist.inLibrary;

        dispatch(success(artist))
    };

    function request(artist) {return {type: Library.LIBRARY_REQUEST, song: artist}}
    function success(artist) {return {type: Library.LIBRARY_SUCCESS, song: artist}}
}

function AddMediaToPlaylist(playlistId, id, type, callback) {

    return (dispatch, getState ) => {
        dispatch({type: Library.LIBRARY_REQUEST})

        let songs = [];
        if(type === 'song') {
            songs.push(id);
            addSongsToPlaylist(songs, playlistId, dispatch);
        }
        if(type === 'album') {
            MediaActions.getMediaFromAlbum(id, cb => {
                cb.forEach(song => {
                    songs.push(song.id)
                });
                addSongsToPlaylist(songs, playlistId, dispatch);
            }, dispatch, getState)
        }

        dispatch({type: Library.LIBRARY_SUCCESS})
        callback()
    }
}

function addSongsToPlaylist(songs, playlistId, dispatch) {
    songs.forEach(songId => {
        PlaylistActions.addMediaToPlaylist(playlistId, songId, cb => {}, dispatch)
    });
}