import React, {Component} from 'react';
import {withRouter, Link, NavLink, Switch, Redirect} from "react-router-dom";
import {bindActionCreators} from "redux";
import {LoginActions} from "../../redux/actions/LoginActions";
import {connect} from "react-redux";
import {UserActions} from "../../redux/api/user/UserApi";
import DashboardComponent from './DashboardComponent'
import ArtistsComponent from './ArtistsComponent'
import './adminstyle.scss'
import PrivateRouteAdmin from './PrivateRouteAdmin'

class AdminLogin extends Component {

    state = {
        email: '',
        password: '',
        isAdminLogged: false,
        isSendMessageOpened: false,
        artistList: [],
        emailTitle: '',
        emailContentText: '',
    };

    componentDidMount() {
        if (localStorage.getItem('loggedAs') === 'ADMIN') {
            this.setState({isAdminLogged: true});
        }
    }

    handleAdminLogin(email, password) {
        let log = {
            login: email,
            password: password,
        };
        this.props.logAdmin(log, (response) => {
            if (response.status === -1) {
            } else {
                this.props.onLoginDispatch({role: response.role, id: response.id});
            }
        });
    }

    onHandleEmailChange(e) {
        this.setState({email: e.target.value})
    }

    onHandlePasswordChange(e) {
        this.setState({password: e.target.value})
    }

    logoutUser() {
        this.props.logoutUser();
        this.props.history.push('/');
    }

    render() {
        return (
            this.state.isAdminLogged ?
                <div className="adminpanel">
                    <div className="menu">
                        <Link to="/admin/">
                            <div className="logo"/>
                        </Link>
                        <div className="d-flex flex-column w-100 navlinks">
                            <NavLink to="/admin/dashboard" activeClassName="active"><span
                                className="icon-dj"/></NavLink>
                            <NavLink to="/admin/stats" activeClassName="active"><span
                                className="icon-bar-chart"/></NavLink>
                            <NavLink to="/admin/artists" activeClassName="active"><span
                                className="icon-audio-file"/></NavLink>
                        </div>
                        <Link to="/admin" onClick={this.logoutUser.bind(this)}>
                            <div className="icon-logout"/>
                        </Link>
                    </div>
                    <div className="body">
                        <Switch>
                            <PrivateRouteAdmin currentUser={this.props.currentUser} path="/admin/dashboard" component={DashboardComponent}/>
                            <PrivateRouteAdmin currentUser={this.props.currentUser} path="/admin/artists" component={ArtistsComponent}/>
                        </Switch>
                    </div>
                    <Redirect to="/admin/dashboard" />
                </div>
                :
                <div className="panelBody">
                    <div className="container-fluid">
                        <div className="create-wallet">
                            <div className="header p-5 d-flex justify-content-between align-items-center">
                                <a><img src="/img/logo-black.png" alt="OPUS"/></a>
                            </div>
                            <div className="content-center text-center">
                                <h2 className="light my-3 ">Hello Admin!</h2>
                                <p className="regular mb-5">I hope You have a great day.</p>
                                <input value={this.state.email} onChange={(e) => {
                                    this.onHandleEmailChange(e)
                                }} type="text" className=" mb-5" placeholder="Your e-mail"/>
                                <input value={this.state.password} onChange={(e) => {
                                    this.onHandlePasswordChange(e)
                                }} type="password" className="walletPassword  mb-5" placeholder="Your password"/>
                            </div>
                            <div className="footer">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <a onClick={(e) => {
                                                this.handleAdminLogin(this.state.email, this.state.password)
                                            }} className="button mx-auto my-4">Login</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.loginReducer.loggedAs
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logoutUser: bindActionCreators(LoginActions.logout, dispatch),
        logAdmin: bindActionCreators(UserActions.login, dispatch),
        getArtistsWaitingForApproval: bindActionCreators(UserActions.getArtistsWaitingForApproval, dispatch),
        approveArtist: bindActionCreators(UserActions.approveArtist, dispatch),
        sendMessageToNewsletter: bindActionCreators(UserActions.sendMessageToNewsletter, dispatch),
        onLoginDispatch: bindActionCreators(LoginActions.login, dispatch),

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLogin));
