import React from 'react';

import {
    Route,
    Redirect,
    withRouter,
} from 'react-router-dom'

const PrivateLoginRouteAdmin = ({currentUser, component, ...rest}) => (

    <Route  {...rest}
            render={
                (props) => ((currentUser !== 'ADMIN') ?
                        (React.createElement(component, props)) :
                        ((currentUser === 'ADMIN' ?
                                (<div><Redirect to={{pathname: '/admin/dashboard'}}/> </div>) :
                                (<div><Redirect to={{pathname: '/admin/login'}}/> </div>))
                        )
                )}/>
);

export default withRouter(PrivateLoginRouteAdmin);