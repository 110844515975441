import { API_VERSION } from "../../base";
import { getApiRoot } from "../../config";
import { devConsoleLog } from "../../Utilities";
import jwt from "jsonwebtoken";
import { errorHandler } from "./ErrorHandler";
import moment from 'moment';

class ApiCall { }

export function makeJsonApiCall(method, url, data = null, handleError = true, withContentType = true) {
    devConsoleLog(method + ' ' + url);
    devConsoleLog(data)
    return new Promise((resolve, reject) => {
        let request = new XMLHttpRequest();
        request.open(method, getApiRoot() + url, true);
        request.setRequestHeader('X-Access-Token', localStorage.getItem('tokenAccess'));
        request.setRequestHeader('x-api-version', API_VERSION);
        if (withContentType)
            request.setRequestHeader("Content-type", "application/json");

        request.addEventListener('load', () => {
            if (request.status === 200) {
                let response = JSON.parse(request.response);
                if (response.status !== undefined && response.status === -1) {
                    if (handleError) {
                        errorHandler(response)
                    } else {
                        reject(response)
                    }
                } else {
                    resolve(response);
                }
            } else if (request.status === 401) {
                refreshToken().then((success) => {
                    if (success) {
                        resolve(makeJsonApiCall(method, url, data));
                    } else {
                        reject(JSON.parse(request.response));
                    }
                });
            } else if (request.status === 204) {
                resolve();
            } else {
                devConsoleLog(request.response);
                if (handleError)
                    errorHandler(JSON.parse(request.response))
                else
                    reject(JSON.parse(request.response))
            }
        });
        request.addEventListener('error', (reason) => {
            // no idea why but reason is 'progressEvent' so I cannot get error message -.-'
            if (request.readyState === 4) {
                if (handleError)
                    errorHandler('Something went wrong.')
                else
                    reject('Something went wrong.')
            }
        }, false);
        request.send(data)
    });
}

export function makeFormApiCall(method, url, data = null, progress = null, handleError = true) {
    devConsoleLog(method + ' ' + url);
    devConsoleLog(data);
    return new Promise((resolve, reject) => {
        let request = new XMLHttpRequest();
        request.open(method, getApiRoot() + url, true);
        request.setRequestHeader('X-Access-Token', localStorage.getItem('tokenAccess'));
        request.setRequestHeader('x-api-version', API_VERSION);
        // request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        if (progress !== null) {
            request.upload.addEventListener('progress', (evt) => {
                if (evt.lengthComputable) {
                    let percentComplete = Math.round((evt.loaded * 100) / evt.total);
                    let success = percentComplete === 100 ? 1 : 0;
                    progress({ percentComplete: percentComplete, success: success })
                }
            }, false);
        }
        request.addEventListener('load', () => {
            if (request.status === 200) {
                let response = JSON.parse(request.response);
                if (response.status !== undefined && response.status === -1) {
                    if (handleError) {
                        errorHandler(response)
                    } else {
                        reject(response);
                    }
                } else {
                    resolve(response);
                }
            } else if (request.status === 401) {
                refreshToken().then((success) => {
                    if (success) {
                        resolve(makeJsonApiCall(method, url, data));
                    } else {
                        reject(JSON.parse(request.response));
                    }
                });
            } else if (request.status === 204) {
                resolve();
            } else {
                devConsoleLog(JSON.parse(request.response));
                if (handleError)
                    errorHandler(JSON.parse(request.response))
                else
                    reject(JSON.parse(request.response))
            }
        });

        request.addEventListener("error", (reason) => {
            // no idea why but reason is 'progressEvent' so I cannot get error message -.-'
            if (request.readyState === 4) {
                if (handleError)
                    errorHandler('Something went wrong.')
                else
                    reject('Something went wrong.')
            }
        }, false);
        request.send(data)
    });
}

export function refreshToken(isForced) {
    return new Promise((resolve, reject) => {
        let token = localStorage.getItem('tokenAccess');
        let email = localStorage.getItem('email');
        let data = { token: token, email: encodeURIComponent(email), gmt: new Date().getTimezoneOffset() };
        if (isForced === true) {
            data = { force: true, token: token, email: encodeURIComponent(email), gmt: new Date().getTimezoneOffset() };
        }

        let request = new XMLHttpRequest();
        request.open('POST', getApiRoot() + '/users/refreshToken', true);
        request.setRequestHeader('X-Access-Token', localStorage.getItem('tokenAccess'));
        request.setRequestHeader('x-api-version', localStorage.getItem(API_VERSION));
        request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        request.addEventListener("load", () => {
            let parsedResponse = JSON.parse(request.response);
            if (parsedResponse.status === -1) {
                localStorage.removeItem('loggedAs');
                localStorage.removeItem('tokenAccess');
                localStorage.removeItem('userId');
                localStorage.removeItem('email');
                localStorage.removeItem('subFrom');
                localStorage.removeItem('subTo');
                localStorage.removeItem('avatar');
                localStorage.removeItem('opusTokenAddr');
                localStorage.removeItem('opusMasterContractAddr');
                localStorage.removeItem('opusMasterContractOwnerAddr');
                window.location = '/login';

                resolve(false);
            } else if (parsedResponse.token != null) {
                localStorage.setItem('tokenAccess', parsedResponse.token);
                // localStorage.setItem('apiLocationURL', parsedResponse.apiNode);
                let decoded = jwt.decode(parsedResponse.token);
                let subFrom = decoded.sub_from;
                let subTo = decoded.sub_to;
                let subFromSec = Date.parse(subFrom);
                let subToSec = Date.parse(subTo);
                let formattedSubFrom = (new Date(subFromSec)).toLocaleDateString('en-US');
                let formattedSubTo = (new Date(subToSec)).toLocaleDateString('en-US');
                // localStorage.setItem('subFrom', formattedSubFrom);
                // localStorage.setItem('subTo', formattedSubTo);
                localStorage.setItem('subFrom', decoded.sub_from);
                localStorage.setItem('subTo', decoded.sub_to);
                localStorage.setItem('isSubActive', moment(decoded.sub_from).isBefore(moment()) && moment(decoded.sub_to).add(15, 'minutes').isAfter(moment()));

                resolve(true);
            } else {
                reject(request.response)
            }

        }, false);
        request.send('user=' + JSON.stringify(data));
    });
}