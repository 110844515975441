import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link, NavLink, Redirect, Route, Switch} from 'react-router-dom'
import MailForm from './MailForm'
import {ArtistActions} from '../../redux/api/artist/ArtistApi'
import moment from 'moment'
import {UserActions} from "../../redux/api/user/UserApi";
import {sortById} from "../../Utilities";
import ArtistAlbumsComponent from "./ArtistAlbumsComponent";
import ArtistAlbumDetailsComponent from "./ArtistAlbumDetailsComponent";

export class ArtistsComponent extends Component {
  constructor(props) {
      super(props)

      this.sendMessage = this.sendMessage.bind(this)

      this.state = {
          artists: [],
          allArtists: [],
          artistsSearch: '',
          acceptedCount: '-',
          notAcceptedCount : '-',
          blockedCount : '-'
      }

      this.onChange = this.onChange.bind(this)
  }

  componentWillUpdate(nextProps, nextState){
      let filtered = []
      let accepted = nextState.allArtists.filter(artist => {
          return artist.approval_status === "APPROVED" && artist.enabled === true
      })
      let toAccept = nextState.allArtists.filter(artist => {
          return artist.approval_status === "NEW" && artist.approved === false && artist.enabled === false
      })
      let blocked = nextState.allArtists.filter(artist => {
          return artist.enabled === false && artist.approval_status !== 'NEW'
      })

      if (nextProps.filter === 'accepted') {
          filtered = accepted
      } else if (nextProps.filter === 'toAccept') {
          filtered = toAccept
      } else if (nextProps.filter === 'blocked') {
          filtered = blocked
      }
      if(nextState.artistsSearch !== ''){
          nextState.artists = filtered.filter(artist => {
              return artist.name.toLowerCase().match('.*'+nextState.artistsSearch.toLowerCase()+'.*') !== null
          })
      }else {
          nextState.artists = filtered
      }
      nextState.acceptedCount = accepted.length
      nextState.notAcceptedCount = toAccept.length
      nextState.blockedCount = blocked.length
  }

  componentDidMount() {
    this.props.getAllArtists(artists => {
        let reversed = artists.reverse()
        this.setState({
            artists: reversed,
            allArtists: reversed
        })
    })
  }

  onChange(e) {
      const value = e.target.value;
      var results = this.state.allArtists.filter(artist => {
          return artist.name.toLowerCase().match('.*'+value.toLowerCase()+'.*') !== null
      })
      this.setState({
          artists: results,
          artistsSearch : value
      })
  }

  blockArtist(artistId){
      this.props.blockArtist(artistId, (result) => {
          let allArtists = this.state.allArtists.slice()
          let updatedArtists = allArtists.map(artist => {
              if(artist.id === artistId){
                  artist.approval_status = "DECLINED"
                  artist.enabled = false
              }
              return artist
          })
          updatedArtists.sort(sortById).reverse()
          this.setState({
              allArtists: updatedArtists
          })
      })
  }

  acceptArtist(artistId){
        this.props.approveArtist(artistId, true, (result) => {
            let allArtists = this.state.allArtists.slice()
            let updatedArtists = allArtists.map(artist => {
                if(artist.id === artistId){
                    artist.approval_status = "APPROVED"
                    artist.approved = true
                    artist.enabled = true
                }
                return artist
            })
            updatedArtists.sort(sortById).reverse()
            this.setState({
                allArtists: updatedArtists
            })
        })
  }

  declineArtist(artistId){
        this.props.approveArtist(artistId, false, (result) => {
            let allArtists = this.state.allArtists.slice()
            let updatedArtists = allArtists.map(artist => {
                if(artist.id === artistId){
                    artist.approval_status = "DECLINED"
                    artist.approved = false
                    artist.enabled = false
                }
                return artist
            })
            updatedArtists.sort(sortById).reverse()
            this.setState({
                allArtists: updatedArtists
            })
        })
  }

  sendMessage(e) {
      e.preventDefault()
  }

  render() {
    return (
      <div className="container">
        <div className="row">
            <div className="col-12 mb-5">
                <h1><Link className="titleColor" to="/admin/artists">Artists</Link></h1>
            </div>
            <div className="col-12 mb-5">
                <div className="badge w-25">
                    <h2 className="bold">{this.state.allArtists.length}</h2>
                    <small>Artists</small>
                </div>
                <div className="badge w-25">
                    <h2 className="bold">{this.state.acceptedCount}</h2>
                    <small>Accepted</small>
                </div>
                <div className="badge w-25">
                    <h2 className="bold">{this.state.notAcceptedCount}</h2>
                    <small>Not Accepted</small>
                </div>
                <div className="badge w-25">
                    <h2 className="bold">{this.state.blockedCount}</h2>
                    <small>Blocked</small>
                </div>
            </div>
        </div>

        <Switch>
            <Route path="/admin/artists/" exact render={() => <Redirect to="/admin/artists/accepted" />} />
            <Route path="/admin/artists/:id/albums/:albumId" component={ArtistAlbumDetailsComponent} />
            <Route path="/admin/artists/:id/albums" component={ArtistAlbumsComponent} />
            <Route path="/admin/artists/:category" exact render={() =>
                <div className="row">
                    <div className="col-12 mb-5">
                        <div className="panel">
                            <ul className="nav mb-2">
                                <li className="nav-item">
                                    <NavLink to="/admin/artists/accepted" exact className="nav-link" activeClassName="active">Accepted ({this.state.acceptedCount})</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/admin/artists/toAccept" className="nav-link" activeClassName="active">Not Accepted ({this.state.notAcceptedCount})</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/admin/artists/blocked" className="nav-link" activeClassName="active">Blocked ({this.state.blockedCount})</NavLink>
                                </li>
                            </ul>
                            <form className="mb-2">
                                <div className="input-group">
                                    <input type="text" name="artistsSearch" placeholder="Serch..."
                                           onChange={(e)=>this.onChange(e)} className="form-control"
                                           autoComplete="off"
                                    />
                                    <div className="input-group-append">
                                        <button type="submit" className="btn"><div className="icon-admin-search"></div></button>
                                    </div>
                                </div>
                            </form>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Country</th>
                                        <th>Registration Date</th>
                                        <th>Songs</th>
                                        <th>Albums</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.artists.map((artist, index) =>
                                        <tr key={index}>
                                            <td><img src={artist.avatar_url} alt="avatar" className="avatar"/></td>
                                            <td><Link to={'/admin/artists/'+artist.id+'/albums'}>{artist.name}</Link></td>
                                            <td>{artist.country !== undefined ? artist.country.code : "-"}</td>
                                            <td>{moment(new Date(artist.creation_date)).format("YYYY-MM-DD").toLocaleString()}</td>
                                            <td>{artist.no_all_tracks}</td>
                                            <td>{artist.no_all_albums}</td>
                                            {/*<td><Link to="/admin/artists/message/1"><div className="icon-arrow-right"></div></Link></td>*/}
                                            <td>

                                                {this.props.filter === 'accepted' ?
                                                    <div className="">
                                                        <button className="btn danger mr-2"
                                                                onClick={() => this.blockArtist(artist.id)}>Block
                                                        </button>
                                                        <Link to={'/admin/artists/message/'+artist.creation_user}>
                                                            <button className="btn mail icon-mail"></button>
                                                        </Link>
                                                    </div>
                                                        : null
                                                }
                                                {this.props.filter === 'toAccept' ?
                                                    <div>
                                                        <button className="btn success mr-2" onClick={() => this.acceptArtist(artist.id)}>Accept</button>
                                                        <button className="btn danger mr-2" onClick={() => this.declineArtist(artist.id)}>Decline</button>
                                                        <Link to={'/admin/artists/message/'+artist.creation_user}>
                                                            <button className="btn mail icon-mail"></button>
                                                        </Link>
                                                    </div>
                                                    : null
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            } />
            <Route path="/admin/artists/message/:id" recipientCode={"ID_"+1} isRecipientChoicesEnabled={false} component={MailForm} />
        </Switch>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
    let p = ownProps.location.pathname.split('/')[3]
    return{
        filter : p
    }
}

const mapDispatchToProps = {
    getAllArtists: ArtistActions.getAllArtists,
    blockArtist: ArtistActions.deleteArtistById,
    approveArtist: UserActions.approveArtist
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistsComponent)
