import React from 'react';
import {FAN, ARTIST} from "../../redux/actions/LoginActions";

import {
    Route,
    Redirect,
    withRouter,
} from 'react-router-dom'

const PrivateLoginRoute = ({currentUser, component, ...rest}) => (

    <Route  {...rest}
            render={
                (props) => ((currentUser !== FAN && currentUser !== ARTIST) ?
                    (React.createElement(component, props)) :
                    ((currentUser === FAN ?
                                (<Redirect to={{pathname: '/main/explore',}}/>) : (<Redirect to={{pathname: '/',}}/>))
                    )
                )}/>
);

export default withRouter(PrivateLoginRoute);