import React from 'react';
// import newId from '../utils/newid';
import ScrollComponent from './ScrollComponent';
import $ from 'jquery'

class DropdownComponentGenre extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            label: props.label
        }

        this.handleSelectElement = this.handleSelectElement.bind(this);
    }

    componentWillMount() {
        // this.id = newId();
    }

    componentDidMount() {
        this.setState({label: this.props.genreDisplay})

		this.$el = $(this.refs.container)

		this.onKeyPressed = this.onKeyPressed.bind(this)

		window.addEventListener('keypress', this.onKeyPressed)
    }

	componentWillUnmount() {
		window.removeEventListener('keypress', this.onKeyPressed)
	}

	onKeyPressed(e) {
		if(this.$el.hasClass('show')) {
			let elements = this.$el.find('.dropdown-item')
			$.each(elements, (index, item) => {
				let pattern = new RegExp("^"+ e.key +".*");
				if ($(item).text().toLowerCase().match(pattern)){
					this.scroll.scrollTo(index * $(item).outerHeight())
					return false
				}
			})
		}
	}

    handleSelectElement(name) {
        this.setState(prevState => ({label: name}));
    }

    render() {
        return (
            <div className="dropdown" ref={el => this.el = el}>
                <button type="button" className="btn dropdown-toggle" id={this.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.state.label}
                </button>
                <div className="dropdown-menu" ref="container" aria-labelledby={this.id}>
                    <ScrollComponent ref={scroll => this.scroll = scroll} maxHeight="300px">
                        {this.props.listToDisplay.map(item => (
                            <a className={this.state.label === item ? 'dropdown-item active' : 'dropdown-item'}  name='genreId' key={item.name} onClick={(e) => {
                                this.props.onGenreChange(e.target.name, item.id);
                                this.handleSelectElement(item.name)}}>{item.name}</a>
                        ))}
                    </ScrollComponent>
                </div>
            </div>
        );
    }
}


export default DropdownComponentGenre;