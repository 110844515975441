import React, {Component} from 'react';
import {connect} from "react-redux";
import Registration from "./components/Registration"
import Login from "./components/Login"
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import {
    Switch, Route,
    Redirect,
    withRouter, Link,
} from 'react-router-dom'
import OpenInAppPage from '../other websites/OpenInAppPage/OpenInAppPage';

class LoginPage extends Component {

    state = {
        showRegister: false,
        shouldRedirectFAN: false,
        shouldRedirectARTIST: false,
        loginErrorMessage: null,
        areAnyErrors: false,
        logData: {
            login: null,
            password: null,
            'os': "empty",
            'ip': "empty",
            'device': "empty"
        },
    }

    render() {
        if (this.state.shouldRedirectFAN) {
            return (
                <Redirect to='/main/explore'/>
            );
        } else if (this.state.shouldRedirectARTIST) {
            return (
                <Redirect to='/'/>
            )

        } else {
            return (
                <OpenInAppPage>
                <div className="panel-login">
                    <div className="content-login d-flex">
                        <div className="container d-flex" style={{flex: 1}}>
                            <div className="row" style={{flex: 1}}>
                                <div className="col-12">
                                    <nav className="navbar m-0">
                                        <ul className="nav">
                                            {/* <li className="nav-item ml-0">
                                                <a href="https://opusfoundation.medium.com//opus-beta-tests-have-started-3840af9ec5a2" target="_blank" className="nav-link pl-0"><b>We are on Testnet</b> READ MORE</a>
                                            </li> */}
                                        </ul>
                                        <ul className="nav ml-auto social">
                                            <li className="nav-item">
                                                <a href="https://www.facebook.com/opusfoundation/" target="__blank" className="nav-link"><i className="icon-facebook"/></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://twitter.com/opusfoundation" target="__blank" className="nav-link"><i className="icon-twitter"/></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://www.youtube.com/channel/UCo5UJmWcifx6UfsAyHNzQFQ" target="__blank" className="nav-link"><i className="icon-youtube-play"/></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://t.me/opusfoundation" target="__blank" className="nav-link"><i className="icon-telegram"/></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://www.reddit.com/r/opusfoundation/" target="__blank" className="nav-link"><i className="icon-reddit-alien"/></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://opusfoundation.medium.com/" target="__blank" className="nav-link"><i className="icon-medium"/></a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <nav className="navbar navbar-expand-xl my-0">
                                        <div className="logo-dark"/>
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="icon-menu"/>
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul className="navbar-nav ml-auto whiteText">
                                                <li className="nav-item">
                                                    <a className="nav-link" href="https://opus.audio/whitepaper.pdf" target="_blank">Whitepaper</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="https://opus.audio/artists" className="nav-link">For Artists</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="https://opusfoundation.medium.com/" className="nav-link">Blog</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="https://opus.audio/jobs" className="nav-link">Careers</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="https://opus.audio/faq" className="nav-link">Help</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>

                                <div className="col-12">
                                    <Switch>
                                        <Route path="/login/registration" component={Registration} />
                                        <Route path="/login" component={Login} />
                                    </Switch>
                                </div>

                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <ul className="inline">
                                        <li><a href="https://opus.audio/TermsOfUse.pdf" target="_blank">Terms of Use</a></li>
                                        <li><a href="https://opus.audio/Privacy_Policy.pdf" target="_blank">Privacy Policy</a></li>

                                        <li><a href="https://artist.opus.audio/login">Are you an artist?</a></li>
                                    </ul>
                                    {this.props.location.pathname === '/login' ?
                                        <ul className="inline align-items-center">
                                            <li><Link to="/login/registration" >Don't have an account?</Link></li>
                                            <li><Link to="/login/registration" className="btn btn-outline">Register to OPUS</Link></li>
                                        </ul>
                                         :
                                        <ul className="inline align-items-center">
                                            <li><Link to="/login" className="btn btn-outline">Have an account?</Link></li>
                                        </ul>
                                     }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </OpenInAppPage>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.loginReducer.loggedAs,
        logData: state.loginReducer.logData
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
