import {Library} from '../actions/LibraryActions' // CONST

export default function library(state = {}, action) {
    switch(action.type) {
        case Library.LIBRARY_REQUEST:
            return state
        case Library.LIBRARY_SUCCESS:
            return {
                song: action.song
            }
        case Library.LIBRAYR_ERROR:
            return state
        default:
            return state
    }
}