import {toast} from "react-toastify";

class ErrorHandler{}

var toasts = new Map()

export const errorHandler = response => {
    if(response.status !== undefined && response.status === -1) {
        if(toasts.has(getHash(response.message))){
            toast.update(getHash(response.message), null)
        }else {
            toasts.set(getHash(response.message), true)
            toast.error(response.message, {
                position: toast.POSITION.TOP_CENTER,
                id: getHash(response.message),
                onClose : () => {toasts.delete(getHash(response.message))}
            })
        }
    }else{
        if(toasts.has(getHash("Something went wrong."))){
            toast.update(getHash("Something went wrong."), null)
        }else {
            toasts.set(getHash("Something went wrong."), true)
            toast.warn("Something went wrong.", {
                position: toast.POSITION.TOP_CENTER,
                id: getHash("Something went wrong."),
                onClose : () => {toasts.delete(getHash("Something went wrong."))}
            })
        }
    }
};

function getHash(input){
    let hash = 0, len = input.length;
    for (let i = 0; i < len; i++) {
        hash  = ((hash << 5) - hash) + input.charCodeAt(i);
        hash |= 0; // to 32bit integer
    }
    return hash;
}
