import {AlbumActionType} from "./AlbumApi";
import {CacheActionType} from "../user/UserApi";

class AlbumCache {}

const initialState = {
    albumListCache : new Map(),
    albumCache : new Map()
};

export default function album( state = initialState, action){
    let newState = state;
    switch (action.type){
        case AlbumActionType.GET_ALBUM_BY_ID:
            newState = {
                ...state,
                albumCache : state.albumCache.set(action.key, action.data)
            };
            break;
        case AlbumActionType.GET_LIBRARY_ALBUMS:
        case AlbumActionType.GET_ALBUMS_OF_ARTIST:
        case AlbumActionType.GET_RECOMMENDED_ALBUMS:
            newState = {
                ...state,
                albumListCache : state.albumListCache.set(action.key, action.data)
            };
            break;
        case CacheActionType.INVALIDATE_KEY:
            let newListCache = state.albumListCache;
            let newCache = state.albumCache;
            newListCache.delete(action.key);
            newCache.delete(action.key);
            newState = {
                ...state,
                albumCache : newCache,
                albumListCache : newListCache
            };
            break;
        case CacheActionType.INVALIDATE_ALL:
            newState = {
                ...state,
                albumCache : new Map(),
                albumListCache : new Map()
            };
            break;
        default:
            break;
    }
    return newState;
}