import {makeFormApiCall, makeJsonApiCall} from "../ApiCall";
import {sortByTrackNo, sortByOrder} from "../../../Utilities";
import {CacheActionType} from "../user/UserApi";
import {AlbumActionType} from "../album/AlbumApi";
import {PlayerActionType} from "../../actions/player";

class MediaApi{}

export const MediaActionType = {
    GET_MEDIA_FROM_ALBUM : 'GET_MEDIA_FROM_ALBUM',
    GET_LIBRARY_MEDIA: 'GET_LIBRARY_MEDIA',
    GET_POPULAR_MEDIA_ARTIST : 'GET_POPULAR_MEDIA_ARTIST',
    GET_POPULAR_MEDIA : 'GET_POPULAR_MEDIA',
    GET_MEDIA_FROM_PLAYLIST : 'GET_MEDIA_FROM_PLAYLIST',
    GET_RECOMMENDED_SONGS : 'GET_RECOMMENDED_SONGS',
    GET_MEDIA_FROM_ARTIST : 'GET_MEDIA_FROM_ARTIST',
    GET_RECENTLY_PLAYED : 'GET_RECENTLY_PLAYED',
    SEARCH_ALL : 'SEARCH_ALL',
    ADD_MEDIA_TO_LIBRARY : 'ADD_MEDIA_TO_LIBRARY',
    REMOVE_MEDIA_FROM_LIBRARY: 'REMOVE_MEDIA_FROM_LIBRARY',
    GET_ALL_MEDIA_COUNT : 'GET_ALL_MEDIA_COUNT',
    GET_ALL_PLAYS_COUNT : 'GET_ALL_PLAYS_COUNT'
};

export const MediaActions = {
    getMediaFromAlbum,
    getLibraryMedia,
    getPopularMediaFromArtist,
    getPopularMedia,
    getMediaFromPlaylist,
    getRecommendedSongs,
    getRecentlyPlayed,
    getMediaFromArtist,
    searchAll,
    addToLibrary,
    removeFromLibrary,
    uploadMedia,
    songListened,
    addNewMediaToAlbum,
    deleteMedia,
    updateMedia,
    getAllMediaCount,
    getAllPlaysCount,
    getMediaStats,
    whatsHot,
};

function getMediaFromAlbum(albumId, callback, dispatch2 = null, getState2 = null) {
    if(dispatch2 === null) {
        return (dispatch, getState) => {
            if (getState().media.songListCache.has(MediaActionType.GET_MEDIA_FROM_ALBUM + albumId)) {
                callback(getState().media.songListCache.get(MediaActionType.GET_MEDIA_FROM_ALBUM + albumId))
            } else {
                makeJsonApiCall('GET', '/media/getMediaFromAlbum/' + albumId).then(songs => {
                    songs.sort(sortByTrackNo);
                    dispatch({
                        type: MediaActionType.GET_MEDIA_FROM_ALBUM,
                        key: MediaActionType.GET_MEDIA_FROM_ALBUM + albumId,
                        data: songs
                    });
                    callback(songs);
                })
            }
        }
    }else{
        if (getState2().media.songListCache.has(MediaActionType.GET_MEDIA_FROM_ALBUM + albumId)) {
            callback(getState2().media.songListCache.get(MediaActionType.GET_MEDIA_FROM_ALBUM + albumId))
        } else {
            makeJsonApiCall('GET', '/media/getMediaFromAlbum/' + albumId).then(songs => {
                songs.sort(sortByTrackNo);
                dispatch2({
                    type: MediaActionType.GET_MEDIA_FROM_ALBUM,
                    key: MediaActionType.GET_MEDIA_FROM_ALBUM + albumId,
                    data: songs
                });
                callback(songs);
            })
        }
    }
}


function getLibraryMedia(callback) {
    return (dispatch, getState) => {
        if(getState().media.songListCache.has(MediaActionType.GET_LIBRARY_MEDIA)){
            callback(getState().media.songListCache.get(MediaActionType.GET_LIBRARY_MEDIA));
        } else {
            makeJsonApiCall('GET', '/userMedia/getLibraryMedia').then(songs => {
                dispatch({
                    type: MediaActionType.GET_LIBRARY_MEDIA,
                    key: MediaActionType.GET_LIBRARY_MEDIA,
                    data: songs
                });
                callback(songs);
            });
        }
    }
}

function getPopularMediaFromArtist(artistId, callback) {
    return (dispatch, getState) => {
        if(getState().media.songListCache.has(MediaActionType.GET_POPULAR_MEDIA_ARTIST + artistId)){
            callback(getState().media.songListCache.get(MediaActionType.GET_POPULAR_MEDIA_ARTIST + artistId))
        } else {
            makeJsonApiCall('GET', '/media/popularMediaFromArtist/' + artistId).then(songs => {
                dispatch({
                    type: MediaActionType.GET_POPULAR_MEDIA_ARTIST,
                    key : MediaActionType.GET_POPULAR_MEDIA_ARTIST + artistId,
                    data : songs
                });
                callback(songs);
            });
        }
    }
}

function getPopularMedia(callback) {
    return (dispatch, getState) => {
        if(getState().media.songListCache.has(MediaActionType.GET_POPULAR_MEDIA)){
            callback(getState().media.songListCache.get(MediaActionType.GET_POPULAR_MEDIA))
        } else {
            makeJsonApiCall('GET', '/media/popularMedia').then(songs => {
                dispatch({
                    type : MediaActionType.GET_POPULAR_MEDIA,
                    key : MediaActionType.GET_POPULAR_MEDIA,
                    data : songs
                });
                callback(songs);
            })
        }
    }
}

function getMediaFromPlaylist(playlistId, callback) {
    return (dispatch, getState) => {
        if(getState().media.songListCache.has(MediaActionType.GET_MEDIA_FROM_PLAYLIST+playlistId)){
            callback(getState().media.songListCache.get(MediaActionType.GET_MEDIA_FROM_PLAYLIST+playlistId));
        }else{
            makeJsonApiCall('GET', '/playlistMedia/getMediaFromPlaylist/' + playlistId).then(songs => {
                dispatch({
                    type: MediaActionType.GET_MEDIA_FROM_PLAYLIST,
                    key : MediaActionType.GET_MEDIA_FROM_PLAYLIST + playlistId,
                    data : songs.map(song => {
                        song.playlist_id = playlistId;
                        return song;
                    })
                });
                callback(songs);
            })
        }
    }
}

function getRecommendedSongs(callback) {
    return (dispatch, getState) => {
        if(getState().media.songListCache.has(MediaActionType.GET_RECOMMENDED_SONGS)){
            callback(getState().media.songListCache.get(MediaActionType.GET_RECOMMENDED_SONGS));
        } else {
            makeJsonApiCall('GET', '/media/recommendedSongs').then(songs => {
                dispatch({
                    type : MediaActionType.GET_RECOMMENDED_SONGS,
                    key : MediaActionType.GET_RECOMMENDED_SONGS,
                    data : songs
                });
                callback(songs);
            });
        }
    }
}

function getRecentlyPlayed(callback) {
    return (dispatch, getState) => {
        if(getState().media.songListCache.has(MediaActionType.GET_RECENTLY_PLAYED)){
            callback(getState().media.songListCache.get(MediaActionType.GET_RECENTLY_PLAYED).sort(sortByOrder))
        }else {
            makeJsonApiCall('GET', '/media/recentlyPlayed').then(songs => {
                dispatch({
                    type : MediaActionType.GET_RECENTLY_PLAYED,
                    key : MediaActionType.GET_RECENTLY_PLAYED,
                    data : songs
                });

                callback(songs.sort(sortByOrder));
            });
        }
    }
}

function getMediaFromArtist(artistId, callback) {
    return (dispatch, getState) => {
        if(getState().media.songListCache.has(MediaActionType.GET_MEDIA_FROM_ARTIST+artistId)){
            callback(getState().media.songListCache.get(MediaActionType.GET_MEDIA_FROM_ARTIST+artistId));
        } else {
            makeJsonApiCall('GET', '/media/getMediaFromArtist/'+artistId).then(songs =>{
                songs.sort(sortByTrackNo);
                dispatch({
                    type : MediaActionType.GET_MEDIA_FROM_ARTIST,
                    key : MediaActionType.GET_MEDIA_FROM_ARTIST+artistId,
                    data : songs
                });
                callback(songs);
            });
        }
    }
}

function searchAll(searchStr, callback, onError) {
    return (dispatch, getState) => {
        if(getState().media.songListCache.has(MediaActionType.SEARCH_ALL + searchStr)){
            callback(getState().media.songListCache.get(MediaActionType.SEARCH_ALL + searchStr))
        }else{
            makeJsonApiCall('GET', '/media/searchAll?searchStr=' + searchStr, null, false).then(response => {
                dispatch({
                    type : MediaActionType.SEARCH_ALL,
                    key : MediaActionType.SEARCH_ALL + searchStr,
                    data : response
                });
                callback(response);
            }).catch(reason => {
                onError(reason)
            });
        }
    }
}

function removeFromLibrary(songId, dispatch) {
    makeJsonApiCall('DELETE', '/userMedia/media/' + songId).then(response => {
        dispatch({
            type: CacheActionType.INVALIDATE_KEY,
            key : MediaActionType.GET_LIBRARY_MEDIA
        });
        dispatch({
            type : MediaActionType.REMOVE_MEDIA_FROM_LIBRARY,
            data : {id : songId, inLibrary: false}
        })
        dispatch({
            type: PlayerActionType.REMOVE_MEDIA_FROM_LIBRARY,
            data: {id: songId, inLibrary: false}
        })
    });
}

function addToLibrary(songId, dispatch) {
    makeJsonApiCall('POST', '/userMedia/media/' + songId).then(response => {
        dispatch({
            type: CacheActionType.INVALIDATE_KEY,
            key : MediaActionType.GET_LIBRARY_MEDIA
        });
        dispatch({
            type : MediaActionType.ADD_MEDIA_TO_LIBRARY,
            data : { id: songId, inLibrary : true }
        });
        dispatch({
            type : PlayerActionType.ADD_MEDIA_TO_LIBRARY,
            data : { id: songId, inLibrary : true }
        });
    });
}

function uploadMedia(payload, allSongsInfo, callback, progress) {
    return (dispatch) => {
        let form = new FormData();
        let media = {};
        allSongsInfo.map((item, index) => {
            media['file' + (index + 1)] = item;
        });
        let album = {
            enabled : payload.enabled,
            name : payload.name,
            genre_id: payload.genreId,
            artist_id: payload.artistId,
            year: parseInt(payload.year),
            single: payload.single,
            ownership_declared: payload.ownershipDeclared
        };
        let songs = payload.songs;
        songs.map((song, index) => {
            form.append('file'+(index+1), song)
        });
        form.append('model', JSON.stringify({album: album, media : media}));
        form.append('avatar_file', payload.avatarFile);
        makeFormApiCall('POST', '/albums/withSongs', form, progress).then(response => {
            dispatch({
                type : CacheActionType.INVALIDATE_KEY,
                key: (AlbumActionType.GET_ALBUMS_OF_ARTIST+payload.artistId),
            });
            callback(response);
        })
    }
}

function songListened(songId, playlist_id = undefined) {
    return () => {
        let data = {media_id : songId};
        if (playlist_id !== undefined) {
            data.playlist_id = playlist_id
        }
        makeJsonApiCall('POST', '/statDailies',JSON.stringify(data), false).then(response => {
            //ignore response
        }).catch(e => {/*ignore error*/});
    }
}

function addNewMediaToAlbum(payload, albumId, callback, progress){
    return (dispatch) => {
        let data = new FormData();
        payload.songs.map((song, index) => {
            data.append("file"+index, song);
        });
        data.append("media", JSON.stringify(payload.info));
        dispatch({
            type : CacheActionType.INVALIDATE_KEY,
            key : MediaActionType.GET_MEDIA_FROM_ALBUM+albumId
        })
        makeFormApiCall('POST', "/media/addNewMediaToAlbum", data, progress).then(response => {
            callback(response)
        })
    }
}

function deleteMedia(mediaId, albumId, callback) {
    return (dispatch) => {
        makeJsonApiCall('DELETE', '/media/'+mediaId).then(response => {
            dispatch({
                type : CacheActionType.INVALIDATE_KEY,
                key: MediaActionType.GET_MEDIA_FROM_ALBUM+albumId
            });
            callback(response)
        });
    }
}

function updateMedia(payload, albumId, callback) {
    return (dispatch) => {
        makeJsonApiCall('PUT', '/media/updateMedia', JSON.stringify(payload)).then(response => {
            dispatch({
                type : CacheActionType.INVALIDATE_KEY,
                key : MediaActionType.GET_MEDIA_FROM_ALBUM+albumId
            });
            callback(response)
        })
    }
}

function getAllMediaCount(callback) {
    return () => {
        makeJsonApiCall('GET', '/media/getNumberOfAllSongs').then(response => {
            callback(response)
        })
    }
}

function getAllPlaysCount(callback) {
    return () => {
        makeJsonApiCall('GET', '/stats/getNumberOfAllStats').then(response => {
            callback(response)
        })
    }
}

function getMediaStats(callback) {
    return () => {
        makeJsonApiCall('GET', '/media/getStatOfMediaForAdmin').then(response => {
            callback(response)
        })
    }

}

function whatsHot(callback) {
    return () => {
        makeJsonApiCall('GET', '/media/whatshot').then(response => {
            callback(response)
        })
    }
}