import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from "redux";
import {AlbumActions} from "../../redux/api/album/AlbumApi";
import {MediaActions} from "../../redux/api/media/MediaApi";
import {UserActions} from "../../redux/api/user/UserApi";
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";
import {formatDuration} from "../../Utilities";
import DropdownComponentGenre from "../components/DropdownComponentGenre";

class ArtistAlbumDetailsComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            media: [],
            album: null,
            albumId: this.props.match.params.albumId,
            artistId: this.props.match.params.id,
            loading: false,
            editIndex: null,
            genres: null
        }

        this.setPayload = this.setPayload.bind(this)
        this.switchPayload = this.switchPayload.bind(this)
        this.changeGenre = this.changeGenre.bind(this)
        this.onSortEnd = this.onSortEnd.bind(this)
        this.handleSongChange = this.handleSongChange.bind(this)
        this.handleEditSong = this.handleEditSong.bind(this)
        this.deleteMedia = this.deleteMedia.bind(this)
        this.saveMediaInfo = this.saveMediaInfo.bind(this)
    }

    componentDidMount() {
        this.props.getAlbumById(this.state.albumId, cb => {
            this.setState({
                album: cb
            })
        })
        this.props.getMediaFromAlbum(this.state.albumId, cb => {
            this.setState({
                media: cb
            })
        })
        this.props.getGenres(cb => {
            this.setState({
                genres: cb
            })
        })
    }

    onSortEnd({oldIndex, newIndex}) {
        if (oldIndex === newIndex)
            return
        var media = arrayMove(this.state.media, oldIndex, newIndex)
        var mediaToUpdate = []
        media.forEach((m, index) => {
            if (m.track_no !== (index + 1)) {
                m.track_no = (index + 1)
                mediaToUpdate.push(m)
            }
        })
        this.props.updateMedia({media: mediaToUpdate}, this.state.albumId, cb => {
        })
        this.setState({media: media})
    }

    setPayload(e) {
        const {name, value} = e.target

        let album = this.state.album
        album[name] = value

        this.setState({album: album})
    }

    switchPayload(name) {
        let album = this.state.album;
        album[name] = !album[name];

        this.setState({album: album})
    }

    changeGenre(name, id) {
        let album = this.state.album;
        album.genre_id = id;

        this.setState({album: album})
    }

    handleSongChange(event) {
        event.preventDefault()
        const {name, value} = event.target
        const {media, editIndex} = this.state
        media[editIndex][name] = value

        this.toFocus = event.target.id

        this.setState({media: media})
    }

    handleEditSong(index) {
        this.setState({editIndex: index})
    }

    deleteMedia(index) {
        var media = this.state.media
        this.props.deleteMedia(media[index].id, this.state.albumId, res => {
            media.splice(index, 1)
            media.forEach((item, index) => {
                media[index].track_no = index + 1
            })
            this.props.updateMedia({media: media}, this.state.albumId, cb => {
            })
            this.setState({media: media})
        })
    }

    saveMediaInfo(media, editIndex) {

        this.setState({loading: true})
        this.props.updateMedia({media: [media[editIndex]]}, this.state.albumId, (res) => {
            this.setState({editIndex: null, loading: false});
        });
    }

    render() {
        const {laoding, album, genres, media, editIndex} = this.state

        const SortableItem = SortableElement(({value, sortIndex}) =>
            <div className="elementSortable">
                <span><i className="icon-menu-1"></i></span>
                <span>{value.track_no === null ? sortIndex + 1 : value.track_no}</span>
                <h3>{value.title}</h3>
                <span className="artist">{value.artist.name}</span>
                <span className="timer">{formatDuration(value.duration)}</span>
                <span className="more">
                    <i className="icon-edit-pencil-symbol" onClick={e => this.handleEditSong(sortIndex)}></i>
                    <i className="icon-close-circle" onClick={e => {
                        window.confirm('Are you sure you wish to delete this item?') ? this.deleteMedia(sortIndex) : null
                    }}></i>
                </span>
            </div>
        );

        const SortableList = SortableContainer(({items}) => {
            return (
                <div className="list list-vertical noscrool sortable">
                    <div className="wrapper">
                        {items.map((value, index) => (
                            <SortableItem
                                key={`item-${index}`}
                                index={index}
                                sortIndex={index}
                                value={value}
                            />
                        ))}
                    </div>
                </div>
            );
        });

        return (
            <div className="adminEditRelease">
                <div className="row">
                    <div className="col-12 my-5">

                        <div className="panel">
                            <div className="panel-header panel-header-column">
                                <h5>Main info</h5>
                            </div>
                            {album != null ?
                                <div className="content-row">
                                    <div className="w-25 text-center">
                                        <div className="cover cover-album">
                                            <i className="icon-camera"/>
                                            {album.image_url !== null ? <img src={album.image_url} alt="Cover"/> : null}
                                        </div>
                                    </div>

                                    <div className="w-75 ml-4">
                                        <div className="input-group mb-2">
                                            <input name='name' onChange={this.setPayload} value={album.name}
                                                   className="input" type="text" placeholder="Album title"/>
                                        </div>

                                        <div className="input-group mb-2">
                                            <input name='year' onChange={this.setPayload} value={album.year}
                                                   className="input" type="text" placeholder="Year"/>
                                        </div>

                                        <div className="input-group mb-2">
                                            <DropdownComponentGenre genreDisplay={genres.find(genre => {
                                                return genre.id === album.genre_id
                                            }).name}
                                                                    onGenreChange={this.changeGenre}
                                                                    listToDisplay={genres}/>
                                        </div>

                                        <div className="input-group mb-2">
                                            <div className="input checkbox">
                                                <input name='enabled'
                                                       onChange={(e) => this.switchPayload(e.target.name)}
                                                       checked={album.enabled}
                                                       className="input" id="enabled"
                                                       type="checkbox"/>
                                                <label htmlFor="enabled">Enable</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            <div className="d-flex justify-content-end">
                                <button className="button" onClick={e => {
                                    this.setState({loading: true})
                                    this.props.updateAlbumInfo({album: album}, (res) => {
                                        this.setState({loading: false})
                                    })
                                }}>Save
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-5">
                        <div className="panel">
                            <div className="panel-header">
                                <h5>Uploaded Songs</h5>
                            </div>
                            {editIndex === null ?
                                <SortableList items={media} helperClass="sortOnDrag" lockAxis="y"
                                              shouldCancelStart={e => {
                                                  return false
                                              }} pressDelay="100" onSortOver={this.onSortOver}
                                              onSortEnd={this.onSortEnd}/>
                                :
                                <div className="content-row flex-column">
                                    <div className="input-group align-items-center mb-2">
                                        <label htmlFor="title" className="m-0 mr-4">Title:</label>
                                        <input name='title' onChange={this.handleSongChange}
                                               value={media[editIndex].title} id="title"
                                               style={{width: "auto", flexGrow: "1"}} className="input" type="text"
                                               placeholder="Title"/>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button className="button"
                                                onClick={e => this.saveMediaInfo(media, editIndex)}>Save
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        getAlbumById: bindActionCreators(AlbumActions.getAlbum, dispatch),
        getMediaFromAlbum: bindActionCreators(MediaActions.getMediaFromAlbum, dispatch),
        getGenres: bindActionCreators(UserActions.getGenres, dispatch),
        deleteMedia: bindActionCreators(MediaActions.deleteMedia, dispatch),
        updateAlbumInfo: bindActionCreators(AlbumActions.updateAlbumInfo, dispatch),
        updateMedia: bindActionCreators(MediaActions.updateMedia, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistAlbumDetailsComponent)