import React from 'react';
import {
    Link, withRouter
} from 'react-router-dom'
import LoaderComponent from "../../components/LoaderComponent";
import {devConsoleLog} from "../../../Utilities";
import {toast} from "react-toastify";
import {app, facebookProvider, googleProvider, twitterProvider} from "../../../base";
import {UserActions} from "../../../redux/api/user/UserApi";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import isEmail from "validator/lib/isEmail";
import {LoginActions} from "../../../redux/actions/LoginActions";

class Login extends React.Component {

    state = {
        password : null,
        login : null,
        errors: new Map()
    };

    getOs(){
        let OSName = "Unknown OS";
        if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
        if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
        if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
        if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";

        return OSName
    }

    handleKeyPress = (e) => {
        if(e !== undefined && e.key !== undefined) {
            if (e.key === 'Enter') {
                this.validateAndLogin()
            }
        }
    };

    loginWithFirebase(provider){
        const auth = app.auth();
        this.setState({loading : true});
        auth.app.firebase_.auth().signInWithPopup(provider).then((result, error) => {
            if(error){
                this.setState({loading : false});
                toast.error("Unable to login to " +
                    provider === facebookProvider ? "Facebook" :
                    provider === twitterProvider? "Twitter" : "Google", {
                    position: toast.POSITION.TOP_CENTER
                });
            }else{
                auth.app.firebase_.auth().signInWithCredential(result.credential).then((userCredential) => {
                    userCredential.user.getIdToken().then(token => {
                        let params = {firebaseToken : token, email : result.user.email, nickname : result.user.displayName};
                        this.logUser(params);
                    })
                })
            }
        }).catch(reason => {
            devConsoleLog(reason);
            this.setState({loading : false});
        })
    }

    validateAndLogin(){
        let errors = new Map();
        if (!this.state.login) errors.set('login', 'Email Required');
        if (this.state.login && !isEmail(this.state.login)) errors.set('login', 'Invalid Email')
        if (!this.state.password) {
            errors.set('password', 'Password Required');
        }

        if(errors.size === 0) {
            this.logUser({login: this.state.login, password: this.state.password});
        }else {
            this.setState({errors: errors});
        }
    }

    logUser(params){
        this.setState({loading: true});
        params['os'] = this.getOs();

        this.props.logUser(params, (response) => {
            if (response.status === -1) {
                let err = new Map()
                err.set('login', response.message)
                this.setState({
                    loading: false,
                    errors: err
                });
            } else {
                this.props.onLoginDispatch({
                    role: response.role,
                    id: response.id,
                    creation_date: response.creation_date,
                    firstLogin: response.firstLogin
                });
                this.setState({loading: false});
            }
        })
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-7 d-flex align-items-center">
                    <h1>Login to OPUS<br/>web player</h1>
                </div>
                <div className="col-lg-5">
                    <div className="panel">
                        {this.state.loading &&
                        <LoaderComponent withBg={true}/>
                        }
                        <form action="#">
                            <div className="form-group withError">
                                <input onKeyPress={(e) => this.handleKeyPress(e)} name='login' onChange={(e) => {
                                    this.setState({login : e.target.value})
                                }} type="email" className="form-control"
                                    placeholder="Your e-mail address"/>
                                {this.state.errors.has('login') ?
                                    <div className="invalid-feedback">{this.state.errors.get('login')}</div>
                                    : null}
                            </div>
                            <div className="form-group withError">
                                <input onKeyPress={(e) => this.handleKeyPress(e)} name='password'
                                    onChange={(e) => {
                                        this.setState({password : e.target.value})
                                    }} type="password" className="form-control"
                                    placeholder="Password"/>
                                {this.state.errors.has('password') ?
                                    <div className="invalid-feedback">{this.state.errors.get('password')}</div>
                                    : null}
                            </div>
                            <div className="form-group">
                                <Link to="/forgotten-password">Forgot your password?</Link>
                                <button className="btn"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.validateAndLogin()
                                        }}>Login
                                </button>
                            </div>
                            <div className="hr" data-text="true">OR</div>
                            <div className="row">
                                <div className="col-6 mb-3 mx-auto">
                                    <a onClick={()=>this.loginWithFirebase(facebookProvider)} className="btn btn-block facebook"> <i
                                        className="icon-facebook-squared"/>Facebook</a>
                                </div>
                                <div className="col-6 mb-3 mx-auto">
                                    <a onClick={()=>this.loginWithFirebase(googleProvider)} className="btn btn-block google"><i
                                        className="icon-google"/>Google</a>
                                </div>
                                <div className="col-6 mb-3 mx-auto">
                                    <a onClick={()=>this.loginWithFirebase(twitterProvider)} className="btn btn-block twitter"><i
                                        className="icon-twitter"/>Twitter</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        logUser : bindActionCreators(UserActions.login, dispatch),
        onLoginDispatch: bindActionCreators(LoginActions.login, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));