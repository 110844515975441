import {MediaActionType} from "./MediaApi";
import {CacheActionType} from "../user/UserApi";
import {PlaylistActionType} from "../playlist/PlaylistApi";

class MediaCache{}

const initialState = {
    songListCache : new Map()
};

let cacheWithSongs = [
    MediaActionType.GET_MEDIA_FROM_ALBUM,
    MediaActionType.GET_POPULAR_MEDIA,
    MediaActionType.GET_POPULAR_MEDIA_ARTIST,
    MediaActionType.GET_MEDIA_FROM_PLAYLIST,
    MediaActionType.GET_RECOMMENDED_SONGS,
    MediaActionType.GET_MEDIA_FROM_ARTIST,
    MediaActionType.GET_RECENTLY_PLAYED,
    MediaActionType.SEARCH_ALL
];

export default function media (state = initialState, action) {
    let newState = state;
    switch (action.type) {
        case MediaActionType.GET_MEDIA_FROM_PLAYLIST:
        case MediaActionType.GET_MEDIA_FROM_ALBUM:
        case MediaActionType.GET_LIBRARY_MEDIA:
        case MediaActionType.GET_POPULAR_MEDIA_ARTIST:
        case MediaActionType.GET_POPULAR_MEDIA:
        case MediaActionType.GET_RECOMMENDED_SONGS:
        case MediaActionType.GET_MEDIA_FROM_ARTIST:
        case MediaActionType.SEARCH_ALL:
            newState = {
                ...state,
                songListCache : state.songListCache.set(action.key, action.data)
            };
            break;
        case CacheActionType.INVALIDATE_KEY:
            let newListCache = state.songListCache;
            newListCache.delete(action.key);
            newState = {
                ...state,
                songListCache : newListCache
            };
            break;
        case CacheActionType.INVALIDATE_ALL:
            newState = {
                ...state,
                songListCache: new Map()
            };
            break;
        case MediaActionType.ADD_MEDIA_TO_LIBRARY:
        case MediaActionType.REMOVE_MEDIA_FROM_LIBRARY:
            let values = new Map();
            cacheWithSongs.forEach((key) => {
                if(state.songListCache.has(key))
                    values.set(key, searchSongAndChange(action.data.id, action.data.inLibrary, key, state.songListCache.get(key)))
            });
            newState = {
                ...state,
                songListCache: values
            };
            break;
        case PlaylistActionType.REMOVE_MEDIA_FROM_PLAYLIST:
            if(state.songListCache.has(action.key)){
                let newCache = state.songListCache;
                let playlistSongs = [];
                state.songListCache.get(action.key).forEach((value) => {
                    if(value.id !== action.data.mediaId){
                        playlistSongs.push(value);
                    }
                });
                newCache.set(action.key, playlistSongs)
                newState = {
                    ...state,
                    songListCache: newCache
                }
            }
            break;
        default:
            break;
    }
    return newState;
}

function searchSongAndChange(songId, inLibrary, key, cache) {
    if(key === MediaActionType.GET_RECOMMENDED_SONGS){
        cache.songs.forEach((value) => {
            if(value.id === songId){
                value.inLibrary = inLibrary
            }
        });
        return cache;
    } else {
        cache.forEach((value) => {
            if (value.id === songId) {
                value.inLibrary = inLibrary;
            }
        });
        return cache;
    }
}
