import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {UserActions} from "../../redux/api/user/UserApi";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {toast} from "react-toastify";

class ForgottenPassword extends Component {

    state = {
        username: '',
    }

    onHandleEmailChange(e) {
        this.setState({username: e.target.value})
    }

    render() {
        return (
            <div className="panelBody">
                <div className="container-fluid">
                    <div className="create-wallet">
                        <div className="header p-5 d-flex justify-content-between align-items-center">
                            <Link to="/artist-login"><img src="/img/logo-black.png" alt="OPUS"/></Link>
                            <Link to="/artist-login" className="closeButton"><i className="icon-close-circle"/> Close</Link>
                        </div>
                        <div className="content-center text-center">
                            <h2 className="light my-3">Forgot your password?</h2>
                            <p className="regular mb-5">Just enter the e-mail address you registered with, and we will send instructions on how to reset your password.</p>
                            <input value={this.state.username} onChange={(e) => {
                                this.onHandleEmailChange(e)
                            }} type="text" className="walletPassword" placeholder="E-mail address"/>
                        </div>
                        <div className="footer">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <Link onClick={(e) => {
                                            this.props.forgottenPassword(this.state.username, response => {
                                                toast.success(response.message, {
                                                    position: toast.POSITION.TOP_CENTER
                                                });
                                            })
                                        }} to="/artist-login" className="button mx-auto my-4">Send e-mail</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        forgottenPassword : bindActionCreators(UserActions.forgottenPassword, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);