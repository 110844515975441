import Web3js from 'web3'
import { opusB1TokenAbi as tokenAbi, opusB1TokenAbi } from './scenes/abi/OpusTokenABI.js'
import { config } from './config'
import EthereumTx from 'ethereumjs-tx'
import wallet, { generate } from 'ethereumjs-wallet'
import { toBuffer } from 'ethereumjs-util'
import cryptoBrowser from 'crypto-browserify'
import moment from 'moment'
import FileSaver from 'file-saver'
import { etherscanApiKey } from './base.js'

import worker from 'workerize-loader!./EthWorker';

let w3js = null;
let _contract = null

export const EthHelper = {
  isAddress,
  newEthersWallet,
  downloadKs,
  downloadRecoveryFile,
  turnStringToHex,
  downloadRecoveryFileAfterRegister,
  getEthAddressTransactions,
  getEthBalance,
  getCustomTokenBalance,
  showRemainingAllowance,
  sendOptToContract,
  approve,
  checkStatus,
  importFromPrivateKey,
  importFromKeystore,
  generateWallet,
  // ethRequiredToFillFee, // TODO: To implement
}

/**
 * Get access to web3js
 * @return {Object} Web3 object with provider
 */
const web3 = () => {
  if (!w3js)
    // eslint-disable-next-line max-len
    w3js = new Web3js(new Web3js.providers.HttpProvider(config.WEB3_HOST));

  return w3js;
};

/**
 * Get contract instance
 * @returns {Object} Contract instance
 */
const tokenInst = () => {
  const w3 = web3();
  if (!_contract)
    // eslint-disable-next-line max-len
    _contract = new w3.eth.Contract(tokenAbi, config.TOKEN_ADDRES);

  return _contract;
};

/**
 * Get contract methods
 * @returns {Object} Contract methods
 */
const tokenMethods = () => {
  return tokenInst().methods;
};

function isAddress(wallet) {
  return web3().utils.isAddress(wallet)
}

const formatNumber = number => {
  return Number(number).toLocaleString('fullwide', {useGrouping: false})
}

const estimateGas = txObject => new Promise((resolve, reject) => {
  web3().eth.estimateGas(txObject)
  .then(gas => {
    resolve({...txObject, ...{gasLimit: gas}})
  })
  .catch(err => {
    const errStr = err.toString()
    if(errStr.slice(0, 53) === 'Error: Returned error: gas required exceeds allowance' &&
     errStr.slice(errStr.length - 29, errStr.length)) {
      reject(new Error('You don\'t have enough ETH or OPT to execute.'))
    } else {
      reject(err)
    }
  })
})

const getGasPrice = txObject => new Promise((resolve, reject) => {
  web3().eth.getGasPrice()
  .then(gasPrice => {
    resolve({...txObject, ...{gasPrice: web3().utils.toHex(gasPrice)}})
  })
  .catch(reject)
})

const calculateFee = txObject => new Promise((resolve, reject) => {
  getGasPrice(txObject)
  .then(txObject2 => {
    estimateGas(txObject2)
    .then(txObject3 => {
      resolve(txObject3.gas * txObject3.gasPrice)
    })
    .catch(reject)
  })
  .catch(reject)
})

const sendSingedTx = (txObject, privateKey) => new Promise((resolve, reject) => {
  web3().eth.getTransactionCount(txObject.from, 'pending')
  .then(nonce => {
    txObject.nonce = web3().utils.toHex(nonce)
    const tx = new EthereumTx(txObject, {chain: config.WEB3_CHAIN})
    tx.sign(Buffer.from(privateKey, 'hex'))

    web3().eth.sendSignedTransaction('0x' + tx.serialize().toString('hex'))
    .then(hash => {
      console.log('tx_hash', hash.transactionHash)
      resolve(hash.transactionHash)
    })
    .catch(err => {
      reject({code: err, txObject: txObject})
    })
  }).catch(reject)
})

function newEthersWallet(name, password, callback) {
  // let walletBuff = wallet.generate([true])
  // let walletLightJSONv3 = walletBuff.toV3(password)

  // walletLightJSONv3.address = turnStringToHex(walletLightJSONv3.address)
  // let walletObjectToSave = {
  //     data: walletLightJSONv3,
  //     name: name,
  //     type: "generated",
  //     address: turnStringToHex(walletBuff.getAddressString()),
  //     privateKey: turnStringToHex(walletBuff.getPrivateKeyString()),
  // }
  // localStorage.setItem("OPUSwallet", JSON.stringify(walletObjectToSave))
  // callback(walletObjectToSave)
  generateWallet(password)
  .then(walletObjectToSave => {
    const wallet = {...walletObjectToSave, ...{name}}
    localStorage.setItem("OPUSwallet", JSON.stringify(wallet))
    callback(wallet);
  })
}

function downloadKs(e, data, address) {
  e.preventDefault()
  let date = moment().format();
  let blob = new Blob([JSON.stringify(data)], {type: "text/plain;charset=utf-8"});
  FileSaver.saveAs(blob, "Keystore-" + date + "-" + address);
}

function turnStringToHex(str) {
  let newStr = str
  try {
      let strArr = str.split("")
      if (strArr[0] !== "0" || strArr[1] !== "x") {
          newStr = "0x" + str
      }
  } catch (err) {
      throw new Error(err)
  }
  return newStr
}

function turnHexToString(hex) {
  let newStr = hex
  try {
      let strArr = hex.split('')
      if (strArr[0] === "0" && strArr[1] === "x") {
          newStr = strArr.slice(2, strArr.length).join('')
      }
  } catch (err) {
      throw new Error(err)
  }
  return newStr
}


function downloadRecoveryFile(privateKey) {
  // Prepare payload to get information about file
  const key = cryptoBrowser.randomBytes(256 / 8);
  const iv = cryptoBrowser.randomBytes(16);

  const payload = {
      algorithm: config.CIPHER,
      key: JSON.stringify(key),
  }

  // myFetch(config.API_URL + '/walletrecovery', {
  //     method: 'POST',
  //     body: JSON.stringify(payload),
  // }).then(res => res.json()).then(res => {
  //     if(res.success) {
  //         // Encrypt file
  //         let cipher = cryptoBrowser.createCipheriv(config.CIPHER, Buffer.from(key), iv);
  //         let encrypted = cipher.update(privateKey);
  //         encrypted = Buffer.concat([encrypted, cipher.final()]);
  //         let toSave = {
  //             iv: iv.toString('hex'),
  //             encryptedData: encrypted.toString('hex'),
  //             originalFileName: res.data.filename,
  //         };
  //         // Save file
  //         let blob = new Blob([JSON.stringify(toSave)], {type: 'binary'})
  //         FileSaver.saveAs(blob, res.data.filename);
  //     }
  // })
}

function downloadRecoveryFileAfterRegister(privateKey, key, iv, filename) {
  // Encrypt file
  let cipher = cryptoBrowser.createCipheriv(config.CIPHER, Buffer.from(key), iv);
  let encrypted = cipher.update(privateKey);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  let toSave = {
    iv: iv.toString('hex'),
    encryptedData: encrypted.toString('hex'),
    originalFileName: filename,
  };
  // Save file
  let blob = new Blob([JSON.stringify(toSave)], { type: 'binary' })
  FileSaver.saveAs(blob, filename);
}

// TODO:
function restoreWalletFromFile(file) {
  // return dispatch => {
  //     const filename = file.name;
  //     // Read file to json format
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //         const recoveryObj = JSON.parse(reader.result);
  //         // Send request to api to get key to decrypt private key
  //         myFetch(config.API_URL + '/walletrecovery/recovery', {
  //             method: 'POST',
  //             body: JSON.stringify({
  //                 filename: recoveryObj.originalFileName,
  //             }),
  //         }).then(res => res.json()).then(res => {
  //             if (res.success) {
  //                 // Decrypt file
  //                 let iv = Buffer.from(recoveryObj.iv, 'hex')
  //                 let encryptedData = Buffer.from(recoveryObj.encryptedData, 'hex')
  //                 let decipher = cryptoBrowser.createDecipheriv(res.recoveryData.cipher, Buffer.from(JSON.parse(res.recoveryData.key).data), iv)
  //                 let decrypted = decipher.update(encryptedData)
  //                 decrypted = Buffer.concat([decrypted, decipher.final()])
  //                 // Import wallet as private key
  //                 let privateKeyBuffer = ethUtil.toBuffer(web3Service.turnStringToHex(decrypted.toString()))
  //                 let walletBuffer = wallet.fromPrivateKey(privateKeyBuffer)
  //                 let walletLightJSONv3 = walletBuffer.toV3('')
  //                 const endWalletName = 'My wallet'
  //                 let walletObjectToSave = {
  //                     data: walletLightJSONv3,
  //                     address: web3Service.turnStringToHex(walletLightJSONv3.address),
  //                     name: endWalletName,
  //                     type: "fromPrivateKey",
  //                 }
  //                 // Save and assign wallet to account
  //                 web3Service.saveWallet(walletObjectToSave)
  //                 dispatch(userActions.assignWallet(walletObjectToSave.address))
  //                 dispatch(getWallet(walletObjectToSave.address))
  //                 dispatch(alertActions.success('Wallet recovered successfully'))
  //                 history.push('/panel/wallet')
  //             } else {
  //                 dispatch(alertActions.error(res.message))
  //             }
  //         })
  //     }
  //     reader.readAsText(file);
  // }
}

const etherscanAPI = require('etherscan-api').init(etherscanApiKey, config.WEB3_CHAIN);
const abiDecoder = require('abi-decoder')
function getEthAddressTransactions(address, fromBlock = 0) {
  return new Promise((resolve, reject) => {
    etherscanAPI.account.txlist('0xBA8081dbECE213847881305EbD916E17050B77f1', 0, 'latest', 'desc')
    .then(response => {
      abiDecoder.addABI(opusB1TokenAbi)
      let resultArray = [];
      response.result.map(element => {
        let date = moment.unix(element.timeStamp).format("MM/DD/YYYY");
        if (element.value === "0") {
          let decodeInput = abiDecoder.decodeMethod(element.input);
          if (decodeInput !== undefined) {
            if (decodeInput.params[1].value !== 0) {
              let newElement = {
                blockNumber: element.blockNumber,
                date: date,
                hash: element.hash,
                value: 0,
                type: decodeInput.name.toUpperCase(),
                success: Number(element.isError) !== 1
              };
              newElement.value = parseInt(Math.round(decodeInput.params[1].value) / (1 * Math.pow(10, 18)));
              if (element.from.toUpperCase() === address.toUpperCase()) {
                newElement.value *= -1;
              }
              newElement.value += " OPT"
              if (newElement.type === "APPROVE") {
                newElement.value = "-";
              }
              resultArray.push(newElement)
            }
          }
        } else {
          let newElement = {
            blockNumber: element.blockNumber,
            date: date,
            hash: element.hash,
            value: 0,
            type: 'ETH OPERATION',
            success: Number(element.isError) !== 1
          };
          newElement.value = parseInt(element.value) / (1 * Math.pow(10, 18))
          if (element.from.toUpperCase() === address.toUpperCase()) {
            newElement.value *= -1;
          }
          newElement.value += " ETH"
          resultArray.push(newElement)
          return;
        }

      })

      resolve(resultArray)
    })
    .catch(err => {
      resolve([])
    })
  })
}

function getEthBalance(address) {
  return new Promise((resolve, reject) => {
    web3().eth.getBalance(turnStringToHex(address))
    .then(balance => {
      resolve(balance / Math.pow(10, 18));
    })
  })
}

function getCustomTokenBalance(address) {
  return new Promise((resolve, reject) => {
    tokenMethods().decimals().call()
    .then(decimals => {
      tokenMethods().balanceOf(turnStringToHex(address)).call()
      .then(balance => {
        resolve(balance / Math.pow(10, decimals))
      })
      .catch(reject)
    })
    .catch(reject)
  })
}

function showRemainingAllowance(walletAddress) {
  return new Promise((resolve, reject) => {
    tokenMethods().decimals().call()
    .then(decimals => {
      tokenMethods().allowance(turnStringToHex(walletAddress), turnStringToHex(config.CONTRACT_ADDRESS)).call()
      .then(remaining => {
        resolve(remaining / Math.pow(10, decimals))
      })
    })
    .catch(reject)
  })
}

function sendOptToContract(from, privateKey, amountToTransfer) {
  return new Promise((resolve, reject) => {

    tokenMethods().decimals().call()
    .then(decimals => {
      let txObject = {
        from,
        to: config.TOKEN_ADDRES,
        value: web3().utils.toHex(0),
        data: tokenMethods().transfer(config.CONTRACT_ADDRESS, formatNumber(amountToTransfer * Math.pow(10, decimals))).encodeABI(),
      }

      getGasPrice(txObject)
      .then(estimateGas)
      .then(txObj => {
        sendSingedTx(txObj, turnHexToString(privateKey)).then(hash => {
          resolve(hash)
        }).catch(err => {
          reject(err)
        })
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
    })
    .catch(reject)

  })
}

function approve(from, privateKey, amountToPay) {
  return new Promise((resolve, reject) => {
    tokenMethods().decimals().call()
    .then(decimals => {
      let txObject = {
        from,
        to: config.TOKEN_ADDRES,
        value: web3().utils.toHex(0),
        data: tokenMethods().approve(config.CONTRACT_ADDRESS, formatNumber(amountToPay * Math.pow(10, decimals))).encodeABI()
      }

      getGasPrice(txObject)
      .then(estimateGas)
      .then(txObj => {
        sendSingedTx(txObj, turnHexToString(privateKey))
        .then(hash => {
          resolve(hash)
        })
        .catch(err => {
          reject(err)
        })
      })
      .catch(reject)
    })
    .catch(reject)
  })
}

// function ethRequiredToFillFee(amountToPay) {
//   return new Promise((resolve, reject) => {
//     tokenMethods().decimals().call()
//     .then(decimals => {
//       let transferObject = {
//         // to: config.TOKEN_ADDRES,
//         // value: web3().utils.toHex(0),
//         data: tokenMethods().approve(config.CONTRACT_ADDRESS, formatNumber(amountToPay * Math.pow(10, decimals))).encodeABI()
//       }
//       let approveObject = {
//         // to: config.TOKEN_ADDRES,
//         // value: web3().utils.toHex(0),
//         data: tokenMethods().transfer(config.CONTRACT_ADDRESS, formatNumber(amountToPay * Math.pow(10, decimals))).encodeABI(),
//       }

//       getGasPrice({})
//       .then(({gasPrice}) => {
//         const gp = web3().utils.hexToNumber(gasPrice) / Math.pow(10, 9)
//         Promise.all([
//           estimateGas(transferObject),
//           estimateGas(approveObject),
//         ])
//         .then(([transfer, approve]) => {
//           console.log('fee', transfer, approve)
//         })
//       })

//       resolve(1);
//     })
//     .catch(reject);
//   })
// }

function checkStatus(hash) {
  const transactionReceiptAsync = function (resolve, reject) {
    web3().eth.getTransactionReceipt(hash)
    .then(receipt => {
      if (receipt) {
        resolve(receipt.status)
      } else {
        setTimeout(() => transactionReceiptAsync(resolve, reject), 5000)
      }
    })
    .catch(err => {
      reject(err)
    })
  };

  return new Promise(transactionReceiptAsync)
}

function importFromPrivateKey(privatekey, password) {
  return new Promise(async (resolve, reject) => {
    if (typeof Worker !== 'undefined') {
      let inst = worker()
      inst
        .importFromPrivateKey(privatekey, password)
        .then(resolve)
        .catch(reject)
    } else {
      try {
        let privateKeyBuffer = toBuffer(turnStringToHex(privatekey))
        let walletBuffer = wallet.fromPrivateKey(privateKeyBuffer)
        let walletLightJSONv3 = await walletBuffer.toV3(password)
        let walletObjectToSave = {
          data: walletLightJSONv3,
          address: turnStringToHex(walletLightJSONv3.address),
          type: 'fromPrivateKey',
          privateKey: turnHexToString(privatekey)
        }
        resolve(walletObjectToSave)
      } catch (err) {
        reject(err)
      }
    }
  })
}

function importFromKeystore(file, password) {
  return new Promise((resolve, reject) => {
    if (typeof Worker !== 'undefined') {
      let inst = worker()
      inst.importFromKeystore(file, password)
        .then(resolve)
        .catch(reject)
    } else {
      let reader = new FileReader()
      reader.onload = async (e) => {
        try {
          let keystoreFile = reader.result
          let walletBuffer = await wallet.fromV3(keystoreFile, password, [true])
          let walletData = JSON.parse(keystoreFile)
          let walletObjectToSave = {
            data: walletData,
            address: turnStringToHex(walletData.address),
            type: 'fromKeystore',
            privateKey: turnHexToString(walletBuffer.getPrivateKeyString())
          }
          resolve(walletObjectToSave)
        } catch (err) {
          reject(err)
        }
      }
      reader.readAsText(file)
    }
  })
}

function generateWallet(password) {
  return new Promise(async (resolve, reject) => {
    if (typeof Worker !== 'undefined') {
      let inst = worker()
      inst.generateWallet(password)
        .then(resolve)
        .catch(reject)
    } else {
      console.log('generate without worker');
      let walletBuff = wallet.generate([true])
      let walletLightJSONv3 = await walletBuff.toV3(password)

      walletLightJSONv3.address = turnStringToHex(walletLightJSONv3.address)
      let walletObjectToSave = {
        data: walletLightJSONv3,
        type: "generated",
        address: turnStringToHex(walletBuff.getAddressString()),
        privateKey: turnStringToHex(walletBuff.getPrivateKeyString()),
      }
      resolve(walletObjectToSave);
    }
  })
}
