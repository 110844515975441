import { makeFormApiCall, makeJsonApiCall } from "../ApiCall";
import user from "./UserCache";
import jwt from 'jsonwebtoken';
import { getApiRoot } from "../../../config";
import { errorHandler } from "../ErrorHandler";
import { nodeEnv } from "../../../base";
import { LOGOUT_USER } from "../../actions/LoginActions";
import moment from 'moment';
import * as Sentry from '@sentry/browser';

class UserApi { } // just for search

export const CacheActionType = {
    INVALIDATE_KEY: 'INVALIDATE_KEY',
    INVALIDATE_ALL: 'INVALIDATE_ALL'
};

export const UserActionType = {
    USER_LOGIN: 'USER_LOGIN',
    GET_CURRENT_USER: 'GET_CURRENT_USER',
    GET_COUNTRIES: 'GET_COUNTRIES',
    GET_GENRES: 'GET_GENRES',
    GET_STATS_FROM_DAYS: 'GET_STATS_FROM_DAYS',
    GET_LATEST_PAYMENTS: 'GET_LATEST_PAYMENTS',
    GET_STATS_DAILY_BY_HASH: 'GET_STATS_DAILY_BY_HASH',
    GET_QUOTATION: 'GET_QUOTATION',
};

export const UserActions = {
    login,
    register,
    registerAsArtist,
    getCurrentUser,
    resetPassword,
    getCountries,
    updateUser,
    changePassword,
    getGenres,
    deleteAccount,
    getStatsFromDays,
    getLatestPayments,
    getStatsDailyByHash,
    logoutUser,
    forgottenPassword,
    activateAccount,
    getUsersWaitingForApproval,
    approveOrDeclineUserToArtist,
    getArtistsWaitingForApproval,
    approveArtist,
    postOnePaymentIsDone,
    postAutoPaymentIsDone,
    sendMessageToNewsletter,
    getListeningsStats,
    getRegistrationStats,
    sendEmail,
    getQuotation,
};

function login(logData, callback) {
    return () => {
        let data = {
            'user': {
                'email': logData.login,
                'password': logData.password,
                'nickname': logData.nickname,
                'firebaseToken': logData.firebaseToken,
                'device': logData.device,
                'os': logData.os,
                'ip': logData.ip,
                'gmt': new Date().getTimezoneOffset()
            }
        };
        makeJsonApiCall('POST', '/users/login', JSON.stringify(data), false).then(response => {
            if (response.status === -1) {
                localStorage.setItem('loggedAs', 'UNDEFINED');
                callback({ 'role': "UNDEFINED", 'errorLoginFlag': true, 'errorMessage': response.message, status: -1 });
            } else {
                let decodedToken = jwt.decode(response.token);
                saveTokenData(decodedToken);
                // if(nodeEnv !== "localhost" && nodeEnv !== "dev"){
                //     localStorage.setItem('apiLocationURL', response.apiNode);
                // }
                localStorage.setItem('tokenAccess', response.token);

                Sentry.configureScope(function (scope) {
                    scope.setUser({
                        email: decodedToken.email,
                        id: decodedToken.user_id,
                    })
                })

                callback({
                    role: decodedToken.role_code,
                    id: decodedToken.user_id,
                    email: decodedToken.email,
                    errorLoginFlag: false,
                    status: 1,
                    creation_date: response.user.creation_date,
                    firstLogin: response.firstLogin,
                });
                //todo save response as current user?
            }
        }).catch(response => callback(response))
    }
}

function saveTokenData(token) {
    localStorage.setItem('isSubActive', moment(token.sub_from).isBefore(moment()) && moment(token.sub_to).add(15, 'minutes').isAfter(moment()));
    localStorage.setItem('userId', token.user_id);
    localStorage.setItem('email', token.email);
    // localStorage.setItem('subFrom', (new Date(Date.parse(token.sub_from))).toLocaleDateString('en-US'));
    // localStorage.setItem('subTo', (new Date(Date.parse(token.sub_to))).toLocaleDateString('en-US'));
    localStorage.setItem('subFrom', token.sub_from);
    localStorage.setItem('subTo', token.sub_to);
    localStorage.setItem('avatar', token.user_avatar_url);
    localStorage.setItem('wallet', token.wallet);
    localStorage.setItem('opusTokenAddr', token.opus_token_addr);
    localStorage.setItem('opusMasterContractAddr', token.opus_master_contract_addr);
    localStorage.setItem('opusMasterContractOwnerAddr', token.opus_master_contract_owner_addr);
    localStorage.setItem('role', token.role_code);
    localStorage.setItem('OPUSwallet', localStorage.getItem(`OPUSwallet${token.user_id}`));
}

function register(registerData, callback) {
    return () => {
        makeJsonApiCall('POST', '/users/addUser', JSON.stringify(registerData), false).then(response => {
            callback(response)
        }).catch(reason => callback(reason))
    }
}

function registerAsArtist(registerData) {
    return new Promise((resolve, reject) => {
        makeFormApiCall('POST', '/users/add-with-artist', registerData, null, false)
            .then(resolve)
            .catch(reject)
    })
}

function getCurrentUser(callback, fromCacheIfAvailable = true) {
    return (dispatch, getState) => {
        if (getState().user.cache.has(UserActionType.GET_CURRENT_USER) && fromCacheIfAvailable) {
            callback(getState().user.cache.get(UserActionType.GET_CURRENT_USER));
        } else {
            makeJsonApiCall('GET', '/users/current').then(user => {
                localStorage.setItem('isSubActive', moment(user.sub_from).isBefore(moment()) && moment(user.sub_to).add(15, 'minutes').isAfter(moment()))
                dispatch({
                    type: UserActionType.GET_CURRENT_USER,
                    key: UserActionType.GET_CURRENT_USER,
                    data: user
                });

                Sentry.configureScope(function (scope) {
                    scope.setUser({
                        email: user.email,
                        id: user.id,
                    })
                })

                callback(user);
            })
        }
    }
}

function getCountries(callback) {
    return (dispatch, getState) => {
        if (getState().user.cache.has(UserActionType.GET_COUNTRIES)) {
            callback(getState().user.cache.get(UserActionType.GET_COUNTRIES));
        } else {
            makeJsonApiCall('GET', '/countries').then(countries => {
                dispatch({
                    type: UserActionType.GET_COUNTRIES,
                    key: UserActionType.GET_COUNTRIES,
                    data: countries
                });
                callback(countries);
            })
        }
    }
}

function updateUser(payload, avatarChanged, dataChanged, callback) {
    return (dispatch) => {
        let form = new FormData();
        let data = JSON.stringify({});
        if (dataChanged)
            data = JSON.stringify(payload);
        form.append('user', data);
        let avatarData = JSON.stringify({});
        if (avatarChanged === true) {
            avatarData = payload.avatarFile;
        }
        form.append('file', avatarData);
        makeFormApiCall('PUT', '/users/updateUser', form).then(response => {
            dispatch({
                type: CacheActionType.INVALIDATE_KEY,
                key: UserActionType.GET_CURRENT_USER
            })
            callback(response);
        }).catch(reason => {
            errorHandler(reason)
            callback(false)
        });
    }
}


function getGenres(callback) {
    return (dispatch, getState) => {
        if (getState().user.cache.has(UserActionType.GET_GENRES)) {
            callback(getState().user.cache.get(UserActionType.GET_GENRES));
        } else {
            makeJsonApiCall('GET', '/genres').then(genres => {
                dispatch({
                    type: UserActionType.GET_GENRES,
                    key: UserActionType.GET_GENRES,
                    data: genres
                });
                callback(genres);
            });
        }
    }
}

function deleteAccount() {
    return () => {
        makeJsonApiCall('DELETE', '/users/').then(() => {
        });
    }
}

function changePassword(data, callback) {
    return () => {
        makeJsonApiCall('POST', '/users/changePassword', JSON.stringify({ user: data })).then(() => {
            callback(true);
        }).catch(reason => errorHandler(reason));
    }
}

function getStatsFromDays(days, callback) {
    return () => {
        makeJsonApiCall('GET', '/stats/getStatsFromDays/' + days).then(response => {
            callback(response);
        });
    }
}

function getLatestPayments(callback) {
    return (dispatch, getState) => {
        if (getState().user.cache.has(UserActionType.GET_LATEST_PAYMENTS)) {
            callback(getState().user.cache.get(UserActionType.GET_LATEST_PAYMENTS))
        } else {
            makeJsonApiCall('GET', '/payments/latest').then(stats => {
                dispatch({
                    type: UserActionType.GET_LATEST_PAYMENTS,
                    key: UserActionType.GET_LATEST_PAYMENTS,
                    data: stats
                });
                callback(stats);
            });
        }
    }
}

function getStatsDailyByHash(hash, callback) {
    return (dispatch, getState) => {
        if (getState().user.cache.has(UserActionType.GET_STATS_DAILY_BY_HASH)) {
            callback(getState().user.cache.get(UserActionType.GET_STATS_DAILY_BY_HASH));
        } else {
            makeJsonApiCall('GET', '/statDailyHashes/getStatDailyByHash/' + hash).then(response => {
                dispatch({
                    type: UserActionType.GET_STATS_DAILY_BY_HASH,
                    key: UserActionType.GET_STATS_DAILY_BY_HASH,
                    data: response
                });
                callback(response);
            })
        }
    }
}

function logoutUser(userId, dispatch) {
    let data = { user: { user_id: userId, device: 'web_device' } };
    makeJsonApiCall('POST', '/users/logout', JSON.stringify(data)).then(response => {
    });
    dispatch({
        type: CacheActionType.INVALIDATE_ALL
    });
}

function forgottenPassword(email, callback) {
    return () => {
        makeJsonApiCall('POST', '/users/forgottenPassword', JSON.stringify({ user: { email: email } })).then(response => {
            callback(response);
        }).catch(reason => errorHandler(reason));
    }
}

function resetPassword(recoveryCode, newPassword, callback) {
    return () => {
        let data = { user: { recovery_code: recoveryCode, new_password: encodeURIComponent(newPassword) } };
        makeJsonApiCall('POST', '/users/newPassword', JSON.stringify(data)).then(response => {
            callback()
        });
    }
}

function activateAccount(activationCode, callback) {
    return () => {
        makeJsonApiCall('POST', '/users/activateUser', JSON.stringify({ activationCode: activationCode })).then(response => {
            callback(response.role_code);
        });
    }
}

function getUsersWaitingForApproval(artistIdToCheck, callback) {
    return () => {
        makeJsonApiCall('GET', '/artistUsers/' + artistIdToCheck + '/getUsersWaitingForApproval').then(response => {
            callback(response);
        });
    }
}

function approveOrDeclineUserToArtist(artistId, userId, decision, callback) {
    return () => {
        makeJsonApiCall('PUT', '/artistUsers/' + artistId + '/' + userId + '/approveOrDecline?approve=' + decision).then(response => {
            //ignore
        });
    }
}

function getArtistsWaitingForApproval(callback) {
    return () => {
        makeJsonApiCall('GET', '/artists/waitingForApproval').then(response => {
            callback(response);
        });
    }
}

function approveArtist(id, decision, callback) {
    return () => {
        makeJsonApiCall('PUT', '/artists/' + id + '/approveOrDecline?approve=' + decision).then(response => {
            callback(response);
        })
    }
}

function postOnePaymentIsDone(amount, hash, callback) {
    return (dispatch) => {
        let title = "One payment for " + howManyMonths(amount) + " months - " + amount + " OPT.";
        let dataToSend = { payment: { amount: amount, tx_hash: hash, title: title, sub_months: howManyMonths(amount) } };
        makeJsonApiCall('POST', '/payments/addOnePaymentForSub', JSON.stringify(dataToSend)).then(response => {
            //ignore response
            localStorage.setItem('isSubActive', true)
            setTimeout(() => { dispatch(getCurrentUser((user => { }), false)) }, 2000)
        });
    }
}

function postAutoPaymentIsDone(amount, hash, wallet, callback) {
    return (dispatch) => {
        let data = { user: { wallet: wallet, allowed_amount: amount, allowed_tx_hash: hash, allowed_sub_months: howManyMonths(amount) } };
        makeJsonApiCall('PUT', '/users/addAllowanceForSub', JSON.stringify(data)).then(response => {
            localStorage.setItem('isSubActive', true)
            callback(response)
            setTimeout(() => { dispatch(getCurrentUser((user => { }), false)) }, 2000)
        })
    }
}

function sendMessageToNewsletter(data, callback) {
    return () => {
        makeJsonApiCall('POST', '/users/sendNewsletter', JSON.stringify({ email: data })).then(() => {
            callback(true);
        }).catch(reason => errorHandler(reason));
    }
}

function getListeningsStats(callback) {
    return () => {
        makeJsonApiCall('GET', '/stats/getListeningStatForAdmin').then((response) => {
            callback(response)
        })
    }
}

function getRegistrationStats(roleId, callback) {
    return () => {
        makeJsonApiCall('GET', '/users/getNumberOfRegisterUser' + (roleId != null ? '?roleId=' + roleId : '')).then((response) => {
            callback(response)
        })
    }
}

function sendEmail(data, callback) {
    return () => {
        makeJsonApiCall('POST', '/users/sendMail', JSON.stringify({ email: data })).then(response => {
            callback(response)
        }).catch(reson => callback(false))
    }
}

function howManyMonths(amount) {
    let howManyMonths = 1;
    if (amount === 20) {
        howManyMonths = 1;
    } else if (amount === 60) {
        howManyMonths = 3;
    } else if (amount === 120) {
        howManyMonths = 6;
    } else if (amount === 240) {
        howManyMonths = 12;
    }
    return howManyMonths;
}

function getQuotation() {
    return dispatch => {
        makeJsonApiCall('GET', '/quotations')
            .then(response => {
                dispatch({
                    type: UserActionType.GET_QUOTATION,
                    data: response
                })
            })
    }
}
