import React from 'react';
import {
    Link, Redirect, withRouter
} from 'react-router-dom'
import {ARTIST, FAN, LoginActions} from "../../../redux/actions/LoginActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import LoaderComponent from "../../components/LoaderComponent";
import {UserActions} from "../../../redux/api/user/UserApi";

class ArtistLogin extends React.Component {

    state = {
        logData: {
            login: null,
            password: null,
            'os': "empty",
            'ip': "empty",
            'device': "empty"
        },
        shouldRedirect: false,
        loading: false,
        errorLoginMessage: '',
        isError: false,
    };

    handleActionLogin(e, log) {
        e.preventDefault()
        this.setState({loading: true});

        if (log == null) {
            let OSName = "Unknown OS";
            if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
            if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
            if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
            if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";
            let newData = {...this.state.logData};
            newData['os'] = OSName;
            this.setState({logData: newData});

            this.props.logUser(this.state.logData, (response) => {
                if (response.status === -1) {
                    this.setState({
                        loading: false,
                        errorLoginMessage: response.message,
                        isError: true
                    });
                } else {
                    this.props.onLoginDispatch({role: response.role, id: response.id});
                    if (response.role === FAN || response.role === ARTIST) {
                        // this.setState({shouldRedirect: true});
                        this.props.history.push('/')
                    }
                    this.setState({loading: false});
                }
            });
        }
    }

    handleChangeLogin(e) {
        let newData = this.state.logData;
        newData[e.target.name] = e.target.value;
        this.setState({logData: newData});
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleActionLogin(e);
        }
    }

    render() {
        return (
            this.state.loading ?
                <LoaderComponent withBg={true}/> :
                <div className="panel-login">
                    <div className="content-login login-artist d-flex">
                        <div className="container d-flex" style={{flex: 1}}>
                            <div className="row" style={{flex: 1}}>
                                <div className="col-12">
                                    <nav className="navbar m-0">
                                        <ul className="nav">
                                            {/* <li className="nav-item ml-0">
                                                <a href="https://opusfoundation.medium.com//opus-beta-tests-have-started-3840af9ec5a2"
                                                   target="_blank" className="nav-link pl-0"><b>We are on
                                                    Testnet</b> READ MORE</a>
                                            </li> */}
                                        </ul>
                                        <ul className="nav ml-auto social">
                                            <li className="nav-item">
                                                <a href="https://www.facebook.com/opusfoundation/" target="__blank"
                                                   className="nav-link"><i className="icon-facebook"/></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://twitter.com/opusfoundation" target="__blank"
                                                   className="nav-link"><i className="icon-twitter"/></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://www.youtube.com/channel/UCo5UJmWcifx6UfsAyHNzQFQ"
                                                   target="__blank" className="nav-link">
                                                    <i className="icon-youtube-play"/></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://t.me/opusfoundation" target="__blank"
                                                   className="nav-link"><i className="icon-telegram"/></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://www.reddit.com/r/opusfoundation/" target="__blank"
                                                   className="nav-link"><i className="icon-reddit-alien"/></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://opusfoundation.medium.com/" target="__blank"
                                                   className="nav-link"><i className="icon-medium"/></a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <nav className="navbar navbar-expand-xl my-0">
                                        <div className="logo-dark"/>
                                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                                data-target="#navbarSupportedContent"
                                                aria-controls="navbarSupportedContent" aria-expanded="false"
                                                aria-label="Toggle navigation">
                                            <span className="icon-menu"/>
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul className="navbar-nav ml-auto whiteText">
                                                <li className="nav-item">
                                                    <a className="nav-link" href="https://opus.audio/whitepaper.pdf"
                                                       target="_blank">Whitepaper</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="https://opus.audio/artists" className="nav-link">For
                                                        Artists</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="https://opusfoundation.medium.com/"
                                                       className="nav-link">Blog</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="https://opus.audio/jobs"
                                                       className="nav-link">Careers</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="https://opus.audio/faq" className="nav-link">Help</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className="col-lg-7 d-flex align-items-center">
                                    <h1>Login to OPUS<br/>for Artists</h1>
                                </div>
                                <div className="col-lg-5 d-flex align-items-center">
                                    <div className="panel w-100">
                                        {this.state.loading &&
                                        <LoaderComponent withBg={true}/>
                                        }
                                        <form action="#">
                                            <div className="form-group">
                                                <input name="login" onKeyPress={this.handleKeyPress}
                                                       onChange={(e) => {
                                                           this.handleChangeLogin(e)
                                                       }} type="text" className="form-control"
                                                       placeholder="E-mail address"/>
                                            </div>

                                            <div className="form-group withError">
                                                <input name="password" onKeyPress={this.handleKeyPress}
                                                       onChange={(e) => {
                                                           this.handleChangeLogin(e)
                                                       }} type="password" className="form-control"
                                                       placeholder="Password"/>
                                                {this.state.isError ?
                                                    <div
                                                        className="invalid-feedback">{this.state.errorLoginMessage}</div>
                                                    : <div className="invalid-feedback"/>}
                                            </div>

                                            <div
                                                className="form-group d-flex justify-content-between align-items-center mb-0">
                                                <span><Link to="/artist-forgotten-password" className="hightlight"> Forgot your password?</Link> </span>
                                                <Link to="/" onClick={(e) => {
                                                    this.handleActionLogin(e)
                                                }} className="btn">Login</Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <ul className="inline">
                                        <li><a href="https://opus.audio/TermsOfUse.pdf" target="_blank">Terms of
                                            Use</a></li>
                                        <li><a href="https://opus.audio/TermsArtists.pdf" target="_blank">Terms of Use for Artists</a></li>
                                        <li><a href="https://opus.audio/Privacy_Policy.pdf" target="_blank">Privacy
                                            Policy</a></li>

                                        <li><a href="https://player.opus.audio/login">Are you a fan?</a></li>
                                    </ul>

                                    <ul className="inline align-items-center">
                                        <li><Link to="/register">Don't have an account?</Link></li>
                                        <li><Link to="/register" className="btn btn-outline">Sign Up</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        currentUser: state.loginReducer.loggedAs,
        logData: state.loginReducer.logData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoginDispatch: bindActionCreators(LoginActions.login, dispatch),
        logUser: bindActionCreators(UserActions.login, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArtistLogin));