import {makeFormApiCall, makeJsonApiCall} from "../ApiCall";
import {CacheActionType} from "../user/UserApi";

class AlbumApi {}

export const AlbumActionType = {
    GET_ALBUM_BY_ID: 'GET_ALBUM_BY_ID',
    GET_ALBUMS_OF_ARTIST : 'GET_ALBUMS_OF_ARTIST',
    GET_LIBRARY_ALBUMS : 'GET_LIBRARY_ALBUMS',
    INVALIDATE_CACHE: 'INVALIDATE_CACHE',
    GET_RECOMMENDED_ALBUMS : 'GET_RECOMMENDED_ALBUMS',
    GET_ALL_ALBUMS_COUNT : 'GET_ALL_ALBUMS_COUNT'
};

export const AlbumActions = {
    getAlbum,
    getAlbumOfArtist,
    getLibraryAlbums,
    getRecommendedAlbums,
    removeFromLibrary,
    addToLibrary,
    deleteAlbum,
    updateAlbumInfo,
    getAllAlbumsCount
};

function getAlbum(id, callback) {
    return (dispatch, getState) => {
        if(getState().album.albumCache.has(AlbumActionType.GET_ALBUM_BY_ID+id)){
            callback(getState().album.albumCache.get(AlbumActionType.GET_ALBUM_BY_ID+id));
        } else {
            makeJsonApiCall('GET', '/albums/'+id).then(album => {
                dispatch({
                    type : AlbumActionType.GET_ALBUM_BY_ID,
                    key : (AlbumActionType.GET_ALBUM_BY_ID+id),
                    data : album
                });
                callback(album);
            })
        }
    }
}

function getAlbumOfArtist(artistId, callback) {
    return (dispatch, getState) => {
        if(getState().album.albumListCache.has(AlbumActionType.GET_ALBUMS_OF_ARTIST+artistId)){
            callback(getState().album.albumListCache.get(AlbumActionType.GET_ALBUMS_OF_ARTIST+artistId));
        }else{
            makeJsonApiCall('GET', '/albums/getAlbumsByArtistId?artistId=' + artistId).then(albums => {
                dispatch({
                    type : AlbumActionType.GET_ALBUMS_OF_ARTIST,
                    key: (AlbumActionType.GET_ALBUMS_OF_ARTIST+artistId),
                    data : albums
                });
                callback(albums);
            })
        }
    }
}

function getLibraryAlbums(callback){
    return (dispatch, getState) => {
        if(getState().album.albumListCache.has(AlbumActionType.GET_LIBRARY_ALBUMS)){
            callback(getState().album.albumListCache.get(AlbumActionType.GET_LIBRARY_ALBUMS))
        }else{
            makeJsonApiCall('GET', '/userAlbums/getLibraryAlbums').then(albums =>{
                dispatch({
                    type : AlbumActionType.GET_LIBRARY_ALBUMS,
                    key: AlbumActionType.GET_LIBRARY_ALBUMS,
                    data: albums
                });
                callback(albums)
            })
        }
    }
}

function getRecommendedAlbums(callback) {
    return (dispatch, getState) => {
        if(getState().album.albumListCache.has(AlbumActionType.GET_RECOMMENDED_ALBUMS)){
            callback(getState().album.albumListCache.get(AlbumActionType.GET_RECOMMENDED_ALBUMS));
        } else {
            makeJsonApiCall('GET', '/albums/recommendedAlbums').then(albums => {
                dispatch({
                    type : AlbumActionType.GET_RECOMMENDED_ALBUMS,
                    key : AlbumActionType.GET_RECOMMENDED_ALBUMS,
                    data : albums
                });
                callback(albums);
            });
        }
    }
}

function removeFromLibrary(albumId, dispatch) {
    makeJsonApiCall('DELETE', '/userAlbums/album/' + albumId).then(response => {
        dispatch({
            type : CacheActionType.INVALIDATE_KEY,
            key : AlbumActionType.GET_LIBRARY_ALBUMS
        })
    });
}

function addToLibrary(albumId, dispatch) {
    makeJsonApiCall('POST', '/userAlbums/album/' + albumId).then(response => {
        dispatch({
            type : CacheActionType.INVALIDATE_KEY,
            key : AlbumActionType.GET_LIBRARY_ALBUMS
        })
    });
}

function deleteAlbum(albumId, artistId, callback) {
    return (dispatch) => {
        makeJsonApiCall('DELETE', '/albums/'+albumId).then(response => {
            dispatch({
                type : CacheActionType.INVALIDATE_KEY,
                key : AlbumActionType.GET_ALBUMS_OF_ARTIST+artistId
            });
            callback(response);
        })
    }
}

function updateAlbumInfo(payload, callback){
    return (dispatch) => {
        let data = new FormData();

        if(payload.album !== undefined)
            data.append('media', JSON.stringify(payload.album));

        if(payload.cover !== undefined)
            data.append('avatar_file', payload.cover);

        makeFormApiCall('PUT', '/albums/updateAlbumInfo/', data).then(response => {
            dispatch({
                type : CacheActionType.INVALIDATE_KEY,
                key : AlbumActionType.GET_ALBUM_BY_ID+payload.album.id
            });
            dispatch({
                type: CacheActionType.INVALIDATE_KEY,
                key: AlbumActionType.GET_ALBUMS_OF_ARTIST
            });
            callback(response)
        })
    }
}

function getAllAlbumsCount(callback) {
    return () => {
        makeJsonApiCall('GET', '/albums/getNumberOfAllAlbums').then(respone => {
            callback(respone)
        })
    }
}