import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import ScrollComponent from "../components/ScrollComponent";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {UserActions} from "../../redux/api/user/UserApi";
import LoaderComponent from "../components/LoaderComponent";

class MailForm extends React.Component {

    state = {
        subject:'',
        to: null,
        sendToOptions : [
            {name: 'Newsletter', value: 'NEWSLETTER' },
            {name: 'Artists', value: 'ARTIST'},
            {name: 'Fans', value: 'FANS'},
            {name: 'All users', value: 'ALL'}
        ],
        label : 'Newsletter',
        loading : false
    }

    componentDidMount() {
        this.setState({isRecipientChoicesEnabled: typeof this.props.match !== 'undefined'})
    }

    sendMail() {
        let to = ''
        if(this.props.isRecipientChoicesEnabled){
            to = this.state.sendToOptions.filter(item => item.name === this.state.label)[0].value
        }else{
            to = 'id_'+this.props.match.params.id;
        }
        let message = this.state.message;
        let subject = this.state.subject;
        let mail = {to:to, message:message, subject:subject};

        this.setState({loading : true})

        this.props.sendEmail(mail, cb => {
            this.setState({
                subject : "",
                message : "",
                label: "Newsletter",
                loading : false
            })
        })
    }



    messageContentChanged(e) {
        this.setState({message: e.target.value})
    }

    subjectContentChanged(e) {
        this.setState({subject: e.target.value})
    }

    handleSelectElement(name) {
        this.setState({label: name});
    }

    render() {

        return (
            <div className="row">
                <div className="col-lg-6 mx-auto mb-5">
                    {this.state.loading ? <LoaderComponent withBg={true} /> : null }
                    <div className="panel message">
                        <h5 className="medium mb-3 w-50">Mail Form</h5>
                        <form  className="">
                            {this.props.isRecipientChoicesEnabled ?
                                <div className="pb-5">
                                    <p className="float-left">Send message: </p>
                                    <button type="button" className="btn dropdown-toggle float-right" id={this.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {this.state.label}
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby={this.id}>
                                        <ScrollComponent ref={scroll => this.scroll = scroll} maxHeight="300px">
                                            {this.state.sendToOptions.map(item => (
                                                <a className={this.state.label === item.value ? 'dropdown-item active' : 'dropdown-item'}  name='sendTo' key={item.value} onClick={(e) => {
                                                    this.handleSelectElement(item.name)}}>{item.name}</a>
                                            ))}
                                        </ScrollComponent>
                                    </div>
                                </div> : null
                            }
                            <div className="form-group">
                                <input  value={this.state.subject} onChange={this.subjectContentChanged.bind(this)} autoComplete="off" id="subject" name="subject" type="text" className="form-control" placeholder="Subject"/>
                                <label htmlFor="subject">Subject</label>
                            </div>

                            <div className="form-group">
                                <textarea value={this.state.message} onChange={this.messageContentChanged.bind(this)} name="message" placeholder="Message" id="message" cols="30" rows="5" className="form-control"/>
                                <label htmlFor="message">Message</label>
                            </div>
                        </form>
                        <div className="text-right">
                            <button onClick={this.sendMail.bind(this)} type="submit" className="btn">Send Message</button></div>
                    </div>
                </div>
            </div>

        )
    }

}

function mapStateToProps(){
    return{}
}

function mapDispatchToProps(dispatch) {
    return {
        sendEmail : bindActionCreators(UserActions.sendEmail, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MailForm);


