import React from 'react';
import ReactDOM from 'react-dom';
import './Scroll.css';

class ScrollComponent extends React.PureComponent  {

    state = {
        hideScrollX : this.props.hideScrollX !== null ? this.props.hideScrollX : false
    }

	componentDidMount() {
		this.wrapper = ReactDOM.findDOMNode(this.refs.container);
		this.scrollY = ReactDOM.findDOMNode(this.refs.scrollY);
		this.scrollX = ReactDOM.findDOMNode(this.refs.scrollX);

		this.wrapper.addEventListener('scroll', (e) => {this.handleScroll()});

        if(navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i)) {
			this.wrapper.style.marginBottom = 0
		}

		this.calculateScroll();
	}

	scrollTo(pos) {
		this.wrapper.scrollTop = pos
	}

	calculateScroll() {
		let scrollWidth = this.wrapper.scrollWidth;
		let scrollHeight = this.wrapper.scrollHeight;

		this.maxWidth = this.wrapper.offsetWidth;
		this.maxHeight = this.wrapper.offsetHeight;

		

		this.scrollY.style.height = (this.maxHeight / (scrollHeight - 17) * 100) + '%';
		if(!this.state.hideScrollX)
		    this.scrollX.style.width = (this.maxWidth / (scrollWidth - 17) * 100) + '%';

		if(parseInt(this.scrollY.style.height, 10) >= 100) {
			this.scrollY.parentElement.style.opacity = 0;
		} else {
			this.scrollY.parentElement.style.opacity = 1;
		}
		if(!this.state.hideScrollX) {
            if (parseInt(this.scrollX.style.width, 10) >= 100) {
                this.scrollX.parentElement.style.opacity = 0;
            } else {
                this.scrollX.parentElement.style.opacity = 1;
            }
        }

		if(typeof this.props.maxHeight === 'undefined' || this.props.maxHeight === null) {
			this.scrollY.parentElement.style.opacity = 0;
		}

		if(!this.state.hideScrollX) {
            if (typeof this.props.maxWidth === 'undefined' || this.props.maxWidth === null) {
                this.scrollX.parentElement.style.opacity = 0;
            }
        }
	}

	handleScroll() {
		this.calculateScroll();

		let maxScrollY = this.scrollY.parentElement.clientHeight - this.scrollY.offsetHeight;		
		let marginY = this.wrapper.scrollTop / (this.wrapper.scrollHeight - this.wrapper.clientHeight) * maxScrollY;
		this.scrollY.style.marginTop = Math.round(marginY) + "px";

		if(!this.state.hideScrollX) {
            let maxScrollX = this.scrollX.parentElement.clientWidth - this.scrollX.offsetWidth;
            let marginX = this.wrapper.scrollLeft / (this.wrapper.scrollWidth - this.wrapper.clientWidth) * maxScrollX;
            this.scrollX.style.marginLeft = Math.round(marginX) + "px";
        }
	}


	render() {
	    return (
			<div className="scrollComponent" style={{maxHeight: this.props.maxHeight, maxWidth: parseInt(this.props.maxWidth, 10)-17 + "px"}}>
				<div className="wrapper" ref="container" style={{maxHeight: this.props.maxHeight, maxWidth: this.props.maxWidth}}>
					{this.props.children}
				</div>
				<div className="scroll-y">
					<div className="scroll-y-handler" ref="scrollY" />
				</div>
                {this.state.hideScrollX ? null :
                    <div className="scroll-x">
                        <div className="scroll-x-handler" ref="scrollX" />
                    </div>
                }
			</div>
		);
	}

}

export default ScrollComponent;