import {Views} from "./ViewActions";
import {UserActions} from "../api/user/UserApi";
import history from "../../helpers/history";

export const LOG_USER = 'LOG_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const ARTIST = 'ARTIST';
export const FAN = 'FAN';
export const ADMINISTRATOR = 'ADMINISTRATOR';
export const UNDEFINED = 'UNDEFINED';

export const LoginActions = {
    login,
    logout
};


function login(credentials) {
    return dispatch => {
        let firstRunDone = localStorage.getItem(`FirstRun-${credentials.id}`);
        firstRunDone = firstRunDone && firstRunDone === 'true' ? true : false

        if (credentials.firstLogin === true && !firstRunDone) {
            setTimeout(() => { history.push('/first-run') }, 100)
        }

        dispatch({
            type: LOG_USER,
            payload: credentials
        })
    }
}

function logout(){
    return dispatch => {
        const userId = localStorage.getItem('userId');
        UserActions.logoutUser(localStorage.getItem('userId'), dispatch);
        dispatch({
            type : LOGOUT_USER
        })
        localStorage.removeItem('loggedAs');
        localStorage.removeItem('tokenAccess')
        localStorage.removeItem('userId');
        localStorage.removeItem('email');
        localStorage.removeItem('subFrom');
        localStorage.removeItem('subTo');
        localStorage.removeItem('avatar');
        localStorage.removeItem('opusTokenAddr');
        localStorage.removeItem('opusMasterContractAddr');
        localStorage.removeItem('opusMasterContractOwnerAddr');
        localStorage.removeItem('isSubscribed');
        // TODO: Change wallet storage to other section
        localStorage.setItem(`OPUSwallet${userId}`, localStorage.getItem('OPUSwallet'))
        localStorage.removeItem('OPUSwallet')
    }
};

