import Rebase from 're-base'
import firebase from 'firebase'

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
};

const API_VERSION = '1';

const app = firebase.initializeApp(config)
const base = Rebase.createClass(app.database())
const facebookProvider = new firebase.auth.FacebookAuthProvider()
const googleProvider = new firebase.auth.GoogleAuthProvider()
const twitterProvider = new firebase.auth.TwitterAuthProvider()
const nodeEnv = process.env.REACT_APP_STAGE;
const etherscanApiKey = process.env.REACT_APP_ETHERSCAN_API_KEY

export { app, base, twitterProvider, facebookProvider, googleProvider, nodeEnv, API_VERSION, etherscanApiKey }