import {LOG_USER, LOGOUT_USER, UNDEFINED} from "../actions/LoginActions";

const initialState = {
    loggedAs : localStorage.getItem('loggedAs'), //UNDEFINED
    isSubscribed: localStorage.getItem('isSubscribed'),
    id :  null,
};

const LoginReducer = (state = initialState, action) => {
    let newState = state;

    if(state.loggedAs == null){
        state.loggedAs = UNDEFINED
    }

    switch (action.type){
        case LOG_USER:
            newState = {
                loggedAs : action.payload['role'],
                id : action.payload['id']
            };
            localStorage.setItem('loggedAs', newState.loggedAs)
            break;
        case LOGOUT_USER:
            newState = {
                loggedAs : UNDEFINED
            };
            break;
        // case LOG_IN_AS_GUEST:
        //     newState = {
        //         loggedAs : GUEST
        //     };
        //     break;
        default:
            break;
    }

    return newState;
};

export default LoginReducer;
