import React from 'react';
import {
    Link
} from 'react-router-dom'
import DatePicker from 'react-datepicker';
import moment from "moment/moment";
import isEmail from 'validator/lib/isEmail';
import {toast} from "react-toastify";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {UserActions} from "../../../redux/api/user/UserApi";
import DropdownComponentCountry from '../../components/DropdownComponentCountry'
import Dropzone from 'react-dropzone'
import ReactCrop, {makeAspectCrop} from 'react-image-crop'
import { EthHelper } from '../../../EthHelper';
import { config } from '../../../config';
import cryptoBrowser from 'crypto-browserify'

class ArtistRegister extends React.Component {

    state = {
        registrationForm: {
            role_code: "ARTIST",
            firstname: null,
            lastname: null,
            login: null,
            password: null,
            confPassword: null,
            birthdayDate: moment().subtract(18, 'years'),
            idNumber: null,
            idType: null,
            checkbox: false,
            artistId: null,
            nickname: null,
        },
        registerAs: 'artist',
        step: 1,
        isChecked: false,
        fieldErrors: new Map(),
        shouldRedirectToLogin: false,

        artistForm: {
            name: '',
            wallet: '',
            avatarFile: null,
            country_id: null,
        },

        countryDisplay: 'Select Country',
        preview: null,
        cropImage: false,
        crop: {},
        countryList: [],
        registerProgress: false,

        walletForm: {
            password: '',
            password2: '',
            walletObject: null,
            step: 0,
        }
    };

    onChangeWalletForm = e => {
        const { name, value } = e.target
        this.setState({
            walletForm: {...this.state.walletForm, ...{[name]: value}}
        })
    }

    onSubmitWalletCreate = e => {
        e.preventDefault()

        const errors = new Map();
        let valid = true;
        const walletForm = this.state.walletForm
        if(walletForm.password.length === 0) {
            valid = false;
            errors.set('wallet.password', "Password is required")
        }
        else {
            if(walletForm.password.length < 9) {
                valid = false
                errors.set('wallet.password', 'Password must be at least 9 characters')
            }
            if(walletForm.password.trim() !== walletForm.password2.trim()) {
                valid = false
                errors.set('wallet.password2', 'Passwords are different')
            }
        }

        if (valid) {
            this.setState({
                walletForm: { ...walletForm, ...{ step: 1 } }
            }, () => {setTimeout(() => {
                EthHelper.newEthersWallet('', walletForm.password.trim(), (walletObj) => {
                    this.setState({
                        walletForm: {
                            ...this.state.walletForm,
                            ...{
                                walletObject: walletObj,
                                step: 2,
                            }
                        },
                        artistForm: {
                            ...this.state.artistForm,
                            ...{
                                wallet: walletObj.address,
                            }
                        }
                    })
                })
            }, 100)})
        } else {
            this.setState({
                fieldErrors: errors
            })
        }
    }

    componentDidMount() {
        this.props.getCountries((cb) => {
            this.setState({ countryList: cb })
        })
    }

    handleRegisterNewUserArtist(e) {
        let newPerson = this.state.registrationForm;

        this.validate(newPerson, (cb) => {
            this.setState({fieldErrors: cb});

            if (cb.size > 0) {
                return;
            }
            let person = {
                'user': {
                    'email': newPerson.login,
                    'password': newPerson.password,
                    'nickname': newPerson.nickname,
                    'firstname': newPerson.firstname,
                    'lastname': newPerson.lastname,
                    'role_code': newPerson.role_code,
                    'birth_date': moment(newPerson.birthdayDate).format('YYYY-MM-DD'),
                    'marketing_agreed': newPerson.checkbox,
                    'id_number': newPerson.idNumber,
                    'id_type': newPerson.idType
                }
            };
            this.props.register(person, (response) => {
                if (response.error === null || response.error === undefined) {
                    if (response.status === 0) {
                        toast.info("Activation link has been sent to your email", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        this.props.history.push('/artist-login')
                    } else if (response.status === -1) {
                        toast.error(response.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else {
                        toast.warn("Something went wrong, please try again.", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                }
            });

        })
    }

    handleDateChange(date) {
        let instanceRegistrationForm = this.state.registrationForm;
        instanceRegistrationForm.birthdayDate = date;
        this.setState({registrationForm: instanceRegistrationForm});
    }

    handleCheckboxChange() {
        let instanceRegistrationForm = this.state.registrationForm;
        let checkboxValue = instanceRegistrationForm.checkbox;
        checkboxValue = !checkboxValue;
        instanceRegistrationForm.checkbox = checkboxValue;
        this.setState({registrationForm: instanceRegistrationForm});
    }

    validate(person, cb) {
        const errors = new Map();
        if (!person.firstname) errors.set("firstname", 'First name required.');
        if (!person.lastname) errors.set('lastname', 'Last name required.');
        if (!person.login) errors.set('login', 'E-mail Required')
        else if (person.login && !isEmail(person.login)) errors.set("login", 'Invalid E-mail.');
        if (!person.password) {
            errors.set("password", 'Password required.');
        } else {
            let regexPassword = new RegExp("^(?=.*?[a-zA-Z])(?=.*?[0-9]).{6,}$");
            let testRegexPassword = regexPassword.test(person.password)
            if (!testRegexPassword) {
                errors.set("password", "Password must have at least six characters, one letter, and one number.");
            }
        }
        if (!person.confPassword) {
            errors.set("confPassword", 'Confirmation password required.');
        } else {
            if (person.confPassword !== person.password) {
                errors.set("confPassword", 'Passwords are different.');
            }
        }
        if (!person.nickname) errors.set("nickname", 'Username required.');
        if (!person.birthdayDate) errors.set("birthdayDate", 'Date of birth is required.');
        cb(errors);
    }

    handleChangeRegistration(e) {
        let changedForm = this.state.registrationForm;
        changedForm[e.target.name] = e.target.value;
        this.setState({registrationForm: changedForm});
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleRegisterNewUserArtist(e);
        }
    }

    onRadioChange = e => {
        const { name, value } = e.target
        this.setState({
            [name]: value,
        })
    }

    goToStep = (e, step) => {
        e.preventDefault();
        this.setState({ step });
    }

    onArtistInputChange = e => {
        const { name, value } = e.target
        this.setState({
            artistForm: {...this.state.artistForm, ...{[name]: value}}
        })
    }

    renderError = name => {
        return this.state.fieldErrors.has(name) ?
        <div key={this.state.fieldErrors.get(name)} className="invalid-feedback">
            {this.state.fieldErrors.get(name)}
        </div> : null
    }

    onDropFile = files => {
        if(files.length > 0) {
            this.setState({
                preview: files[0].preview,
                cropImage: true,
            })
        }
    }

    handleImgFileChange = event => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    preview: e.target.result,
                    cropImage: true,
                })
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    cropOnChange = crop => {
        this.setState({ crop });
    }

    getImage = () => {
        const {crop} = this.state
        var img = new Image()
        img.src = this.state.preview

        const canvas = document.createElement('canvas')
        canvas.width = crop.width * img.width / 100
        canvas.height = crop.height * img.height / 100
        const ctx = canvas.getContext('2d')
        img.onload = () => {
            ctx.drawImage(
                img,
                crop.x * img.width / 100,
                crop.y * img.height / 100,
                crop.width * img.width / 100,
                crop.height * img.height / 100,
                0,
                0,
                crop.width * img.width / 100,
                crop.height * img.height / 100,
            )

            canvas.toBlob((blob) => {
                if(blob === null)
                    return

                blob.name = "cover"
                blob.lastModifiedDate = new Date()
                this.setState({preview: URL.createObjectURL(blob)});
                let newArtistForm = this.state.artistForm;
                newArtistForm.avatarFile = blob;
                this.setState({artistForm: newArtistForm, cropImage: false});
            }, 'image/jpeg')
        }
    }

    registerAsOnlyArtist = e => {
        e.preventDefault();

        const {name, wallet, avatarFile, country_id} = this.state.artistForm;

        const errors = new Map();
        if(!name || name.trim().length === 0) errors.set('artist.name', 'Name is required')
        if(!wallet || wallet.trim().length === 0) errors.set('artist.wallet', 'Wallet is required')
        else if(!EthHelper.isAddress(wallet)) errors.set('artist.wallet', 'Invalid wallet address')
        if(!country_id) errors.set('artist.country_id', 'Country is required')
        if(!avatarFile) errors.set('artist.avatar', 'Cover is required')

        this.setState({fieldErrors: errors})

        if(errors.size === 0) {
            this.setState({
                registerProgress: true
            }, () => {
                const formData = new FormData();
                const newPerson = this.state.registrationForm;
                const artistForm = this.state.artistForm
                const person = {
                    'user': {
                        'email': newPerson.login,
                        'password': newPerson.password,
                        'nickname': newPerson.nickname,
                        'firstname': newPerson.firstname,
                        'lastname': newPerson.lastname,
                        'role_code': newPerson.role_code,
                        'birth_date': moment(newPerson.birthdayDate).format('YYYY-MM-DD'),
                        'marketing_agreed': newPerson.checkbox,
                        'id_number': newPerson.idNumber,
                        'id_type': newPerson.idType,
                        'country_id': artistForm.country_id
                    }
                };
                formData.append('user', JSON.stringify(person))
                const artistObj = {
                    artist: {
                        name : artistForm.name,
                        wallet: artistForm.wallet,
                        country_id: artistForm.country_id
                    }
                };
                formData.append('artist', JSON.stringify(artistObj));

                // add info about wallet

                const key = cryptoBrowser.randomBytes(256 / 8);
                const iv = cryptoBrowser.randomBytes(16);
                const recoveryObj = {
                    recovery: {
                        algorithm: config.CIPHER,
                        key: key
                    }
                }

                if (this.state.walletForm.step === 2 && this.state.walletForm.walletObject !== null) {
                    formData.append('recovery', JSON.stringify(recoveryObj));
                }

                // end add info about wallet

                formData.append('file', artistForm.avatarFile)
                UserActions.registerAsArtist(formData)
                .then(response => {
                    console.log('register response', response)
                    if (response.error === null || response.error === undefined) {
                        if (response.status === 0) {
                            toast.info("Activation link has been sent to your email", {
                                position: toast.POSITION.TOP_CENTER
                            });

                            // if response data recovery download recovery wallet
                            if (response.data && response.data.recovery) {
                                EthHelper.downloadRecoveryFileAfterRegister(this.state.walletForm.walletObject.privateKey, key, iv, response.data.filename)
                            }

                            this.props.history.push('/artist-login')
                        } else if (response.status === -1) {
                            toast.error(response.message, {
                                position: toast.POSITION.TOP_CENTER
                            });
                        } else {
                            toast.warn("Something went wrong, please try again.", {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }
                    }
                    this.setState({registerProgress: false})
                })
                .catch(err => {
                    console.log(err)
                    if (err.status === -1) {
                        toast.error(err.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                    this.setState({registerProgress: false})
                })
            })
        }
    }

    render() {
        return (
            <div className="panel-login">

                <div
                    className={this.state.cropImage ? "foregroundContainer d-flex flex-column align-items-center justify-content-center" : "d-none"}>
                    <h2>Adjust image</h2>
                    {this.state.preview !== null ? <div className="col-6"><ReactCrop
                        src={this.state.preview}
                        crop={this.state.crop}
                        onChange={this.cropOnChange}
                        onImageLoaded={image => {
                            this.setState({
                                crop: makeAspectCrop({
                                    x: 0,
                                    y: 0,
                                    width: 100,
                                    aspect: 1 / 1,
                                }, image.width / image.height),
                                image: {
                                    width: image.width,
                                    height: image.height
                                }
                            });
                            if (image.width === image.height && this.state.cropImage) {
                                this.getImage();
                            }
                        }}
                    /></div> : null}
                    <button className="button mt-5" onClick={e => {
                        this.getImage()
                    }}>Apply image
                        </button>
                </div>

                <div className="content-login login-artist d-flex">
                    <div className="container d-flex" style={{flex: 1}}>
                        <div className="row" style={{flex: 1}}>
                            <div className="col-12">
                                <nav className="navbar m-0">
                                    <ul className="nav">
                                        {/* <li className="nav-item ml-0">
                                            <a href="https://opusfoundation.medium.com//opus-beta-tests-have-started-3840af9ec5a2"
                                               target="_blank" className="nav-link pl-0"><b>We are on
                                                Testnet</b> READ MORE</a>
                                        </li> */}
                                    </ul>
                                    <ul className="nav ml-auto social">
                                        <li className="nav-item">
                                            <a href="https://www.facebook.com/opusfoundation/" target="__blank"
                                               className="nav-link"><i className="icon-facebook"/></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://twitter.com/opusfoundation" target="__blank"
                                               className="nav-link"><i className="icon-twitter"/></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.youtube.com/channel/UCo5UJmWcifx6UfsAyHNzQFQ"
                                               target="__blank" className="nav-link">
                                                <i className="icon-youtube-play"/></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://t.me/opusfoundation" target="__blank"
                                               className="nav-link"><i className="icon-telegram"/></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.reddit.com/r/opusfoundation/" target="__blank"
                                               className="nav-link"><i className="icon-reddit-alien"/></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://opusfoundation.medium.com/" target="__blank"
                                               className="nav-link"><i className="icon-medium"/></a>
                                        </li>
                                    </ul>
                                </nav>
                                <nav className="navbar navbar-expand-xl my-0">
                                    <div className="logo-dark"/>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                                            data-target="#navbarSupportedContent"
                                            aria-controls="navbarSupportedContent" aria-expanded="false"
                                            aria-label="Toggle navigation">
                                        <span className="icon-menu"/>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav ml-auto whiteText">
                                            <li className="nav-item">
                                                <a className="nav-link" href="https://opus.audio/whitepaper.pdf"
                                                   target="_blank">Whitepaper</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://opus.audio/artists" className="nav-link">For
                                                    Artists</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://opusfoundation.medium.com/"
                                                   className="nav-link">Blog</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://opus.audio/jobs" className="nav-link">Careers</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="https://opus.audio/faq" className="nav-link">Help</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <div className="col-lg-7 d-flex align-items-center" style={{maxHeight: 'calc(100vh - 160px)', minHeight: '332px'}}>
                                <div className="">
                                    <h1>Register to OPUS</h1>
                                    <p className="text-white">This two step registration process allow you to create business artist entity at OPUS.</p>
                                    <p className="text-white">In step 1 you will create your personal profile as an artist or a band member representative. </p>
                                    <p className="text-white">In step 2 you will create your artist or band profile.</p>
                                    <p className="text-white">You will have to confirm your e-mail address and wait for artist entity verification. You will be informed about verification result by e-mail.</p>
                                </div>
                            </div>
                            <div className="col-lg-5 d-flex align-items-center">
                                <div className="panel w-100 mb-2">
                                    <form action="#">
                                        {(() => {
                                            switch(this.state.step) {
                                                case 2:
                                                    return <div>
                                                        <h5 className="text-center mb-0" style={{color: '#9796b1'}}>Artist Account Creation</h5>

                                                        <div className="stepline mb-5"><span>Step 2 ( your artist or band profile)</span></div>

                                                        <div className="form-group withError">
                                                            <input name="name"
                                                                onChange={this.onArtistInputChange} required type="text" value={this.state.artistForm.name} className="form-control"
                                                                placeholder="Name of Artist / Band" />
                                                            {this.renderError('artist.name')}
                                                        </div>

                                                        <div className="mb-3">
                                                            <p className="text-muted text-justify">OPUS does not manage nor maintain your Ethereum blockchain based cryptocurrency wallet. Wallet creation functionality here is for your convenience only and OPUS never knows its sensitive data like private key or password. If you prefer you can create a wallet somewhere else, for example on <a href="https://www.myetherwallet.com/create-wallet" target="_blank" rel="noopener noreferrer">https://www.myetherwallet.com/create-wallet</a> website or with use a hardware wallet like Trezor or Ledger.</p>
                                                        </div>

                                                        <div className="text-center mb-3">
                                                            <button type="button" class="btn" data-toggle="modal" data-target="#createWallet">
                                                                {this.state.walletForm.step === 2 ? 'Wallet informations' : 'Generate wallet'}
                                                            </button>
                                                        </div>

                                                        <div className="mb-3">
                                                            <p className="text-mutted text-center">or enter your existing wallet address below:</p>
                                                        </div>

                                                        <div className="form-group withError">
                                                            <input name="wallet"
                                                                onChange={this.onArtistInputChange} required type="text" value={this.state.artistForm.wallet} className="form-control"
                                                                placeholder="Your existing Ethereum wallet address (if any)" />
                                                            <div className="help-tip">
                                                                <p>Revenue that you gain as a band will be transfered to this wallet.</p>
                                                            </div>
                                                            {this.renderError('artist.wallet')}
                                                        </div>

                                                        <div className="form-group mb-2 withError">
                                                            <DropdownComponentCountry
                                                                countryDisplay={this.state.countryDisplay}
                                                                setCountryId={cid => this.onArtistInputChange({target: {name: 'country_id', value: cid}})}
                                                                changeDisplay={name => this.setState({countryDisplay: name})}
                                                                listToDisplay={this.state.countryList} />
                                                            {this.renderError('artist.country_id')}
                                                        </div>

                                                        <Dropzone preview onDrop={this.onDropFile} accept="image/*" multiple={false} className="cover-album">
                                                            <div className="box border-0 mb-0 mx-auto" style={{backgroundColor: '#f6f7fa'}}>
                                                                <img src={this.state.preview} />
                                                                <a>{this.state.preview !== null ? null :
                                                                    <i className="icon-camera" />
                                                                }</a>
                                                            </div>
                                                        </Dropzone>
                                                        <div className="form-group withError">{this.renderError('artist.avatar')}</div>
                                                        <div className="my-2 text-center">
                                                            <input name="avatarFile" id="avatarFile" onChange={this.handleImgFileChange}
                                                                accept="image/*" type="file" className="form-control d-none w-100"
                                                                placeholder="Avatar" />
                                                            <label htmlFor="avatarFile" className="btn text-white">Choose artist cover</label>
                                                        </div>

                                                        {this.state.walletForm.step === 2 ? <div className="mb-3"><small>After Register should be started downloading wallet recovery file required to restore your privateKey when you lost access to your wallet.</small></div> : null}


                                                        <div className="mb-3">
                                                            <p className="text-muted text-justify">By clicking Register, you agree to our <a href="https://opus.audio/TermsOfUse.pdf" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="https://opus.audio/TermsArtists.pdf" target="_blank" rel="noopener noreferrer">Terms of Use for Artists</a>. Learn how we collect, use and share your data in our <a href="https://opus.audio/Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                                                        </div>

                                                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                            <button className="btn mx-3 mb-2 mb-xl-0" onClick={e => this.goToStep(e, 1)} disabled={this.state.registerProgress}>Back</button>
                                                            <button className="btn mx-3 mb-2 mb-xl-0" onClick={this.registerAsOnlyArtist} disabled={this.state.registerProgress}>Register</button>
                                                        </div>
                                                    </div>
                                                default:
                                                    return <div>
                                                        <h5 className="text-center mb-0" style={{color: '#9796b1'}}>Artist Account Creation</h5>

                                                        <div className="stepline mb-5"><span>Step 1 (your personal data)</span></div>

                                                        <div className="form-group withError">
                                                            <input name="firstname" onKeyPress={this.handleKeyPress}
                                                                value={this.state.registrationForm.firstname}
                                                                onChange={(e) => {
                                                                    this.handleChangeRegistration(e)
                                                                }} required type="text" className="form-control"
                                                                placeholder="First name" />
                                                            {this.state.fieldErrors.has('firstname') ?
                                                                <div key={this.state.fieldErrors.get('firstname')}
                                                                    className="invalid-feedback">
                                                                    {this.state.fieldErrors.get('firstname')}
                                                                </div> : null
                                                            }
                                                        </div>
                                                        <div className="form-group withError">
                                                            <input name="lastname" onKeyPress={this.handleKeyPress}
                                                                value={this.state.registrationForm.lastname}
                                                                onChange={(e) => {
                                                                    this.handleChangeRegistration(e)
                                                                }} required type="text" className="form-control"
                                                                placeholder="Last name" />
                                                            {this.state.fieldErrors.has('lastname') ?
                                                                <div key={this.state.fieldErrors.get('lastname')}
                                                                    className="invalid-feedback">
                                                                    {this.state.fieldErrors.get('lastname')}
                                                                </div> : null
                                                            }
                                                        </div>

                                                        <div className="form-group withError">
                                                            <input name="login" value={this.state.registrationForm.login} onKeyPress={this.handleKeyPress} onChange={(e) => {
                                                                this.handleChangeRegistration(e)
                                                            }} type="text" className="form-control" placeholder="E-mail address" />
                                                            {this.state.fieldErrors.has('login') ?
                                                                <div key={this.state.fieldErrors.get('login')}
                                                                    className="invalid-feedback">
                                                                    {this.state.fieldErrors.get('login')}
                                                                </div> : null
                                                            }
                                                        </div>
                                                        <div className="form-group withError">
                                                            <input name="nickname" value={this.state.registrationForm.nickname} onKeyPress={this.handleKeyPress}
                                                                onChange={(e) => {
                                                                    this.handleChangeRegistration(e)
                                                                }} type="text" className="form-control" placeholder="Username" />
                                                            {this.state.fieldErrors.has('nickname') ?
                                                                <div key={this.state.fieldErrors.get('nickname')}
                                                                    className="invalid-feedback">
                                                                    {this.state.fieldErrors.get('nickname')}
                                                                </div> : null
                                                            }
                                                        </div>
                                                        <div className="form-group withError">
                                                            <input name="password" value={this.state.registrationForm.password} onKeyPress={this.handleKeyPress}
                                                                onChange={(e) => {
                                                                    this.handleChangeRegistration(e)
                                                                }} type="password" className="form-control"
                                                                placeholder="Password" />
                                                            {this.state.fieldErrors.has('password') ?
                                                                <div key={this.state.fieldErrors.get('password')}
                                                                    className="invalid-feedback">
                                                                    {this.state.fieldErrors.get('password')}
                                                                </div> : null
                                                            }
                                                        </div>
                                                        <div className="form-group withError">
                                                            <input name="confPassword" value={this.state.registrationForm.confPassword} onKeyPress={this.handleKeyPress}
                                                                onChange={(e) => {
                                                                    this.handleChangeRegistration(e)
                                                                }} type="password" className="form-control"
                                                                placeholder="Confirm Password" />
                                                            {this.state.fieldErrors.has('confPassword') ?
                                                                <div key={this.state.fieldErrors.get('confPassword')}
                                                                    className="invalid-feedback">
                                                                    {this.state.fieldErrors.get('confPassword')}
                                                                </div> : null
                                                            }
                                                        </div>
                                                        <div className="form-group withError row">
                                                            <label htmlFor="date" className="col-4 col-form-label">Date of birth</label>
                                                            <DatePicker name="datepicker" className="form-control"
                                                                selected={this.state.registrationForm.birthdayDate}
                                                                onChange={this.handleDateChange.bind(this)}
                                                                showYearDropdown
                                                                dateFormatCalendar="MMMM"
                                                                scrollableYearDropdown
                                                                yearDropdownItemNumber={100}
                                                                maxDate={moment()} />
                                                            {this.state.fieldErrors.has('birthdayDate') ?
                                                                <div key={this.state.fieldErrors.get('birthdayDate')}
                                                                    className="invalid-feedback">
                                                                    {this.state.fieldErrors.get('birthdayDate')}
                                                                </div> : null
                                                            }
                                                        </div>

                                                        {/* <p>Select Account Type:</p>

                                                        <div className="d-flex flex-wrap">
                                                            <div className="form-group mb-1 mr-1">
                                                                <input className="d-none" type="radio" name="registerAs" id="asArtist" onChange={this.onRadioChange} value="artist" checked={this.state.registerAs === 'artist'} />
                                                                <label className="form-check-label form-radio-label" for="asArtist">
                                                                    Register as an artist
                                                                </label>
                                                            </div>
                                                            <div className="form-group mb-1">
                                                                <input className="d-none" type="radio" name="registerAs" id="asManager" onChange={this.onRadioChange} value="manager" checked={this.state.registerAs === 'manager'} />
                                                                <label className="form-check-label form-radio-label" for="asManager">
                                                                    Register as a manager
                                                                </label>
                                                            </div>
                                                        </div> */}

                                                        <div className="form-group">
                                                            <input onKeyPress={this.handleKeyPress} name='checkbox' type="checkbox"
                                                                onChange={this.handleCheckboxChange.bind(this)}
                                                                className="d-none" id="checkbox1" />
                                                            <label className="form-check-label" htmlFor="checkbox1" style={{whiteSpace: 'normal'}}>
                                                                Share my registration data with Opus's content providers for
                                                                marketing purposes.
                                                            </label>
                                                        </div>
                                                        <div className="form-group mt-5 mb-0">
                                                            {this.state.registerAs === 'manager' ?
                                                            <a onClick={(e) => {
                                                                this.handleRegisterNewUserArtist(e)
                                                            }}
                                                                className="btn mx-auto">Register</a>
                                                            : <button className="btn mx-auto" type="button" onClick={e => {
                                                                e.preventDefault()
                                                                this.validate(this.state.registrationForm, errors => {
                                                                    console.log('errors', errors)
                                                                    this.setState({fieldErrors: errors});
                                                                    if (errors.size === 0) {
                                                                        this.goToStep(e, 2)
                                                                    }
                                                                })
                                                            }}>Next step</button>}
                                                        </div>
                                                    </div>
                                            }
                                        })()}
                                    </form>
                                </div>
                            </div>
                            <div className="col-12 d-flex align-items-center justify-content-between">
                                <ul className="inline">
                                    <li><a href="https://opus.audio/TermsOfUse.pdf" target="_blank">Terms of Use</a>
                                    </li>
                                    <li><a href="https://opus.audio/TermsArtists.pdf" target="_blank">Terms of Use for Artists</a>
                                    </li>
                                    <li><a href="https://opus.audio/Privacy_Policy.pdf" target="_blank">Privacy Policy</a>
                                    </li>
                                    <li><a href="https://player.opus.audio/login">Are you a fan?</a></li>
                                </ul>
                                <ul className="inline align-items-center">
                                    <li><Link to="/login">Already registered?</Link></li>
                                    <li><Link to="/login" className="btn btn-outline">Sign In</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="createWallet" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body panel">
                                {(() => {
                                    switch(this.state.walletForm.step) {
                                        case 2:
                                            return <div>
                                                <p><strong>Addres:</strong><br /><span className="text-wrap">{this.state.walletForm.walletObject.address}</span></p>
                                                <p><strong>Private key:</strong><br /><span className="text-wrap">{this.state.walletForm.walletObject.privateKey}</span></p>
                                                <div className="mb-3"><small>Copy and save in secure place your <strong>private key</strong> and download <strong>keystore file</strong> to have access to your wallet in the future.</small></div>

                                                <div className="text-center mb-2">
                                                    <button className="btn" onClick={e => {
                                                        EthHelper.downloadKs(e, this.state.walletForm.walletObject.data, this.state.walletForm.walletObject.address)
                                                    }}>Download keystore file</button>
                                                </div>
                                                <div className="text-center">
                                                    <button type="button" class="btn" data-dismiss="modal">Close</button>
                                                </div>
                                            </div>
                                        case 1:
                                            return <div className="text-center">
                                                <h5>Generating</h5>
                                                <p>Please wait...</p>
                                            </div>
                                        default:
                                            return (<form onSubmit={this.onSubmitWalletCreate}>
                                                <div className="form-group withError">
                                                    <input name="password"
                                                        onChange={this.onChangeWalletForm} type="password" value={this.state.walletForm.password} className="form-control"
                                                        placeholder="Wallet password" />
                                                    <div className="help-tip">
                                                        <p>Please Enter At Least 9 Characters</p>
                                                    </div>
                                                    {this.renderError('wallet.password')}
                                                </div>
                                                <div className="form-group withError">
                                                    <input name="password2"
                                                        disabled={this.state.walletForm.password.length === 0}
                                                        onChange={this.onChangeWalletForm} type="password" value={this.state.walletForm.password2} className="form-control"
                                                        placeholder="Confirm Wallet Password" />
                                                    {this.renderError('wallet.password2')}
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" className="btn">Generate Wallet</button>
                                                </div>
                                            </form>)
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        register: bindActionCreators(UserActions.register, dispatch),
        getCountries: bindActionCreators(UserActions.getCountries, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistRegister);