import React, {Component, Suspense} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom'
import {connect} from 'react-redux';
import {FAN} from "./redux/actions/LoginActions";
import LoginPage from "./scenes/login/LoginPage";
// import ArtistPage from './scenes/artist/ArtistPage'
import ArtistRegister from './scenes/login/components/ArtistRegister'
// import FanPage from "./scenes/fan/FanPage";
import AccountActivation from "./scenes/other websites/AccountActivation";
import ResetPassword from "./scenes/other websites/ResetPassword";
import ForgottenPassword from "./scenes/other websites/ForgottenPassword";
import ForgottenPasswordArtist from "./scenes/other websites/ForgottenPasswordArtist";
import PrivateRoute from "./scenes/components/PrivateRoute.js";
import PrivateLoginRoute from "./scenes/components/PrivateLoginRoute.js";
import ArtistLogin from "./scenes/login/components/ArtistLogin";
import AdminLogin from './scenes/admin/AdminLogin'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {checkIfDev} from './Utilities'
import PrivateRouteAdmin from './scenes/admin/PrivateRouteAdmin'
import PrivateLoginRouteAdmin from './scenes/admin/PrivateLoginRouteAdmin'
// import AdminMain from "./scenes/admin/AdminMain";
import CookieComponent from './scenes/components/CookieComponent';
import * as Sentry from '@sentry/react'
import ErrorBoundary from './components/ErrorBoundary'
const ArtistPage = React.lazy(() => import('./scenes/artist/ArtistPage'))
const FanPage = React.lazy(() => import('./scenes/fan/FanPage'))
const AdminMain = React.lazy(() => import('./scenes/admin/AdminMain'))
class App extends Component {

    render() {
        return (
            <ErrorBoundary>
            <Suspense fallback={<div></div>}>
                <ToastContainer/>
                {checkIfDev() === true ?
                    <Switch>
                        <Route path="/activation/:activationCode" component={AccountActivation}/>
                        <Route path="/new-password/:recoveryCode" component={ResetPassword}/>
                        <Route path="/forgotten-password" component={ForgottenPassword}/>
                        <Route path="/artist-forgotten-password" component={ForgottenPasswordArtist}/>
                        <PrivateLoginRoute path="/login" currentUser={this.props.currentUser} component={LoginPage}/>
                        <Route path="/artist-login" component={ArtistLogin}/>
                        <Route path="/register" component={ArtistRegister}/>
                        <PrivateLoginRouteAdmin path="/admin/login" currentUser={this.props.currentUser} component={AdminLogin}/>
                        <PrivateRouteAdmin currentUser={this.props.currentUser} path="/admin" component={AdminMain}/>

                        <PrivateRoute path="/" currentUser={this.props.currentUser}
                                      component={this.props.currentUser === FAN ? FanPage : ArtistPage}/>
                    </Switch>
                    :
                    <Switch>
                        <Route path="/activation/:activationCode" component={AccountActivation}/>
                        <Route path="/new-password/:recoveryCode" component={ResetPassword}/>
                        <Route path="/forgotten-password" component={ForgottenPassword}/>
                        <Route path="/artist-forgotten-password" component={ForgottenPasswordArtist}/>
                        {window.location.href === "https://player.opus.audio/login" ?
                            <PrivateLoginRoute path="/login" currentUser={this.props.currentUser}
                                               component={LoginPage}/>
                            : null}
                        {window.location.href === "https://player.opus.audio/login/registration" ?
                            <PrivateLoginRoute path="/login/registration" currentUser={this.props.currentUser}
                                               component={LoginPage}/>
                            : null}
                        {window.location.href === "https://artist.opus.audio/login" ?
                            <Route path="/login" component={ArtistLogin}/>
                            : null}
                        {window.location.href === "https://artist.opus.audio/register" ?
                            <Route path="/register" component={ArtistRegister}/>
                            : null}

                        <PrivateLoginRouteAdmin path="/admin/login" currentUser={this.props.currentUser} component={AdminLogin}/>
                        <PrivateRouteAdmin currentUser={this.props.currentUser} path="/admin" component={AdminMain}/>

                        <PrivateRoute path="/" currentUser={this.props.currentUser} component={this.props.currentUser === FAN ? FanPage : ArtistPage}/>
                    </Switch>
                }
                <CookieComponent />
            </Suspense>
            </ErrorBoundary>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.loginReducer.loggedAs
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default Sentry.withProfiler(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
