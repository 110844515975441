import React, {Component} from 'react';
import {UserActions} from "../../redux/api/user/UserApi";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {toast} from "react-toastify";

class ResetPassword extends Component {

    state = {
        newPassword: '',
        confirmationPassword: '',
    }

    onNewPasswordChange(e) {
        this.setState({newPassword: e.target.value})
    }

    onConfirmPasswordChange(e) {
        this.setState({confirmationPassword: e.target.value})
    }

    confirmPasswordAndSend() {
        let error = false
        let regexPassword = new RegExp("^(?=.*?[a-zA-Z])(?=.*?[0-9]).{6,}$");
        let testRegexPassword = regexPassword.test(this.state.newPassword);
        if (!testRegexPassword) {
            toast.error("Password must be at least six characters, at least one letter and one number", {position: toast.POSITION.TOP_CENTER});
            error = true;
        }
        if (this.state.newPassword !== this.state.confirmationPassword) {
            toast.error("Passwords do not match", {position: toast.POSITION.TOP_CENTER});
            error = true;
        }
        if (!error) {
            this.props.resetPassword(this.props.match.params.recoveryCode, this.state.newPassword, ()=> {
                this.props.history.push('/')
            })
        }
    }

    render() {
        return (
            <body className="panelBody">
            <div className="container-fluid">
                <div className="create-wallet">
                    <div className="header p-5 d-flex justify-content-between align-items-center">
                        <a href="/"><img src="/img/logo-black.png" alt="OPUS"/></a>
                        <a href="/" className="closeButton"><i className="icon-close-circle"/> Close</a>
                    </div>
                    <div className="content-center text-center">
                        <h2 className="light my-3">Reset Password</h2>
                        <p className="regular mb-5">Change your password.</p>
                        <div className="d-flex flex-wrap flex-lg-nowrap">
                            <input value={this.state.newPassword} type="password" onChange={(e) => {
                                this.onNewPasswordChange(e)
                            }} className="walletPassword mr-lg-2" placeholder="New password"/>
                            <input value={this.state.confirmationPassword} type="password" onChange={(e) => {
                                this.onConfirmPasswordChange(e)
                            }} className="walletPassword ml-lg-2" placeholder="Again new password"/>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault()
                                        this.confirmPasswordAndSend(e)
                                    }} className="button mx-auto my-4">Reset</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </body>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        resetPassword: bindActionCreators(UserActions.resetPassword, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);