import React from 'react';
import ScrollComponent from './ScrollComponent';
import $ from 'jquery'
import {bindActionCreators} from "redux";
import {UserActions} from "../../redux/api/user/UserApi";
// import connect from "react-redux/es/connect/connect";
import { connect } from 'react-redux'

class DropdownComponentCountry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            label: props.label,
            countryList: []
        }

        this.handleSelectElement = this.handleSelectElement.bind(this);
    }

    componentWillMount() {
        this.props.getCountries((cb) => {
            this.setState({countryList: cb})
        })
    }

    componentDidMount() {
        this.setState({label: this.props.countryDisplay})
        this.$el = $(this.refs.container)
        this.onKeyPressed = this.onKeyPressed.bind(this)
        window.addEventListener('keypress', this.onKeyPressed)
    }

    componentWillUnmount() {
        window.removeEventListener('keypress', this.onKeyPressed)
    }

    onKeyPressed(e) {
        if (this.$el.hasClass('show')) {
            let elements = this.$el.find('.dropdown-item')
            $.each(elements, (index, item) => {
                let pattern = new RegExp("^" + e.key + ".*");
                if ($(item).text().toLowerCase().match(pattern)) {
                    this.scroll.scrollTo(index * $(item).outerHeight())
                    return false
                }
            })
        }
    }

    handleSelectElement(name) {
        this.setState(prevState => ({label: name}));
    }

    render() {
        return (
            <div className="dropdown w-100" ref={el => this.el = el}>
                <button type="button" className="btn dropdown-toggle" id={this.id} data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    {this.props.countryDisplay}
                </button>
                <div className="dropdown-menu" ref="container" aria-labelledby={this.id}>
                    <ScrollComponent ref={scroll => this.scroll = scroll} maxHeight="300px">
                        {this.state.countryList.map(item => (
                            <a className={this.state.label === item ? 'dropdown-item active' : 'dropdown-item'}
                               name='country_id' key={item.name} onClick={(e) => {
                                this.props.changeDisplay(item.name);
                                this.props.setCountryId(item.id);
                                this.handleSelectElement(item.name)
                            }}>{item.name}</a>
                        ))}
                    </ScrollComponent>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    }
}


function mapDispatchToProps(dispatch) {
    return {
        getCountries: bindActionCreators(UserActions.getCountries, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownComponentCountry);