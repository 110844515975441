import React from 'react'
import './LoaderComponent.css'

class LoaderComponent extends React.Component{

    getClass = () => {
        let c = 'overlay';
        if (this.props.withBg) {
            c += ' loaderBg';
        }
        if (this.props.fixed) {
            c += ' fixed';
        }
        return c;
    }

    render(){
        return(
            <div className={this.getClass()} >
                <div className="spinner">
                    <div className="rect1"/>
                    <div className="rect2"/>
                    <div className="rect3"/>
                    <div className="rect4"/>
                    <div className="rect5"/>
                </div>
            </div>
        )
    }
}

export default LoaderComponent