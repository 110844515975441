import {makeFormApiCall, makeJsonApiCall} from "../ApiCall";
import {MediaActionType} from "../media/MediaApi";
import {CacheActionType} from "../user/UserApi";

class PlaylistApi{}

export const PlaylistActionType = {
    GET_RECOMMENDED_PLAYLISTS : 'GET_RECOMMENDED_PLAYLISTS',
    GET_PLAYLIST_BY_ID : 'GET_PLAYLIST_BY_ID',
    GET_LIBRARY_PLAYLISTS : 'GET_LIBRARY_PLAYLISTS',
    ADD_MEDIA_TO_PLAYLIST : 'ADD_MEDIA_TO_PLAYLIST',
    REMOVE_MEDIA_FROM_PLAYLIST: 'REMOVE_MEDIA_FROM_PLAYLIST',
    GET_MY_PLAYLISTS : 'GET_MY_PLAYLISTS',
    DELETE_PLAYLIST : 'DELETE_PLAYLIST',
    ADD_TO_LIBRARY : 'ADD_TO_LIBRARY',
};

export const PlaylistActions = {
    getRecommendedPlaylists,
    getPlaylistById,
    getLibraryPlaylists,
    createPlaylist,
    addMediaToPlaylist,
    getMyPlaylists,
    addToLibrary,
    removeFromLibrary,
    deletePlaylist,
    removeMediaFromPlaylist,
};

function getRecommendedPlaylists(callback) {
    return (dispatch, getState) => {
        if(getState().playlist.playlistListCache.has(PlaylistActionType.GET_RECOMMENDED_PLAYLISTS)){
            callback(getState().playlist.playlistListCache.get(PlaylistActionType.GET_RECOMMENDED_PLAYLISTS));
        }else{
            makeJsonApiCall('GET', '/playlists/recommendedPlaylists').then(playlists => {
                dispatch({
                    type : PlaylistActionType.GET_RECOMMENDED_PLAYLISTS,
                    key : PlaylistActionType.GET_RECOMMENDED_PLAYLISTS,
                    data : playlists
                });
                callback(playlists);
            })
        }
    }
}

function getPlaylistById(playlistId, callback) {
    return (dispatch, getState) => {
        if(getState().playlist.playlistListCache.has(PlaylistActionType.GET_PLAYLIST_BY_ID + playlistId)){
            callback(getState().playlist.playlistListCache.get(PlaylistActionType.GET_PLAYLIST_BY_ID + playlistId));
        }else{
            makeJsonApiCall('GET', '/playlists/' + playlistId).then(playlist => {
                dispatch({
                    type : PlaylistActionType.GET_PLAYLIST_BY_ID,
                    key : PlaylistActionType.GET_PLAYLIST_BY_ID + playlistId,
                    data : playlist
                });
                callback(playlist);
            })
        }
    }
}

function getLibraryPlaylists(callback) {
    return (dispatch, getState) => {
        if(getState().playlist.playlistListCache.has(PlaylistActionType.GET_LIBRARY_PLAYLISTS)){
            callback(getState().playlist.playlistListCache.get(PlaylistActionType.GET_LIBRARY_PLAYLISTS));
        }else{
            makeJsonApiCall('GET', '/userPlaylists/getLibraryPlaylists').then(playlists => {
                dispatch({
                    type : PlaylistActionType.GET_LIBRARY_PLAYLISTS,
                    key : PlaylistActionType.GET_LIBRARY_PLAYLISTS,
                    data : playlists
                });
                callback(playlists)
            })
        }
    }
}

function createPlaylist(data, coverFile = null, callback) {
    return (dispatch) => {
        let form = new FormData();
        form.append('playlist', JSON.stringify({name : data.name, enabled : data.enabled, description: data.description, private: data.private}));
        if(coverFile !== null)
            form.append('file', coverFile);
        makeFormApiCall('POST', '/playlists', form).then(response =>{
            dispatch({
                type: CacheActionType.INVALIDATE_KEY,
                key : PlaylistActionType.GET_MY_PLAYLISTS
            })
            dispatch({
                type: CacheActionType.INVALIDATE_KEY,
                key: PlaylistActionType.GET_LIBRARY_PLAYLISTS
            })
            callback(response);
        })
    }
}

function addMediaToPlaylist(playlistId, mediaId, callback, dispatch) {
    makeJsonApiCall('POST', '/playlistMedia/' + mediaId + '/' + playlistId).then(response => {
        dispatch({
            type: CacheActionType.INVALIDATE_KEY,
            key: MediaActionType.GET_MEDIA_FROM_PLAYLIST+playlistId
        });
        callback(response)
    })
}

function getMyPlaylists(callback) {
    return (dispatch, getState) => {
        if(getState().playlist.playlistListCache.has(PlaylistActionType.GET_MY_PLAYLISTS)){
            callback(getState().playlist.playlistListCache.get(PlaylistActionType.GET_MY_PLAYLISTS));
        }else{
            makeJsonApiCall('GET', '/playlists/my').then(playlists =>{
                dispatch({
                    type : PlaylistActionType.GET_MY_PLAYLISTS,
                    key : PlaylistActionType.GET_MY_PLAYLISTS,
                    data : playlists
                });
                callback(playlists);
            });
        }
    }
}

function removeFromLibrary(playlistId, dispatch) {
    makeJsonApiCall('DELETE', '/userPlaylists/playlist/' + playlistId).then(response => {
        dispatch({
            type : PlaylistActionType.DELETE_PLAYLIST,
            key : PlaylistActionType.GET_LIBRARY_PLAYLISTS,
            data: playlistId
        })
    });
}

function addToLibrary(playlist, dispatch) {
    makeJsonApiCall('POST', '/userPlaylists/playlist/' + playlist.id).then(response => {
        dispatch({
            type : PlaylistActionType.ADD_TO_LIBRARY,
            key : PlaylistActionType.GET_LIBRARY_PLAYLISTS,
            data: playlist

        })
    });
}

function deletePlaylist(playlistId, callback) {
    return dispatch => {
        makeJsonApiCall('DELETE', '/playlists/' + playlistId).then(response => {
            dispatch({
                type : PlaylistActionType.DELETE_PLAYLIST,
                key : PlaylistActionType.GET_LIBRARY_PLAYLISTS,
                data : playlistId
            });
            callback(true);
        });
    }
}

function removeMediaFromPlaylist(mediaId, playlistId, callback) {
    return dispatch => {
        makeJsonApiCall('DELETE', '/playlistMedia/'+mediaId+'/'+playlistId).then(response => {
            dispatch({
                type: PlaylistActionType.REMOVE_MEDIA_FROM_PLAYLIST,
                key: MediaActionType.GET_MEDIA_FROM_PLAYLIST+playlistId,
                data: {mediaId: mediaId, playlistId: playlistId}
            })
            callback(response)
        })
    }
}