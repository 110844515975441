import {MediaActions} from "../api/media/MediaApi";

//player actions
export const PlayerActionType = {
    PLAY_SONG : 'PLAY_SONG',
    PLAY_LIST_OF_SONGS : 'PLAY_LIST_OF_SONGS',
    PLAY_PREV_SONG : 'PLAY_PREV_SONG',
    PLAY_NEXT_SONG : 'PLAY_NEXT_SONG',
    PAUSE_SONG : 'PAUSE_SONG',
    ADD_TO_QUEUE : 'ADD_TO_QUEUE',
    PLAY_FROM_QUEUE : 'PLAY_FROM_QUEUE',
    SHUFFLE : 'SHUFFLE',
    ADD_ALBUM_TO_QUEUE_AND_PLAY : "ADD_ALBUM_TO_QUEUE_AND_PLAY",
    CLEAR_QUEUE : 'CLEAR_QUEUE',
    REPEAT : 'REPEAT',
    CHANGE_VOLUME : 'CHANGE_VOLUME',
    DRAG_SONG : 'DRAG_SONG',
    REMOVE_FROM_QUEUE : 'REMOVE_FROM_QUEUE',
    ADD_MEDIA_TO_LIBRARY : 'ADD_MEDIA_TO_LIBRARY',
    REMOVE_MEDIA_FROM_LIBRARY: 'REMOVE_MEDIA_FROM_LIBRARY',
    MARK_SONG_CHANGED: 'MARK_SONG_CHANGED'
};

//player states
export const PlayerStates = {
    PLAYING : 'PLAYING',
    PAUSED : 'PAUSED',
    STOP : 'STOP',
    UNDEFINED : 'PLAYER_UNDEFINED'
}

export const PlayerActions = {
    playSong,
    pauseSong,
    playNextSong,
    playPrevSong,
    addToQueue,
    playFromQueue,
    shuffle,
    clearQueue,
    repeat,
    changeVolume,
    dragSong,
    removeFromQueue,
    playListOfSongs,
    addAlbumToQueueAndPlay,
    markSongChanged
}

function playSong(song){
    return dispatch => {
        dispatch({
            type: PlayerActionType.PLAY_SONG,
            song
        })
    }
}

/**
 *
 * @param song - song from $list which will be played
 * @param list - list of songs
 * @returns {{type: string, song: *, list: *}}
 */
function playListOfSongs(song, list){
    return dispatch => {
        dispatch({
            type: PlayerActionType.PLAY_LIST_OF_SONGS,
            song, list
        })
    }
}

function pauseSong(){
    return dispatch => {
        dispatch({
            type: PlayerActionType.PAUSE_SONG
        })
    }
}

function playNextSong(){
    return dispatch => {
        dispatch({
            type: PlayerActionType.PLAY_NEXT_SONG
        })
    }
}

function playPrevSong(){
    return dispatch => {
        dispatch({
            type: PlayerActionType.PLAY_PREV_SONG
        })
    }
}

function addToQueue(song = null, list = null){
    return dispatch => {
        dispatch({
            type: PlayerActionType.ADD_TO_QUEUE,
            song, list
        })
    }
}

function playFromQueue(song){
    return dispatch => {
        dispatch({
            type: PlayerActionType.PLAY_FROM_QUEUE,
            song
        })
    }
}

function shuffle(){
    return dispatch=> {
        dispatch({
            type: PlayerActionType.SHUFFLE
        })
    }
}

function clearQueue(){
    return dispatch => {
        dispatch({
            type: PlayerActionType.CLEAR_QUEUE
        })
    }
}

function repeat(){
    return dispatch => {
        dispatch({
            type: PlayerActionType.REPEAT
        })
    }
}

function changeVolume(volume){
    return dispatch=> {
        dispatch({
            type: PlayerActionType.CHANGE_VOLUME,
            volume
        })
    }
}

function dragSong(indexes){
    return dispatch => {
        dispatch({
            type: PlayerActionType.DRAG_SONG,
            indexes
        })
    }
}

function removeFromQueue(song){
    return dispatch => {
        dispatch({
            type: PlayerActionType.REMOVE_FROM_QUEUE,
            song
        })
    }
}

function addAlbumToQueueAndPlay(albumId, andPlay) {
    if(typeof andPlay === 'undefined') andPlay = true;
    return (dispatch, getState ) => {
        MediaActions.getMediaFromAlbum(albumId, (response) => {

            if(andPlay) {
                dispatch(playListOfSongs(response[0], response))
            }else{
                dispatch(addToQueue(null, response))
            }
            dispatch(success(albumId))
        }, dispatch, getState)
    };

    function success(albumId) {return {type: PlayerActionType.ADD_ALBUM_TO_QUEUE_AND_PLAY, albumId: albumId}}
}

function markSongChanged(){
    return dispatch =>{
        dispatch({
            type: PlayerActionType.MARK_SONG_CHANGED
        })
    }
}