import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {toast} from "react-toastify";
import {UserActions} from "../../redux/api/user/UserApi";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';

class AccountActivation extends Component {

    state = {
        currentUser: null
    };

    componentDidMount() {
        this.props.activateAccount(this.props.match.params.activationCode, (response) => {
            if(response === "FAN" || response === "ARTIST") {
                this.setState({currenUser: response});
                toast.success("Account Activated", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }

    render() {
        return (
            this.state.currentUser === "FAN" ? <Redirect to="/login" /> : <Redirect to="/login" />
        )
    }
}

function mapStateToProps(state) {
    return {}
}
function mapDispatchToProps(dispatch) {
    return {
        activateAccount : bindActionCreators(UserActions.activateAccount, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountActivation);