import React from 'react';

import {
    Route,
    Redirect,
    withRouter,
} from 'react-router-dom'

const PrivateRouteAdmin = ({currentUser, component, ...rest}) => (

    <Route  {...rest}
            render={
                (props) => ((currentUser === 'ADMIN') ?
                        (React.createElement(component, props)) : (<div><Redirect to={{pathname: '/admin/login'}}/> </div>)
                )}/>
);

export default withRouter(PrivateRouteAdmin);