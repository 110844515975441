import {makeFormApiCall, makeJsonApiCall} from "../ApiCall";
import {CacheActionType} from "../user/UserApi";

class ArtistApi {}

export const ArtistActionType = {
    GET_ARTIST_BY_ID : 'GET_ARTIST_BY_ID',
    GET_LIBRARY_ARTIST : 'GET_LIBRARY_ARTIST',
    GET_SIMILAR_ARTISTS : 'GET_SIMILAR_ARTISTS',
    GET_ALL_ARTISTS : 'GET_ALL_ARTISTS',
    GET_ARTIST_LIST_BY_USER : 'GET_ARTIST_LIST_BY_USER',
    DELETE_ARTIST_BY_ID : 'DELETE_ARTIST_BY_ID',
    PUT_ARTIST_INFO : 'PUT_ARTIST_INFO',
    GET_ALL_ARTIST_COUNT : 'GET_ALL_ARTIST_COUNT'
};

export const ArtistActions = {
    getArtistById,
    getLibraryArtist,
    getSimilarArtists,
    getAllArtists,
    getArtistListByUser,
    addToLibrary,
    removeFromLibrary,
    addUserToArtist,
    createArtist,
    deleteArtistById,
    updateArtist,
    getAllArtistCount,
};

function getArtistById(artistId, callback) {
    return (dispatch, getState) => {
        if(getState().artist.artistCache.has(ArtistActionType.GET_ARTIST_BY_ID+artistId)){
            callback(getState().artist.artistCache.get(ArtistActionType.GET_ARTIST_BY_ID+artistId));
        }else{
            makeJsonApiCall('GET', '/artists/'+artistId).then(artist => {
                dispatch({
                    type : ArtistActionType.GET_ARTIST_BY_ID,
                    key : ArtistActionType.GET_ARTIST_BY_ID + artistId,
                    data : artist
                });
                callback(artist);
            })
        }
    }
}

function deleteArtistById(artistId, callback) {
    return (dispatch) => {
            makeJsonApiCall('DELETE', '/artists/' +artistId).then(response => {
                dispatch({
                    type : CacheActionType.INVALIDATE_ALL,
                    key : ArtistActionType.DELETE_ARTIST_BY_ID + artistId,
                });
                callback(true);
            })
    }
}

function getLibraryArtist(callback) {
    return (dispatch, getState) => {
        if(getState().artist.artistListCache.has(ArtistActionType.GET_LIBRARY_ARTIST)){
            callback(getState().artist.artistListCache.get(ArtistActionType.GET_LIBRARY_ARTIST));
        } else {
            makeJsonApiCall('GET', '/userArtists/getLibraryArtists').then(artists => {
                dispatch({
                    type : ArtistActionType.GET_LIBRARY_ARTIST,
                    key : ArtistActionType.GET_LIBRARY_ARTIST,
                    data : artists
                });
                callback(artists);
            })
        }
    }
}

function getSimilarArtists(artistId, callback) {
    return (dispatch, getState) => {
        if(getState().artist.artistListCache.has(ArtistActionType.GET_SIMILAR_ARTISTS + artistId)){
            callback(getState().artist.artistListCache.get(ArtistActionType.GET_SIMILAR_ARTISTS + artistId));
        } else {
            makeJsonApiCall('GET', '/artists/similarTo/'+ artistId).then(artists => {
                dispatch({
                    type: ArtistActionType.GET_SIMILAR_ARTISTS,
                    key : ArtistActionType.GET_SIMILAR_ARTISTS + artistId,
                    data : artists
                });
                callback(artists);
            });
        }
    }
}

function getAllArtists(callback) {
    return (dispatch, getState) =>{
        if(getState().artist.artistListCache.has(ArtistActionType.GET_ALL_ARTISTS)){
            callback(getState().artist.artistListCache.get(ArtistActionType.GET_ALL_ARTISTS));
        }else{
            makeJsonApiCall('GET', '/artists/all').then(artists => {
                dispatch({
                    type : ArtistActionType.GET_ALL_ARTISTS,
                    key : ArtistActionType.GET_ALL_ARTISTS,
                    data : artists
                });
                callback(artists);
            });
        }
    }
}

function getArtistListByUser(callback) {
    return (dispatch, getState) => {
        if(getState().artist.artistListCache.has(ArtistActionType.GET_ARTIST_LIST_BY_USER)){
            callback(getState().artist.artistListCache.get(ArtistActionType.GET_ARTIST_LIST_BY_USER));
        }else{
            makeJsonApiCall('GET', '/artistUsers/getArtistsByCurrentUser').then(artists => {
                let artistList = artists.map(item => {
                    return item.artist
                });
                dispatch({
                    type : ArtistActionType.GET_ARTIST_LIST_BY_USER,
                    key : ArtistActionType.GET_ARTIST_LIST_BY_USER,
                    data : artistList
                });
                callback(artistList);
            });
        }
    }
}

function addToLibrary(artistId, dispatch) {
    makeJsonApiCall('POST', '/userArtists/artist/' + artistId).then(response => {
        dispatch({
            type : CacheActionType.INVALIDATE_KEY,
            key : ArtistActionType.GET_LIBRARY_ARTIST
        })
    });
}

function removeFromLibrary(artistId, dispatch) {
    makeJsonApiCall('DELETE', '/userArtists/artist/' + artistId).then(response => {
        dispatch({
            type : CacheActionType.INVALIDATE_KEY,
            key : ArtistActionType.GET_LIBRARY_ARTIST
        })
    });
}

function addUserToArtist(artistId, callback) {
    return () => {
        let userId = localStorage.getItem('userId');
        makeJsonApiCall('POST', '/artistUsers/'+artistId+'/'+userId).then(response => {
            callback(true);
        })
    }
}

function createArtist(artistForm, callback) {
    return (dispatch) => {
        makeFormApiCall('POST', '/artists', artistForm).then(response => {
            dispatch({
                type : CacheActionType.INVALIDATE_ALL
            });
            callback(response);
        });
    }
}

function updateArtist(artistForm) {
    return (dispatch) => {
        makeFormApiCall('PUT', '/artists/updateArtistInfo', artistForm).then(response => {
            dispatch({
                type : CacheActionType.INVALIDATE_KEY,
                key : ArtistActionType.GET_ARTIST_BY_ID+artistForm.id
            })
        });
    }
}

function getAllArtistCount(callback) {
    return () => {
        makeJsonApiCall('GET', '/artists/getNumberOfAllArtist').then(response => {
            callback(response)
        })
    }
}

function searchByAdmin(callback) {
    return () => {
        makeJsonApiCall('GET', '/artists/searchArtistByAdmin').then(response => {
            callback(response)
        })
    }
}