import {
    arrayMove,
} from 'react-sortable-hoc';
import {PlayerActionType, PlayerStates} from "../actions/player";

const initialState = {
    currentPlaylist: [],
    currentPlayingId: -1,
    currentPlayerState: PlayerStates.UNDEFINED,
    originalPlaylist: [],
    isShuffled: false,
    songChanged: false,
    repeat : false,
    volume: 1
};

const STORAGE_STATE_KEY = 'STORAGE_STATE_KEY';

const player = (state = initialState, action) => {
    let newState = state;
    if(state === initialState){
        let storedState = JSON.parse(localStorage.getItem(STORAGE_STATE_KEY));
        if(storedState !== undefined && storedState  !== null) {
            newState = storedState;
            newState.currentPlayerState = PlayerStates.STOP;
        }
    }

    switch (action.type) {
        case 'LOGOUT_USER':
            return initialState;
        case PlayerActionType.PLAY_SONG:
            if(action.song === undefined){
                break
            }
            if (action.song === null) {
                newState = {
                    ...state,
                    currentPlayerState: PlayerStates.PLAYING,
                    songChanged: false
                }
            } else {
                let newPlaylist = [...state.currentPlaylist];
                let newSong =Object.assign({}, action.song);
                if (newPlaylist.length === 0) {
                    newSong.queueOrder = 0;
                    newPlaylist.push(newSong);
                } else {
                    let minQueueOrder = 0;
                    state.currentPlaylist.forEach((item) => {
                        if(minQueueOrder > item.queueOrder){
                            minQueueOrder = item.queueOrder;
                        }
                    });
                    newSong.queueOrder = minQueueOrder - 1;
                    newPlaylist.push(newSong);
                }
                newState = {
                    ...state,
                    currentPlaylist: newPlaylist,
                    originalPlaylist: [...newPlaylist],
                    currentPlayerState: PlayerStates.PLAYING,
                    currentPlayingId: Math.min(newPlaylist.length, newPlaylist.length - 1),
                    songChanged: true
                };
            }
            break;
        case PlayerActionType.PAUSE_SONG:
            newState = {
                ...state,
                currentPlayerState: PlayerStates.PAUSED,
                songChanged: false
            };
            break;
        case PlayerActionType.PLAY_NEXT_SONG:
            let newPlayingId = state.currentPlayingId
            let songChanged = false
            if(state.repeat){
                newPlayingId = ((state.currentPlayingId + 1) % state.currentPlaylist.length)
                songChanged = true
            }else if(newPlayingId + 1 < state.currentPlaylist.length){
                newPlayingId = state.currentPlayingId + 1
                songChanged = true
            }else{
                //do nothing, player should stop on current song because it's last song in queue and repeat is not checked
            }
            if(songChanged) {
                newState = {
                    ...state,
                    currentPlayingId: state.repeat ? ((state.currentPlayingId + 1) % state.currentPlaylist.length) : Math.min(state.currentPlayingId + 1, state.currentPlaylist.length - 1),
                    currentPlayerState: PlayerStates.PLAYING,
                    songChanged: true
                };
            }else{
                newState = {
                    ...state,
                    currentPlayerState: PlayerStates.STOP,
                    songChanged: false
                }
            }
            break;
        case PlayerActionType.PLAY_PREV_SONG:
            newState = {
                ...state,
                currentPlayingId: state.repeat ? ((state.currentPlayingId - 1 + state.currentPlaylist.length) % state.currentPlaylist.length) : Math.max(state.currentPlayingId - 1, 0),
                currentPlayerState: PlayerStates.PLAYING,
                songChanged: true
            };
            break;
        case PlayerActionType.ADD_TO_QUEUE:
            if(action.song !== null) {
                let newSong = Object.assign({}, action.song);
                newSong.queueOrder = state.currentPlaylist.length;
                newState = {
                    ...state,
                    currentPlaylist: [...state.currentPlaylist, newSong],
                    currentPlayingId: Math.max(0, Math.min(state.currentPlayingId, state.currentPlaylist.length - 1)),
                    currentPlayerState: state.currentPlayerState === PlayerStates.UNDEFINED ? PlayerStates.STOP : state.currentPlayerState,
                    songChanged: state.currentPlayerState === PlayerStates.STOP
                };
            }else if(action.list !== null){
                let maxQueueOrder = getMaxQueueOrder(state.currentPlaylist);
                let newSongs = setQueueOrder(action.list, maxQueueOrder);
                newState = {
                    ...state,
                    currentPlaylist: [...state.currentPlaylist, ...newSongs],
                    currentPlayingId: Math.max(0, Math.min(state.currentPlayingId, state.currentPlaylist.length - 1)),
                    currentPlayerState: state.currentPlayerState === PlayerStates.UNDEFINED ? PlayerStates.STOP : state.currentPlayerState,
                    songChanged: state.currentPlayerState === PlayerStates.STOP
                };
            }
            break;
        case PlayerActionType.PLAY_FROM_QUEUE:
            let songToPlayId = -1;
            state.currentPlaylist.some((item, index) => {
                if(item.queueOrder === action.song.queueOrder){
                    songToPlayId = index;
                    return true
                }
            });
            newState = {
                ...state,
                currentPlayingId: songToPlayId,
                currentPlayerState: PlayerStates.PLAYING,
                songChanged: true
            };
            break;
        case PlayerActionType.SHUFFLE:
            if (state.isShuffled) {
                let currentSong = state.currentPlaylist[state.currentPlayingId];
                let newIndex = -1;
                if(currentSong !== undefined) {
                    state.originalPlaylist.some((item, index) => {
                        if (item.id === currentSong.id) {
                            newIndex = index;
                            return true
                        }
                    });
                }
                if(newIndex === -1){
                    newIndex = 0
                }
                newState = {
                    ...state,
                    currentPlaylist: state.originalPlaylist,
                    currentPlayingId: newIndex,
                    isShuffled: false,
                    songChanged: false
                };
            } else {
                let shuffledArray = shufflePlaylist(state.currentPlaylist, state.currentPlayingId);
                if(shuffledArray.trackedId === -1){
                    shuffledArray.trackedId = 0
                }
                newState = {
                    ...state,
                    originalPlaylist: [...state.currentPlaylist],
                    isShuffled: true,
                    currentPlaylist: shuffledArray.array,
                    currentPlayingId: shuffledArray.trackedId,
                    songChanged: false
                }
            }
            break;
        case PlayerActionType.CLEAR_QUEUE:
            newState = {
                ...state,
                currentPlaylist: [],
                originalPlaylist: [],
                currentPlayingId: -1,
                currentPlayerState: PlayerStates.STOP,
                songChanged: true
            };
            break;
        case PlayerActionType.REPEAT:
            if(state.repeat){
                newState ={
                    ...state,
                    repeat: false,
                    songChanged : false
                };
            }else{
                newState ={
                    ...state,
                    repeat: true,
                    songChanged : false
                };
            }
            break;
        case PlayerActionType.CHANGE_VOLUME:
            newState = {
                ... state,
                volume: action.volume,
                songChanged: false
            };
            break;
        case PlayerActionType.PLAY_LIST_OF_SONGS:
            let newQueue = setQueueOrder([...action.list]);
            if(newQueue.length <= 0){
                return state
            }
            let indexToPlay = 0;
            newQueue.forEach((item, index) => {
                if(item.id === action.song.id){
                    indexToPlay = index;
                }
            });
            newState = {
                ...state,
                currentPlaylist: newQueue,
                currentPlayerState: PlayerStates.PLAYING,
                originalPlaylist: [...newQueue],
                currentPlayingId: indexToPlay,
                songChanged: true
            };
            break;
        case PlayerActionType.DRAG_SONG:
            newState = {
                ...state,
                currentPlaylist: arrayMove(state.currentPlaylist, action.indexes.oldIndex, action.indexes.newIndex),
                currentPlayingId: state.currentPlayingId === action.indexes.oldIndex ? action.indexes.newIndex : state.currentPlayingId,
                songChanged: false
            };
            break;
        case PlayerActionType.REMOVE_FROM_QUEUE:
            let currPlaylist = state.currentPlaylist;
            let currOrig = state.originalPlaylist;
            let indexToRemove = getSongIndex(currPlaylist, action.song);
            newState = {...state}

            if(indexToRemove !== -1){
                currPlaylist.splice(indexToRemove, 1);
            }
            if(currOrig.length > 0) {
                let indexOrigToRemove = getSongIndex(currOrig, action.song);
                if(indexOrigToRemove !== -1){
                    currOrig.splice(indexOrigToRemove, 1);
                }
            }
            let currentPlayingId = state.currentPlayingId;
            if(state.currentPlayingId > indexToRemove){
                currentPlayingId -= 1;
            }
            if(indexToRemove === state.currentPlayingId && currPlaylist.length > 0){
                // newState = {...player(state, {type:PLAY_NEXT_SONG})};
                newState = {
                    ...state,
                    currentPlayingId: state.repeat ? ((state.currentPlayingId ) % state.currentPlaylist.length)
                        :  Math.min(state.currentPlayingId , state.currentPlaylist.length ),
                    songChanged: true
                };
            }else if(currPlaylist.length === 0){
                newState = {...player(state, {type:PlayerActionType.CLEAR_QUEUE})}
            }else {
                newState = {
                    ...state,
                    currentPlaylist: currPlaylist,
                    originalPlaylist: currOrig,
                    currentPlayingId : currentPlayingId,
                    songChanged: false
                };
            }
            break;
        case PlayerActionType.REMOVE_MEDIA_FROM_LIBRARY:
        case PlayerActionType.ADD_MEDIA_TO_LIBRARY:
            let currentQueue = state.currentPlaylist;
            let currentOrig = state.originalPlaylist;
            newState = {
                ...state,
                currentPlaylist: searchSongAndChange(currentQueue, action.data.id, action.data.inLibrary),
                originalPlaylist: searchSongAndChange(currentOrig, action.data.id, action.data.inLibrary),
                songChanged: false
            };

            break;
        case PlayerActionType.MARK_SONG_CHANGED:
            newState ={
                ...state,
                songChanged: false
            }
            break;
        default:
            break;
    }
    localStorage.setItem(STORAGE_STATE_KEY, JSON.stringify(newState));
    return newState;
};

const getSongIndex = (list, song) => {
    let idx = -1;
    list.forEach((item, index) => {
        if(song.id === item.id && song.queueOrder === item.queueOrder){
            idx = index;
        }
    });
    return idx
}

const setQueueOrder = (list, from = -1) => {
    list.forEach((item, index) => {
        item.queueOrder = index;
        if(from !== -1){
            item.queueOrder += from;
        }
    });
    return list
};

const getMinQueueOrder = (list) => {
    let minQueueOrder = 0;
    list.forEach((item) => {
        if(item.queueOrder < minQueueOrder){
            minQueueOrder = item.queueOrder
        }
    });
    return minQueueOrder;
};

const getMaxQueueOrder = (list) => {
    let maxQueueOrder = 0;
    list.forEach((item) => {
        if(item.queueOrder > maxQueueOrder){
            maxQueueOrder = item.queueOrder
        }
    });
    return maxQueueOrder;
};

const shufflePlaylist = (arrayToShuffle, idToTrack) => {
    let array = [...arrayToShuffle];
    let counter = array.length;
    let itemToTrack = arrayToShuffle[idToTrack];
    while (counter > 0) {
        let index = Math.floor(Math.random() * counter);
        counter--;
        let temp = array[counter];

        if (counter === idToTrack) {
            idToTrack = index;
        }

        array[counter] = array[index];
        array[index] = temp;
    }
    return {'array': array, 'trackedId': array.indexOf(itemToTrack)};
};

function searchSongAndChange(list, songId, inLibrary) {
    list.forEach((value) => {
        if(value.id === songId){
            value.inLibrary = inLibrary
        }
    });
    return list;
}

export default player;