export const Views = {
    VIEW_CHANGE_VIEW: "VIEW_CHANGE_VIEW",
    VIEW_CLEAR: "VIEW_CLEAR",
    VIEW_ADD_TO_PLAYLIST: "VIEW_ADD_TO_PLAYLIST",
    VIEW_NEW_PLAYLIST: "VIEW_NEW_PLAYLIST",
    VIEW_CLOSE_VIEW: "VIEW_CLOSE_VIEW",
    VIEW_CLOSE_SEARCH: "VIEW_CLOSE_SEARCH",
    VIEW_OPEN_SEARCH: "VIEW_OPEN_SEARCH"
};

export const ViewActions = {
    ChangeView,
    CloseAllViews,
    CloseLastView,
    CloseSearch,
    OpenSearch
};

function ChangeView(view, params) {
    return dispatch => {
        dispatch({
            type: Views.VIEW_CHANGE_VIEW,
            view: view,
            params: params
        })
    }
}

function CloseSearch() {
    return dispatch => {
        dispatch({
            type: Views.VIEW_CLOSE_SEARCH,
        })
    }
}

function OpenSearch() {
    return dispatch => {
        dispatch({
            type: Views.VIEW_OPEN_SEARCH,
        })
    }
}

function CloseAllViews() {
    return dispatch => {
        dispatch({type: Views.VIEW_CLEAR})
    }
}

function CloseLastView() {
    return dispatch => {
        dispatch({type: Views.VIEW_CLOSE_VIEW})
    }
}