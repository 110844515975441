import {ArtistActionType} from "./ArtistApi";
import {CacheActionType} from "../user/UserApi";

class ArtistCache {}

const initialState = {
    artistCache : new Map(),
    artistListCache : new Map()
};

export default function artist( state = initialState, action) {
    let newState = state;
    switch (action.type){
        case ArtistActionType.GET_ARTIST_BY_ID:
            newState = {
                ... state,
                artistCache : state.artistCache.set(action.key, action.data)
            };
            break;
        case ArtistActionType.GET_LIBRARY_ARTIST:
        case ArtistActionType.GET_SIMILAR_ARTISTS:
        case ArtistActionType.GET_ALL_ARTISTS:
        case ArtistActionType.GET_ARTIST_LIST_BY_USER:
            newState = {
                ...state,
                artistListCache : state.artistListCache.set(action.key, action.data)
            };
            break;
        case CacheActionType.INVALIDATE_KEY:
            let newListCache = state.artistListCache;
            let newCache = state.artistCache;
            newListCache.delete(action.key);
            newCache.delete(action.key);
            newState = {
                ...state,
                artistCache : newCache,
                artistListCache : newListCache
            };
            break;
        case CacheActionType.INVALIDATE_ALL:
            newState = {
                ...state,
                artistCache : new Map(),
                artistListCache : new Map()
            };
            break;
        default:
            break;
    }
    return newState;
}