import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {bindActionCreators} from "redux"
import {AlbumActions} from "../../redux/api/album/AlbumApi";
import {ArtistActions} from "../../redux/api/artist/ArtistApi";

class ArtistAlbumsComponent extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            albums : [],
            loading : false
        }
    }

    componentDidMount(){
        this.setState({
            loading : true
        })
        this.props.getArtistById(this.props.match.params.id, cb => {
            this.setState({
                artist : cb
            })
        })
        this.props.getAlbumsByArtistId(this.props.match.params.id, cb => {
            this.setState({
                loading : false,
                albums : cb
            })
        })
    }

    deleteAlbum(albumId, index) {
        this.props.deleteAlbum(albumId, this.props.match.params.id, cb => {
            let albums = this.state.albums;
            albums.splice(index, 1);
            this.setState({albums: albums})
        })
    }

    render() {
        return (
            <div className="row pt-3 ">
                {this.state.albums === null ? null :
                    this.state.albums.length > 0 ?
                        this.state.albums.map((item, index) =>
                            <div className="col-lg-3" key={index}>
                                <div className="adminRelease">
                                    <div className="cover"><img src={item.image_url} alt="album1"/></div>
                                    <div className="infoPanel">
                                        <p>{item.name}</p>
                                        <small>{item.artist.name}</small>
                                        <div className="hr"></div>
                                        <div className="d-flex">
                                            <Link to={"/admin/artists/"+this.props.match.params.id+"/albums/" + item.id}
                                                  className="icon-edit-pencil-symbol"/>
                                            {/* <a href="#" className="icon-edit-pencil-symbol"></a> */}
                                            <a href="#" className="icon-close-circle" onClick={e => {
                                                e.preventDefault();
                                                window.confirm('Are you sure you wish to delete this item?') ? this.deleteAlbum(item.id, index) : null
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        : null
                }
            </div>
        )
    }
}

function mapStateToProps(state){
    return {

    }
}

function mapDispatchToProps(dispatch){
    return {
        getAlbumsByArtistId : bindActionCreators(AlbumActions.getAlbumOfArtist, dispatch),
        deleteAlbum: bindActionCreators(AlbumActions.deleteAlbum, dispatch),
        getArtistById: bindActionCreators(ArtistActions.getArtistById, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistAlbumsComponent)