import {Views} from '../actions/ViewActions' // CONST

const initialState = {
    viewStack: [],
    isSearchOpened: false,
};

export default function views(state = initialState, action) {
    switch(action.type) {
        case Views.VIEW_CHANGE_VIEW:
            if(action.view === Views.VIEW_ADD_TO_PLAYLIST) {
                let newStack = state.viewStack;
                newStack.push(Views.VIEW_ADD_TO_PLAYLIST);
                return Object.assign({}, state, {
                    params: action.params,
                    viewStack : newStack
                })
            }
            if(action.view === Views.VIEW_NEW_PLAYLIST) {
                let newStack = state.viewStack;
                newStack.push(Views.VIEW_NEW_PLAYLIST);
                return Object.assign({}, state, {
                    viewStack : newStack
                })
            }
            return state;
        case Views.VIEW_CLEAR:
            return Object.assign({}, state,{
                viewStack: []
            });
        case Views.VIEW_CLOSE_VIEW:
            let newStack = state.viewStack;
            newStack.pop();
            return Object.assign({}, state, {
                viewStack : newStack
            });
        case Views.VIEW_CLOSE_SEARCH:
            return Object.assign({}, state,{
                isSearchOpened: false,
            });
        case Views.VIEW_OPEN_SEARCH:
            return Object.assign({}, state,{
                isSearchOpened: true,
            });
        default:
            return state
    }
}