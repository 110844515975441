import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';
import {createStore, applyMiddleware} from 'redux';
import reducer from './redux/reducers';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom'
import thunkMiddleware from 'redux-thunk'
import {createLogger} from 'redux-logger'
import {nodeEnv} from "./base";
import './css/fontello.css'
import './css/opus_login.scss'
import './css/opus-panel.scss'
import * as Sentry from '@sentry/browser';
import history from './helpers/history';
import { Integrations } from '@sentry/tracing';

Sentry.init({
    dsn: "https://88bc2204d5d449a19238ff2d2ba7c8ff@o413466.ingest.sentry.io/5300113",
    environment: process.env.REACT_APP_STAGE,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
});

const loggerMiddleware = createLogger()

const store = () => {
    if(nodeEnv === "dev" || nodeEnv === "localhost") {
        return createStore(reducer,
            applyMiddleware(
                thunkMiddleware,
                loggerMiddleware
            )
        )
    }else{
        return createStore(reducer,
            applyMiddleware(
              thunkMiddleware
            )
        )
    }
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store()}>
      <Router history={history}>
        <div>
          <App />
        </div>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
