import { combineReducers } from 'redux';
import player from './player';
import artist from '../api/artist/ArtistCache';
import loginReducer from './LoginReducer';
import library from './LibraryReducer';
import views from './ViewsReducer';
import album from '../api/album/AlbumCache';
import media from '../api/media/MediaCache';
import playlist from '../api/playlist/PlaylistCache';
import user from '../api/user/UserCache';

export default combineReducers({
    loginReducer,
    player,
    library,
    views,
    album,
    artist,
    media,
    playlist,
    user
});