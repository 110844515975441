import React, { Component } from 'react'
import './ErrorBoundary.scss'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('ERROR', error, errorInfo)
  }

  render() {
    if (this.state.hasError)
      return (
        <div id="ErrorBoundary">
          <svg id="BACKGROUND" width="2560" height="1440" viewBox="0 0 2560 1440">
            <path id="Kształt_1_kopia_10" data-name="Kształt 1 kopia 10" className="cls-1" d="M3060.38,853.117q41.31,154.079,82.72,308.123c4.46,16.66,3.8,32.36-2.28,48.13a202.183,202.183,0,0,1-40.72,65.14q-217.74,234.4-435.98,468.26c-20.96,22.53-47.74,33.76-77.49,39.06-22.34,4.01-44.28,2.13-66.42-3.07q-291.03-66.855-582.23-132.63c-28.37-6.42-50.88-20.32-69.06-42.34a223.845,223.845,0,0,1-43.42-85.02q-34.65-129.27-69.17-258.45-45.48-170.34-91.27-340.483a78.4,78.4,0,0,1,3.21-52.43,205.944,205.944,0,0,1,39.5-61.917q216.735-232.926,433.63-465.773c23.01-24.822,51.86-36.953,84.31-42.166,20.12-3.187,39.87-1.29,59.88,3.317,194.72,44.613,389.42,89.581,584.51,132.939,50.78,11.316,82,41.313,99.73,88.017,5.24,13.9,9.66,28.24,13.52,42.62q39.015,144.261,77.49,288.665A0.847,0.847,0,0,0,3060.38,853.117Z" transform="translate(-408)" />
            <path id="Kształt_1_kopia_2" data-name="Kształt 1 kopia 2" className="cls-1" d="M1016.38,657.117q41.31,154.079,82.72,308.127c4.46,16.651,3.8,32.357-2.28,48.126a202.183,202.183,0,0,1-40.72,65.14q-217.74,234.4-435.98,468.26c-20.963,22.53-47.738,33.76-77.491,39.06-22.335,4.01-44.282,2.13-66.42-3.07q-291.025-66.855-582.224-132.63c-28.377-6.42-50.88-20.32-69.066-42.34a223.975,223.975,0,0,1-43.421-85.02q-34.651-129.27-69.166-258.45-45.489-170.335-91.274-340.483a78.443,78.443,0,0,1,3.213-52.43,205.871,205.871,0,0,1,39.5-61.917Q-119.487,376.564,97.4,143.717c23.015-24.822,51.866-36.953,84.31-42.166,20.122-3.187,39.869-1.29,59.879,3.317,194.724,44.613,389.427,89.581,584.51,132.939,50.782,11.316,82,41.313,99.738,88.017,5.231,13.9,9.656,28.24,13.511,42.62q39.02,144.261,77.494,288.665A0.857,0.857,0,0,0,1016.38,657.117Z" transform="translate(-408)" />
          </svg>

          <div className="container my-auto">
            <div className="row">
              <div className="col-12 col-lg-6 mx-auto text-center">
                <img src="/img/logo-black.png" alt="logo" className="my-3"/>
                <h1>Something went wrong</h1>
                <p>We are so sorry, but it looks like OPUS Player has crashed. This bug has been automatically reported to our team. Please refresh your page to try again.</p>
                <button className="button button-f" onClick={() => {window.location.reload()}}>Refresh</button>
              </div>
            </div>
          </div>
        </div>
      )

    return this.props.children
  }
}
