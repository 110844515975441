import {CacheActionType, UserActionType, UserActions} from "./UserApi";

class UserCache {}

const initialState = {
    cache : new Map()
};

export default function user( state = initialState, action) {
    let newState = state;
    switch (action.type) {
        case UserActionType.GET_COUNTRIES:
        case UserActionType.GET_CURRENT_USER:
        case UserActionType.GET_GENRES:
            newState = {
                ...state,
                cache : state.cache.set(action.key, action.data)
            };
            break;
        case CacheActionType.INVALIDATE_KEY:
            let newCache = state.cache;
            newCache.delete(action.key);
            newState = {
                ...state,
                cache : newCache
            };
            break;
        case CacheActionType.INVALIDATE_ALL:
            newState = {
                ...state,
                cache : new Map()
            };
            break;

        case UserActionType.GET_QUOTATION:
            newState = {
                ...state,
                quotation: action.data,
            }

        default:
            break;
    }
    return newState
}