import { nodeEnv } from "./base";
var backendHost = 'https://api.opus.audio/api';

export function getApiRoot() {
    // if(nodeEnv === "dev") {
    //     backendHost = 'https://dev.nextrope.com:50005/api';
    // }
    // else if (nodeEnv === "prod" && (localStorage.getItem('loggedAs') === "FAN"  || localStorage.getItem('loggedAs') === "ADMIN") ) {
    //     backendHost =  localStorage.getItem('apiLocationURL') === 'undefined' ? "https://api.opus.audio/api" : localStorage.getItem('apiLocationURL');
    // }else if(nodeEnv === "prod" && localStorage.getItem('loggedAs') === "ARTIST"){
    //     backendHost =  "https://api.opus.audio/api";
    // }else if (nodeEnv === "prod") {
    //     backendHost =  "https://api.opus.audio/api";
    // }
    // else if(nodeEnv === "localhost"){
    //     backendHost = "http://localhost:50004/api";
    // } else {
    //     backendHost = "https://api.opus.audio/api"; // Alternative path
    // }
    return backendHost
}

function dev() {
    return ['dev', 'localhost'].includes(process.env.REACT_APP_STAGE)
}

export const config = {
    WEB3_HOST: dev() ? 'https://rinkeby.infura.io/v3/8ff32e1fab7340b686729fbe5d20ec14' : 'https://mainnet.infura.io/v3/8ff32e1fab7340b686729fbe5d20ec14',
    TOKEN_ADDRES: dev() ? '0xd158f87740074f4b4433a2f82eb352ae06d10974' : '0x4355fC160f74328f9b383dF2EC589bB3dFd82Ba0',
    CONTRACT_ADDRESS: dev() ? '0x5E45682347Fb3F5b7b2E0b33f5Bca6C6776c5508' : '0xBFC6acaCcB09AEB99C8b511278E434Cdd45160eB',
    CIPHER: 'aes-256-cbc',
    WEB3_CHAIN: dev() ? 'rinkeby' : 'mainnet',
    MONTHLY_PAYMENT: 5, // USD
}