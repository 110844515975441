import {PlaylistActions, PlaylistActionType} from "./PlaylistApi";
import {CacheActionType} from "../user/UserApi";

class PlaylistCache{}

const initialState = {
    playlistListCache : new Map(),
    playlistCache : new Map()
}

export default function playlist( state = initialState, action) {
    let newState = state;
    switch (action.type){
        case PlaylistActionType.GET_PLAYLIST_BY_ID:
            newState = {
                ...state,
                playlistCache : state.playlistCache.set(action.key, action.data)
            };
            break;
        case PlaylistActionType.GET_LIBRARY_PLAYLISTS:
        case PlaylistActionType.GET_RECOMMENDED_PLAYLISTS:
        case PlaylistActionType.GET_MY_PLAYLISTS:
            newState = {
                ...state,
                playlistListCache : state.playlistListCache.set(action.key, action.data)
            };
            break;
        case CacheActionType.INVALIDATE_KEY:
            let newListCache = state.playlistListCache;
            let newCache = state.playlistCache;
            newListCache.delete(action.key);
            newCache.delete(action.key);
            newState = {
                ...state,
                playlistCache : newCache,
                playlistListCache : newListCache
            };
            break;
        case CacheActionType.INVALIDATE_ALL:
            newState = {
                ...state,
                playlistListCache : new Map(),
                playlistCache : new Map()
            };
            break;
        case PlaylistActionType.DELETE_PLAYLIST:
            let newPlaylistListCache = state.playlistListCache;
            if(newPlaylistListCache.has(PlaylistActionType.GET_LIBRARY_PLAYLISTS)){
                let items = newPlaylistListCache.get(PlaylistActionType.GET_LIBRARY_PLAYLISTS);
                items = items.filter(item => item.id !== action.data);
                newPlaylistListCache.set(PlaylistActionType.GET_LIBRARY_PLAYLISTS, items);
            }
            if(newPlaylistListCache.has(PlaylistActionType.GET_RECOMMENDED_PLAYLISTS)){
                let items = newPlaylistListCache.get(PlaylistActionType.GET_RECOMMENDED_PLAYLISTS);
                items.map(item => {
                    if(item.id === action.data){
                        item.inLibrary = false
                    }
                })
                newPlaylistListCache.set(PlaylistActionType.GET_RECOMMENDED_PLAYLISTS, items);
            }
            newState = {
                ...state,
                playlistListCache : newPlaylistListCache
            };
            break;
        case PlaylistActionType.ADD_TO_LIBRARY:
            let cache = state.playlistListCache;
            if(cache.has(PlaylistActionType.GET_LIBRARY_PLAYLISTS)){
                let items = cache.get(PlaylistActionType.GET_LIBRARY_PLAYLISTS);
                items.push(action.data)
                cache.set(PlaylistActionType.GET_LIBRARY_PLAYLISTS, items);
            }
            newState = {
                ...state,
                playlistListCache : cache
            };
            break;
        default:
            break;
    }
    return newState
}