import React from 'react';
import DatePicker from 'react-datepicker';
import moment from "moment/moment";
import {connect} from 'react-redux';
import LoaderComponent from "../../components/LoaderComponent";
import {toast} from "react-toastify";
import {bindActionCreators} from "redux";
import {UserActions} from "../../../redux/api/user/UserApi";
import isEmail from "validator/lib/isEmail";

class Registration extends React.Component {

    state = {
        fieldErrors: new Map(),
        isChecked: false,
        registrationForm: {
            login: null,
            password: null,
            confPassword: null,
            role_code: "FAN",
            nickname: null,
            birthdayDate: moment().subtract(18, 'years'),
            idNumber: null,
            idType: null,
            checkbox: false,
            role: null
        },
        birthdayDate: moment().subtract(18, 'years'),
    };

    onHandleDateChange(date) {
        this.setState({
            birthdayDate: date
        });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleRegisterNewUser();
        }
    };

    handleCheckboxChange() {
        this.setState({isChecked: !this.state.isChecked})
    }

    handleRegisterNewUser() {
        let newPerson = this.state.registrationForm;
        newPerson.birthdayDate = this.state.birthdayDate;
        newPerson.checkbox = this.state.isChecked;
        this.validate(newPerson, (cb) => {
            this.setState({fieldErrors: cb});

            if (cb.size > 0) {
                return;
            }
            let person = {
                'user': {
                    'email': newPerson.login,
                    'password': newPerson.password,
                    'nickname': newPerson.nickname,
                    'firstname': newPerson.firstname,
                    'lastname': newPerson.lastname,
                    'role_code': newPerson.role_code,
                    'birth_date': moment(newPerson.birthdayDate).format('YYYY-MM-DD'),
                    'marketing_agreed': newPerson.checkbox,
                    'id_number': newPerson.idNumber,
                    'id_type': newPerson.idType
                }
            };
            this.setState({loading: true});
            this.props.register(person, (response) => {
                if (response.error === null || response.error === undefined) {
                    if (response.status === 0) {
                        toast.info("Activation mail sent successfully.", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        this.setState({loading: false});
                        this.props.history.push('/login')
                    } else if (response.status === -1) {
                        var fe = this.state.fieldErrors
                        if(response.message.startsWith('Password')){
                            fe.set('password', response.message);
                        } else if(response.message.startsWith('user with given nickname')) {
                            fe.set('nickname', response.message);
                        } else {
                            fe.set('login', response.message);
                        }
                        this.setState({fieldErrors: fe});
                        this.setState({loading: false});
                    } else {
                        toast.warn("Ops, something went wrong, please try again.", {
                            position: toast.POSITION.TOP_CENTER
                        });
                        this.setState({loading: false});
                    }
                } else {
                    toast.warn("Ops, something went wrong, please try again.", {
                        position: toast.POSITION.TOP_CENTER
                    });
                    this.setState({loading: false});
                }
            });
        });
    }

    validate(person, cb) {
        const errors = new Map();
        if (!person.login) errors.set('login', 'Email Required');
        if (person.login && !isEmail(person.login)) errors.set('login', 'Invalid Email')
        if (!person.password) {
            errors.set('password', 'Password Required');
        } else {
            let regexPassword = new RegExp("^(?=.*?[a-zA-Z])(?=.*?[0-9]).{6,}$");
            let testRegexPassword = regexPassword.test(person.password);
            if (!testRegexPassword) {
                errors.set('password', "Password must be at least six characters, one letter and one number");
            }
        }
        if (!person.confPassword) {
            errors.set('confPassword', 'Confirm Password Required');
        } else {
            if (person.confPassword !== person.password) {
                errors.set('confPassword', 'Passwords are different')
            }
        }
        if (!person.nickname)
            errors.set('nickname', 'Username Required');
        cb(errors);
    }

    onChange(field, value){
        let reg = this.state.registrationForm;
        if(field === 'login'){
            reg.login = value;
        }else if(field === 'password'){
            reg.password = value;
        }else if(field === 'confPassword'){
            reg.confPassword = value;
        }else if(field === 'nickname'){
            reg.nickname = value;
        }
        this.setState({registrationForm : reg});
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-7 d-flex align-items-center">
                    <h1>Register to<br/>OPUS</h1>
                </div>
                <div className="col-lg-5">
                    <div className="panel position-relative">
                        {this.state.loading &&
                        <LoaderComponent withBg={true}/>
                        }
                        <form action="#">
                            <div className="form-group withError">
                                <input type="text" name="login" className="form-control"
                                       onKeyPress={this.handleKeyPress}
                                       onChange={(e) => this.onChange('login', e.target.value)} placeholder="Your e-mail address"/>
                                <label htmlFor="login"/>
                                {this.state.fieldErrors.has('login') ?
                                    <div key={this.state.fieldErrors.get('login')} className="invalid-feedback">
                                        {this.state.fieldErrors.get('login')}
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="form-group withError">
                                <input type="password" name='password' className="form-control"
                                       onKeyPress={this.handleKeyPress}
                                       onChange={(e) => this.onChange('password', e.target.value)} placeholder="Password"/>
                                <label htmlFor="password"/>
                                {this.state.fieldErrors.has('password') ?
                                    <div key={this.state.fieldErrors.get('password')} className="invalid-feedback">
                                        {this.state.fieldErrors.get('password')}
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="form-group withError">
                                <input type="password" name='confPassword' className="form-control"
                                       onKeyPress={this.handleKeyPress}
                                       onChange={(e) => this.onChange('confPassword', e.target.value)} placeholder="Confirm Password"/>
                                <label htmlFor="confPassword"/>
                                {this.state.fieldErrors.has('confPassword') ?
                                    <div key={this.state.fieldErrors.get('confPassword')} className="invalid-feedback">
                                        {this.state.fieldErrors.get('confPassword')}
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="form-group withError">
                                <input type="text" name='nickname' className="form-control"
                                       onKeyPress={this.handleKeyPress}
                                       onChange={(e) => this.onChange('nickname', e.target.value)} placeholder="Username"/>
                                <label htmlFor="nickname"/>
                                {this.state.fieldErrors.has('nickname') ?
                                    <div key={this.state.fieldErrors.get('nickname')} className="invalid-feedback">
                                        {this.state.fieldErrors.get('nickname')}
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="form-group row">
                                <label htmlFor="date" className="col-4 col-form-label">Date of
                                    birth</label>
                                <div className="col-8">
                                    <DatePicker name="datepicker" className="form-control"
                                                selected={this.state.birthdayDate}
                                                onChange={this.onHandleDateChange.bind(this)}
                                                showYearDropdown
                                                dateFormatCalendar="MMMM"
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={100}
                                                maxDate={moment()}/>
                                </div>
                            </div>

                            <div className="form-group">
                                <input onKeyPress={this.handleKeyPress.bind(this)} name='checkbox' type="checkbox"
                                       onChange={this.handleCheckboxChange.bind(this)}
                                       className="d-none" id="checkbox1"/>
                                <label className="form-check-label" htmlFor="checkbox1">
                                    Share my registration data with Opus's content providers for marketing purposes.
                                </label>
                            </div>

                            <div className="mb-3">
                                <p className="text-muted text-justify">By clicking Register, you agree to our <a href="https://opus.audio/TermsOfUse.pdf" target="_blank" rel="noopener noreferrer">Terms</a>. Learn how we collect, use and share your data in our <a href="https://opus.audio/Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer">Data Policy</a>.</p>
                            </div>

                            <div className="form-group row mb-0">
                                <a onClick={(e) => {
                                    this.handleRegisterNewUser(e)
                                }}
                                   className="btn mx-auto">Register</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        register : bindActionCreators(UserActions.register, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration);


