import React, {Component} from 'react'
import {Link, NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import Chart from 'chart.js'
import {ArtistActions} from "../../redux/api/artist/ArtistApi";
import {bindActionCreators} from "redux";
import {MediaActions} from "../../redux/api/media/MediaApi";
import {AlbumActions} from "../../redux/api/album/AlbumApi";
import {UserActions} from "../../redux/api/user/UserApi";
import moment from 'moment'

Chart.plugins.register({
    afterDatasetsDraw: function (chart) {
        let chart_type = chart.config.type;
        if (chart.tooltip._active && chart.tooltip._active.length && chart_type === 'line') {
            var activePoint = chart.tooltip._active[0],
                ctx = chart.chart.ctx,
                x_axis = chart.scales['x-axis-0'],
                y_axis = chart.scales['y-axis-0'],
                x = activePoint.tooltipPosition().x,
                topY = y_axis.top,
                bottomY = y_axis.bottom;


            // draw line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY + 7);
            ctx.lineTo(x, bottomY + 1);
            // ctx.setLineDash([2,3]);
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#e8e8ef';
            ctx.stroke();
            ctx.restore();
        }
    }
});

const data = {
    label: 'label',
    value: 'value',
    dataLabel: 'dataLabel',
}

const time = {
    week: 'week',
    month: 'month',
    year: 'year'
}

const graphType = {
    plays: 'plays',
    registrations: 'All registrations',
    newsongs: 'newsongs',
    registeredFans: 'Fans registrations',
    registeredArtists: 'Artists registrations',
}

const colors = ['#f53e82', '#cc66ff', '#3366ff']

export class DashboardComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            statTime: time.month,
            graphType: props.match.isExact ? graphType.plays : (props.location.pathname.endsWith("registrations") ? graphType.registrations : graphType.newsongs),
            allArtists: '-',
            allMedias: '-',
            allAlbums: '-',
            allPlays: '-',
            whatsHot: null,
            artistsToApprove: [],
            stats: {
                'plays': {
                    'month': {'label': [], 'dataLabel': [graphType.plays],'value': [[],[],[]]},
                    'week': {'label': [], 'dataLabel': [graphType.plays],'value': [[],[],[]]},
                    'year': {'label': [], 'dataLabel': [graphType.plays],'value': [[],[],[]]}
                },
                'newsongs': {
                    'month': {'label': [], 'dataLabel': [graphType.newsongs],'value': [[],[],[]]},
                    'week': {'label': [], 'dataLabel': [graphType.newsongs],'value': [[],[],[]]},
                    'year': {'label': [], 'dataLabel': [graphType.newsongs],'value': [[],[],[]]}
                },
                'All registrations': {
                    'month': {'label': [], 'dataLabel': [graphType.registrations, graphType.registeredFans, graphType.registeredArtists],'value': [[],[],[]]},
                    'week': {'label': [], 'dataLabel': [graphType.registrations, graphType.registeredFans, graphType.registeredArtists],'value': [[],[],[]]},
                    'year': {'label': [], 'dataLabel': [graphType.registrations, graphType.registeredFans, graphType.registeredArtists],'value': [[],[],[]]},
                }
            }
        }

        this.changeStatTime = this.changeStatTime.bind(this)
    }

    changeStatTime(time) {
        this.setState({statTime: time})
    }

    changeType(type) {
        this.setState({graphType: type})
    }

    loadData() {
        this.props.getArtistCount(cb => {
            this.setState({
                allArtists: cb
            })
        })
        this.props.getAlbumsCount(cb => {
            this.setState({
                allAlbums: cb
            })
        })
        this.props.getMediaCount(cb => {
            this.setState({
                allMedias: cb
            })
        })
        this.props.getAllPlays(cb => {
            this.setState({
                allPlays: cb
            })
        })
        this.props.getArtistsWaitingForApproval(cb => {
            this.setState({
                artistsToApprove: cb
            })
        })
        this.props.getStats(cb => {
            let allStats = this.state.stats
            allStats[graphType.plays][time.year][data.label] = cb.map((value) => moment(new Date(value.date_trunc)).format('MMM D')).slice()
            allStats[graphType.plays][time.month][data.label] = cb.map((value) => moment(new Date(value.date_trunc)).format('MMM D')).slice(0, 30)
            allStats[graphType.plays][time.week][data.label] = cb.map((value) => moment(new Date(value.date_trunc)).format('MMM D')).slice(0, 7)

            allStats[graphType.plays][time.year][data.value][0] = cb.map((value) => value.count).slice()            
            allStats[graphType.plays][time.month][data.value][0] = cb.map((value) => value.count).slice(0, 30)
            allStats[graphType.plays][time.week][data.value][0] = cb.map((value) => value.count).slice(0, 7)
            this.setState({
                stats: allStats
            })
        })
        this.props.getRegistrationStats(null, cb1 => {
            let allStats = this.state.stats
            allStats[graphType.registrations][time.year][data.label] = cb1.map((value) => moment(new Date(value.date_trunc)).format('MMM D')).slice()
            allStats[graphType.registrations][time.month][data.label] = cb1.map((value) => moment(new Date(value.date_trunc)).format('MMM D')).slice(0, 30)
            allStats[graphType.registrations][time.week][data.label] = cb1.map((value) => moment(new Date(value.date_trunc)).format('MMM D')).slice(0, 7)

            allStats[graphType.registrations][time.year][data.value][0] = cb1.map((value) => value.count).slice()
            allStats[graphType.registrations][time.year][data.dataLabel][0] = graphType.registrations
            allStats[graphType.registrations][time.month][data.value][0] = cb1.map((value) => value.count).slice(0, 30)
            allStats[graphType.registrations][time.month][data.dataLabel][0] = graphType.registrations
            allStats[graphType.registrations][time.week][data.value][0] = cb1.map((value) => value.count).slice(0, 7)
            allStats[graphType.registrations][time.week][data.dataLabel][0] = graphType.registrations
            
            this.props.getRegistrationStats(2, cb2 => {
                allStats[graphType.registrations][time.year][data.value][1] = cb2.map((value) => value.count).slice()
                allStats[graphType.registrations][time.year][data.dataLabel][1] = graphType.registeredFans
                allStats[graphType.registrations][time.month][data.value][1] = cb2.map((value) => value.count).slice(0, 30)
                allStats[graphType.registrations][time.month][data.dataLabel][1] = graphType.registeredFans
                allStats[graphType.registrations][time.week][data.value][1] = cb2.map((value) => value.count).slice(0, 7)
                allStats[graphType.registrations][time.week][data.dataLabel][1] = graphType.registeredFans

                this.props.getRegistrationStats(3, cb3 => {
                    allStats[graphType.registrations][time.year][data.value][2] = cb3.map((value) => value.count).slice()
                    allStats[graphType.registrations][time.year][data.dataLabel][2] = graphType.registeredArtists
                    allStats[graphType.registrations][time.month][data.value][2] = cb3.map((value) => value.count).slice(0, 30)
                    allStats[graphType.registrations][time.month][data.dataLabel][2] = graphType.registeredArtists
                    allStats[graphType.registrations][time.week][data.value][2] = cb3.map((value) => value.count).slice(0, 7)
                    allStats[graphType.registrations][time.week][data.dataLabel][2] = graphType.registeredArtists
                    this.setState({
                        stats: allStats
                    })
                })
            })
        })
        
        this.props.getMediaStats(cb => {
            let allStats = this.state.stats
            allStats[graphType.newsongs][time.year][data.label] = cb.map((value) => moment(new Date(value.date_trunc)).format('MMM D')).slice()
            allStats[graphType.newsongs][time.month][data.label] = cb.map((value) => moment(new Date(value.date_trunc)).format('MMM D')).slice(0, 30)
            allStats[graphType.newsongs][time.week][data.label] = cb.map((value) => moment(new Date(value.date_trunc)).format('MMM D')).slice(0, 7)

            allStats[graphType.newsongs][time.year][data.value][0] = cb.map((value) => value.count).slice()
            allStats[graphType.newsongs][time.month][data.value][0] = cb.map((value) => value.count).slice(0, 30)
            allStats[graphType.newsongs][time.week][data.value][0] = cb.map((value) => value.count).slice(0, 7)
            this.setState({
                stats: allStats
            })
        })

        this.props.whatsHot(cb => {
            this.setState({
                whatsHot: cb
            })
        })
    }

    approve(decision, artistId) {
        this.props.approveArtist(artistId, decision, (response) => {
            let a = []
            let changed = false
            this.state.artistsToApprove.map((value, index) => {
                if (value.id !== artistId) {
                    a.push(value)
                    changed = true
                }
            })
            if (changed || a.length === 0) {
                this.setState({artistsToApprove: a})
            }
        })
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.chart !== undefined) {
            if (this.state.statTime !== nextState.statTime || this.state.graphType !== nextState.graphType 
                || this.chart.data.datasets[0].data.length === 0) {
                let labels = nextState.stats[nextState.graphType][nextState.statTime]['label'].slice()
                let data = nextState.stats[nextState.graphType][nextState.statTime]['value'].slice()
                let dataLabels = nextState.stats[nextState.graphType][nextState.statTime]['dataLabel'].slice()
                while (this.chart.data.labels.length > 0) {
                    this.chart.data.labels.pop()
                }
                while (0 < labels.length) {
                    this.chart.data.labels.push(labels.pop())
                }
                this.chart.data.datasets.forEach((d, index) => {
                    while (d.data.length > 0) {
                        d.data.pop()
                    }
                })
                this.chart.data.datasets.forEach((d, index) => {
                    while (d.length > 0) {
                        d.pop()
                    }
                })
                data.forEach((d, index) => {
                    let d2 = d.slice()
                    while (0 < d2.length) {
                        this.chart.data.datasets[index].data.push(d2.pop())
                    }
                    this.chart.data.datasets[index].label = dataLabels[index]
                })
                this.chart.update()
            }
        }
    }

    componentDidMount() {
        this.chart = new Chart(this.graph.getContext('2d'), {
            type: 'line',
            data: {
                labels: this.state.stats[this.state.graphType][this.state.statTime]['label'],
                datasets: this.state.stats[this.state.graphType][this.state.statTime]['value'].slice().map((d,index) => {
                    return {
                        label: this.state.stats[this.state.graphType][this.state.statTime]['dataLabel'][index],
                        fill: false,
                        borderColor: colors[index],
                        pointBackgroundColor: colors[index],
                        pointBorderColor: colors[index],
                        pointBorderWidth: 3,
                        pointRadius: 5,
                        data: d
                    }
                })
            },
            options: {
                maintainAspectRatio: false,
                tooltips: {
                    mode: 'x-axis',
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            fontColor: "#9897a9",
                            fontFamily: "Montserrat",
                            padding: 10
                        },
                        gridLines: {
                            display: false,
                            drawBorder: false
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0,
                            fontColor: "#9897a9",
                            fontFamily: "Montserrat",
                            padding: 10
                        },
                        gridLines: {
                            display: false,
                            drawBorder: false
                        }
                    }]
                },
                legend: {
                    display: false
                }
            }
        })
        
        this.loadData()
    }

    render() {
        const {statTime, artistsToApprove} = this.state
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-5">
                        <h1>Dashboard</h1>
                    </div>

                    <div className="col-12 mb-5">
                        <div className="badge w-25">
                            <h2 className="bold">{this.state.allArtists}</h2>
                            <small>Artists</small>
                        </div>
                        <div className="badge w-25">
                            <h2 className="bold">{this.state.allMedias}</h2>
                            <small>Songs</small>
                        </div>
                        <div className="badge w-25">
                            <h2 className="bold">{this.state.allAlbums}</h2>
                            <small>Albums</small>
                        </div>
                        <div className="badge w-25">
                            <h2 className="bold">{this.state.allPlays}</h2>
                            <small>Plays</small>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {artistsToApprove.length > 0 ?
                        <div className="col-lg-6 mb-5">
                            <div className="panel panel-pink">
                                <small
                                    className="hightlight">{moment(new Date()).format('D MMMM YYYY').toLocaleString()}</small>
                                <h5 className="mb-3 mt-1">{artistsToApprove.length} artists waiting for acceptance</h5>
                                <Link to="/admin/artists/toAccept" className="btn">See more</Link>
                            </div>
                        </div> : null}

                    {this.state.whatsHot !== null && (this.state.whatsHot.artist !== null || this.state.whatsHot.album !== null || this.state.whatsHot.playlist !== null)?
                        <div className="col-lg-6 mb-5">
                            <div className="panel panel-pink">
                                <small className="hightlight">What's Hot now?</small>
                                {this.state.whatsHot.artist != null ?
                                <h5 className="mb-3 mt-1">Artist: <Link to={"/admin/artists/"+this.state.whatsHot.artist.id+"/albums"}>
                                    <b><u>{this.state.whatsHot.artist.name}</u></b></Link></h5>
                                    : null}
                                {this.state.whatsHot.album != null ?
                                    <h5 className="mb-3 mt-1">Album: <Link to={"/admin/artists/"+this.state.whatsHot.album.artist_id+"/albums/"+this.state.whatsHot.album.id}>
                                        <b><u>{this.state.whatsHot.album.name}</u></b></Link></h5>
                                    : null}
                                {this.state.whatsHot.playlist != null ?
                                    <h5 className="mb-3 mt-1">Playlist: <Link to="#"><b><u>{this.state.whatsHot.playlist.name}</u></b></Link></h5>
                                    : null}
                            </div>
                        </div>
                        : null
                    }

                </div>

                <div className="row">
                    <div className="col-12 mb-5">
                        <div className="panel">
                            <div className="d-flex justify-content-between mb-2">
                                <ul className="nav">
                                    <li className="nav-item">
                                        <NavLink onClick={() => this.changeType(graphType.plays)} to="/admin/dashboard/"
                                                 exact activeClassName="active" className="nav-link">Plays</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink onClick={() => this.changeType(graphType.registrations)}
                                                 to="/admin/dashboard/registrations" activeClassName="active"
                                                 className="nav-link">Registrations</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink onClick={() => this.changeType(graphType.newsongs)}
                                                 to="/admin/dashboard/newsongs" activeClassName="active"
                                                 className="nav-link">New Songs</NavLink>
                                    </li>
                                </ul>
                                <ul className="nav">
                                    <li className="nav-item">
                                        <button onClick={() => this.changeStatTime(time.week)}
                                                className={statTime === time.week ? 'btn active' : 'btn'}>Week
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button onClick={() => this.changeStatTime(time.month)}
                                                className={statTime === time.month ? 'btn active' : 'btn'}>Month
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button onClick={() => this.changeStatTime(time.year)}
                                                className={statTime === time.year ? 'btn active' : 'btn'}>Year
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="graphContainer">
                                <canvas ref={(el) => this.graph = el}></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                {artistsToApprove.length > 0 ?
                    <div className="row">
                        <div className="col-12 mb-5">
                            <div className="panel">
                                <h5>Artists that waiting for acceptance</h5>
                                <div className="artistsList">
                                    {artistsToApprove.map((artist, index) => (
                                        <div className="artist" key={artist.id}>
                                            <span className="medium mr-3">{artist.name}</span>
                                            <span
                                                className="medium mr-3">{artist.country != null ? artist.country.code : '-'}</span>
                                            <span
                                                className="medium">{moment(new Date(artist.creation_date)).format('D MMMM YYYY').toLocaleString()}</span>
                                            {artist.bio != null ? <p className="regular">
                                                {artist.bio}
                                            </p> : null}
                                            <div className="d-flex">
                                                <button className="btn success mr-2"
                                                        onClick={() => this.approve(true, artist.id)}>Approve
                                                </button>
                                                <button className="btn danger mr-2"
                                                        onClick={() => this.approve(false, artist.id)}>Decline
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        getArtistCount: bindActionCreators(ArtistActions.getAllArtistCount, dispatch),
        getMediaCount: bindActionCreators(MediaActions.getAllMediaCount, dispatch),
        getAlbumsCount: bindActionCreators(AlbumActions.getAllAlbumsCount, dispatch),
        getAllPlays: bindActionCreators(MediaActions.getAllPlaysCount, dispatch),
        getArtistsWaitingForApproval: bindActionCreators(UserActions.getArtistsWaitingForApproval, dispatch),
        approveArtist: bindActionCreators(UserActions.approveArtist, dispatch),
        getStats: bindActionCreators(UserActions.getListeningsStats, dispatch),
        getRegistrationStats: bindActionCreators(UserActions.getRegistrationStats, dispatch),
        getMediaStats: bindActionCreators(MediaActions.getMediaStats, dispatch),
        whatsHot: bindActionCreators(MediaActions.whatsHot, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent)
