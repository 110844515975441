import React, { useState } from 'react'
import './CookieComponent.scss'
import { useCookies } from 'react-cookie'
import moment from 'moment'

export default function CookieComponent() {

  const [cookies, setCookie, removeCookie] = useCookies(['agreeCookies'])

  const agree = () => {
    setCookie('agreeCookies', true, {
      path: '/',
      expires: moment().add(1, 'year').toDate(),
    })
  }

  if (cookies.agreeCookies != undefined) return null;

  return (
    <div id="CookieComponent">
      <p className="mr-3 mb-0">
        OPUS processes your personal data, including information saved in cookies, to personalize content and advertising, analyze internet traffic and suggesting content that may interest you.
        By clicking “I AGREE", You agree to the use of technologies such as cookies and processing Your data by OPUS and its’ trusted partners of your personal data collected on the Internet, such as IP addresses and cookie IDs, for marketing purposes (including to automatically match ads to your interests and measure their effectiveness) and others, described in <a href="https://opus.audio/Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
        Consent is voluntary and you can withdraw it at any time by disabling it in User panel page.
        In addition, you have the right to request access, rectification, deletion or limitation of data processing. In the <a href="https://opus.audio/Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a> you will find information on how to communicate to us your will to exercise these rights.
        Detailed information on the processing of your data can be found in the <a href="https://opus.audio/Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
      </p>
      <button className="button text-nowrap" onClick={agree}>I AGREE</button>
    </div>
  )
}
